import * as Yup from 'yup'

export const schemaContract = Yup.object().shape({
    // contract: Yup.object().shape({
        id_client: Yup.string().test('is-zero-value', 'É necessário adicionar um cliente', (value) => {
            if (value === '0' || value === '') {
              return false;
            }
            return true;
        }),
        id_product: Yup.string().test('is-zero-value', 'É necessário adicionar um produto', (value) => {
            if (value === '0' || value === '') {
                return false;
            }
            return true;
        }),
        id_unit: Yup.string().test('is-zero-value', 'É necessário adicionar uma unidade', (value) => {
            if (value === '0' || value === '') {
                return false;
            }
            return true;
        }),
        // taxa_juros: Yup.string().test('is-zero-value', 'O valor deve ser maior que zero', (value) => {
        //     if (value === '' || value === '0' || value === '0.0' || value === '0.00 ' || value === '% 0'  || value === '% 0,0' || value === '% 0,00') {
        //       return false;
        //     }
        //     return true;
        // })
        // description: Yup.string().required('É necessário adicionar uma descrição'),
    // })
});

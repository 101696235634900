/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
    const intl = useIntl()

    return (
        <>
            
            <SidebarMenuItem
                to='/documentation/docs/apresentations'
                icon=''
                title={'Apresentação'}
                fontIcon='bi-app-indicator'
            />

            {/* <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Apresentação' hasBullet={true}>
                <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
                
            </SidebarMenuItemWithSub> */}

            
            
            <SidebarMenuItem
                to='/documentation/docs/initials'
                icon=''
                title={'Initials'}
                fontIcon='bi-app-indicator'
            />
            
            
            <SidebarMenuItem
                to='/documentation/docs/contract'
                icon=''
                title={'Contratos'}
                fontIcon='bi-app-indicator'
            />             
            
            <SidebarMenuItem
                to='/documentation/docs/lotes'
                icon=''
                title={'Lotes'}
                fontIcon='bi-app-indicator'
            />
            
            <SidebarMenuItem
                to='/documentation/docs/payments'
                icon=''
                title={'Pagamentos'}
                fontIcon='bi-app-indicator'
            />
        </>
    )
}

export {SidebarMenuMain}

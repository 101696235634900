import React from "react";
import { ChildComponentPropsLevelPermission } from "../../../../app/interface/I_Permission";

const PermissionLevel: React.FC<ChildComponentPropsLevelPermission> = ({ childrenHigh, childrenLow, permissionLevel }) => {
     
    if(permissionLevel === 'high')
    {
        return <>{childrenHigh}</>
    }else if(permissionLevel === 'low')
    {
        return <>{childrenLow}</>

    }else{
        return null
    }
};

  
export default PermissionLevel;


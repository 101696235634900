
import StickyTask from "./partials/StickyTask";
import TablesTasks from "./partials/TablesTasks";
import { useState } from "react";
import { typeItemTaskSticky } from "../../types/tasks";
import { TableItem } from "../../interface/I_Tasks";

const TasksWrapper = () => {
    const [activeItem, setActiveItem] = useState<typeItemTaskSticky>({
            id:'64271b447a396', 
            api:'tableInstallmentAnticipation', 
            title:'Antecipação de parcelas', 
            total:0, 
            icon:'/media/icons/duotune/general/gen014.svg', 
            color:'success',
            type:'antecipaParc'
        });

    const handleItemClick = (item: typeItemTaskSticky) => {
        // Atualiza o estado da tabela com as informações do item clicado
        setActiveItem(item);
    };
    
    return (
        <div className="app-container  container-xxl ">
            <div className="d-flex flex-column flex-lg-row ms-n11">                
                <StickyTask onItemClick={handleItemClick}/>
                <TablesTasks api={activeItem.api} type={activeItem.type}/>
            </div>        
        </div>
    )
}

export default TasksWrapper;
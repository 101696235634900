  
 import { IHeaderWithCell, IHeader } from "../../../interface";
  
  export const getHeaderForType = (type: string): IHeaderWithCell[] => {
    switch (type) {
      case 'antecipaParc':
        return [
            { Header: 'Contrato', accessor: 'contrato' },
            { Header: 'Cliente', accessor: 'cliente' },
            { Header:'Data da Solicitação', accessor:'data_solicitacao' },
            { Header: 'Contatos', accessor: 'contato' }
        ];
      case 'quitacao':
        return [
            { Header: 'Nome', accessor: 'name' },
            { Header: 'Email', accessor: 'email' },
            { Header: 'Contatos', accessor: 'phone' }
        ];
        case 'distrato' : 
        return [
            { Header: 'Contrato', accessor: 'contrato' },
            { Header: 'Cliente', accessor: 'cliente' },
            { Header:'Data da Solicitação', accessor:'data_solicitacao' },
            { Header: 'Contatos', accessor: 'contato' }
        ];
        case 'envEmail' : 
        return [
            { Header: 'Nome', accessor: 'name' },
            { Header: 'Email', accessor: 'email' },
            { Header: 'Contatos', accessor: 'phone' }
        ];
        case 'envWhatsapp' :
            return [
                { Header: 'Contrato', accessor: 'contrato' },
                { Header: 'Cliente', accessor: 'cliente' },
                { Header:'Data da Solicitação', accessor:'data_solicitacao' },
                { Header: 'Contatos', accessor: 'contato' }
            ]
        case 'acordo' : return [
            
            { Header: 'Nome', accessor: 'name' },
            { Header: 'Email', accessor: 'email' },
            { Header: 'Contatos', accessor: 'phone' }
        ]
        case 'contrato' : return [
            
            { Header: 'Produto', accessor: 'contract'},
            { Header: 'Valor', accessor: 'money' },
            { Header: 'Situação', accessor: 'situation' },
            { Header: 'Proximo Pagamento', accessor: 'nextPayment' },
            { Header: 'Fim do contrato', accessor: 'endContract' }
        ]
        case 'lote' : return [
            
            { Header: 'Produto', accessor: 'contract' },
            { Header: 'Cidade', accessor: 'city' },
            { Header: 'Estado', accessor: 'state' },
            { Header: 'Tipo', accessor: 'type' },
            { Header: 'Finalidade', accessor: 'purpose' },
            { Header: 'Contrato Atual', accessor: 'currentContract' }
        ]
      default:
        return [
            { Header: 'Contrato', accessor: 'contrato' },
            { Header: 'Cliente', accessor: 'cliente' },
            { Header:'Data da Solicitação', accessor:'data_solicitacao' },
            { Header: 'Contatos', accessor: 'contato' }
        ];
    }
  };
  

  export default getHeaderForType
  
  
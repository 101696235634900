import { createElement } from "react";
import { ChildComponentProps } from "../../../../app/interface/I_Permission";

const Dev: React.FC<ChildComponentProps> = ({ children }) => {
    return createElement(
        'div',
        { permissiontype:'dev' },
        children
    );
};

export default Dev;

export const formatarValor = (value: any) => {

    if (isNaN(value))
    {
        return parseFloat(value.replace(/\./g,'').replace(/\,/g,'.'));
    }
    return value;
};

export const convertMoedaToFloat = (value: any) =>{

    // Remover caracteres não numéricos, exceto o ponto para casas decimais
    const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));

    // Se o valor não for um número válido, retornar 0
    return isNaN(numericValue) ? 0 : numericValue;

 }

export const formatarPercentage = (value: any = 0.00) => {
    
    const newValue = parseFloat(value.toString().replace(/[^\d.,]/g, ''));
    
    if (isNaN(newValue)) {
        return `0,00 %`;
    }

    const formattedValue = newValue.toFixed(2); // Garante duas casas decimais

    return `${formattedValue.replace('.', ',')} %`;


};

export function formatarMoedaReal(valor: number): string {
    // Formata o valor para o formato de moeda brasileira
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    // Retorna o valor formatado
    return formatter.format(valor);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface IFilterState {
    [key: string]: any;
}


const initialState: IFilterState = {};

const historicSlice = createSlice({
    name: 'historic',
    initialState,
    reducers: {
        setHistoric: (
            state,
            action: PayloadAction<IFilterState>
        ) => {

            const filterPayload = action.payload;
            Object.keys(filterPayload).forEach((key) => {
                state[key] = filterPayload[key];
            });

        },
    },
});

export const { setHistoric } = historicSlice.actions;
export default historicSlice.reducer;

import axios, { AxiosError, AxiosResponse } from "axios";
import BaseServiceHttp from "../../_metronic/helpers/functions/http/BaseServiceHttp";
import { IResponse, AuthType } from "../interface/I_General";


const API_URL = process.env.REACT_APP_API_URL;

export const getInvoice = async (id:number|string, auth: AuthType) => {
    let headers = {};
    if (auth && auth.api_token)
    {
        headers = { Authorization: `Bearer ${auth.api_token}` };
    }            
        
    const response = await axios.post(`${API_URL}/module/getInvoice`,
                        { id },
                        { headers }
                    );

    return response;
}

export const createEditInvoice = async (file:any ) => {

    const customConfig:any = {
        method: 'post',
        url: `/module/insertInvoice`,
        data: file,
        isFormFileData: true,
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                                console.log('error invoice ', status, data)
                            }
                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar inserir ou editar nota fiscal'
                                }
                            }
                        });
    return response;
}

export const deleteInvoice = async (id:any, id_client:any, id_contract:any, id_parcel:any, path:string ) => {

    const customConfig:any = {
        method: 'post',
        url: `/module/deleteInvoice`,
        data: { form_data: {id, id_client, id_contract, id_parcel, path}}
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar deletar nota fiscal'
                                }
                            }
                        });
    return response;
}
export const Summary = ({calculoSimulation}:any) => {
    return (
        <div className="m-0">
            <div className="fw-semibold fs-5 mb-3 text-dark00">RESUMO</div>
            <div className="fw-semibold fs-5 text-gray-600 mb-2">Contrato: 
                <span className="fw-bold fs-4 text-gray-800">{calculoSimulation.name_contract || '...'}</span>
            </div>

            <div className="row mb-7">
                <div className="col-sm-6 col-md-2">
                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Valor contrato:</div>
                    <div className="fw-bold fs-6 text-gray-800">{calculoSimulation?.summary?.value_financial || '...'}</div>
                </div>

                <div className="col-sm-6 col-md-3">
                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Prazo contrato (total):</div>
                    <div className="fw-bold fs-6 text-gray-800">{calculoSimulation?.summary?.number_parcel_total || '...'}</div>
                </div>

                <div className="col-sm-6 col-md-3">
                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Prestações disponíveis:</div>
                    <div className="fw-bold fs-6 text-gray-800">{calculoSimulation?.summary?.number_parcel_remaining || '...'}</div>
                </div>

                <div className="col-sm-6 col-md-2">
                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Taxa de Juros (m):</div>
                    <div className="fw-bold fs-6 text-gray-800">{calculoSimulation?.summary?.taxa_juros || '...'}</div>
                </div>

                <div className="col-sm-6 col-md-2">
                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Correção:</div>
                    <div className="fw-bold fs-6 text-gray-800">{calculoSimulation?.summary?.correcao || '...'}</div>
                </div>

            </div>
        </div>
    )
}
export const pt_BR = {
    addBulkFilesFailed: {
            '0': 'Falha ao adicionar %{smart_count} arquivo devido a um erro interno',
            '1': 'Falha ao adicionar %{smart_count} arquivos devido a erros internos'
        },
        addedNumFiles: 'Adicionado(s) %{numFiles} arquivo(s)',
        addingMoreFiles: 'Adicionando mais arquivos',
        addMore: 'Adicionar mais',
        addMoreFiles: 'Adicionar mais arquivos',
        allFilesFromFolderNamed: 'Todos os arquivos da pasta %{name}',
        allowAccessDescription: 'Para tirar fotos ou gravar vídeos com sua câmera, permita o acesso à câmera para este site.',
        allowAccessTitle: 'Por favor, permita o acesso à sua câmera',
        allowAudioAccessDescription: 'Para gravar áudio, permita o acesso ao microfone para este site.',
        allowAudioAccessTitle: 'Por favor, permita o acesso ao seu microfone',
        aspectRatioLandscape: 'Recortar paisagem (16:9)',
        aspectRatioPortrait: 'Recortar retrato (9:16)',
        aspectRatioSquare: 'Recortar quadrado',
        authAborted: 'Autenticação abortada',
        authenticateWith: 'Conectar-se ao %{pluginName}',
        authenticateWithTitle: 'Por favor, autentique-se com %{pluginName} para selecionar arquivos',
        back: 'Voltar',
        browse: 'navegar',
        browseFiles: 'navegar arquivos',
        browseFolders: 'navegar pastas',
        cancel: 'Cancelar',
        cancelUpload: 'Cancelar upload',
        chooseFiles: 'Escolher arquivos',
        closeModal: 'Fechar modal',
        companionError: 'Falha na conexão com o Companion',
        companionUnauthorizeHint: 'Para cancelar a autorização da sua conta %{provider}, vá para %{url}',
        complete: 'Completo',
        compressedX: 'Salvo %{size} comprimindo imagens',
        compressingImages: 'Comprimindo imagens...',
        connectedToInternet: 'Conectado à Internet',
        copyLink: 'Copiar link',
        copyLinkToClipboardFallback: 'Copie a URL abaixo',
        copyLinkToClipboardSuccess: 'Link copiado para a área de transferência.',
        creatingAssembly: 'Preparando upload...',
        creatingAssemblyFailed: 'Transloadit: Não foi possível criar a Assembleia',
        dashboardTitle: 'Painel do Uppy',
        dashboardWindowTitle: 'Janela do Painel do Uppy (Pressione escape para fechar)',
        dataUploadedOfTotal: '%{complete} de %{total}',
        discardRecordedFile: 'Descartar arquivo gravado',
        done: 'Concluído',
        dropHereOr: 'Solte aqui ou %{browse}',
        dropHint: 'Solte seus arquivos aqui',
        dropPasteBoth: 'Solte arquivos aqui, %{browseFiles} ou %{browseFolders}',
        dropPasteFiles: 'Solte arquivos aqui ou %{browseFiles}',
        dropPasteFolders: 'Solte arquivos aqui ou %{browseFolders}',
        dropPasteImportBoth: 'Solte arquivos aqui, %{browseFiles}, %{browseFolders} ou importe de:',
        dropPasteImportFiles: 'Solte arquivos aqui, %{browseFiles} ou importe de:',
        dropPasteImportFolders: 'Solte arquivos aqui, %{browseFolders} ou importe de:',
        editFile: 'Editar arquivo',
        editFileWithFilename: 'Editar arquivo %{file}',
        editing: 'Editando %{file}',
        emptyFolderAdded: 'Nenhum arquivo foi adicionado da pasta vazia',
        encoding: 'Codificando...',
        enterCorrectUrl: 'URL incorreta: por favor, verifique se você está inserindo um link direto para um arquivo',
        enterTextToSearch: 'Digite o texto para buscar imagens',
        enterUrlToImport: 'Digite a URL para importar um arquivo',
        exceedsSize: '%{file} excede o tamanho máximo permitido de %{size}',
        failedToFetch: 'O Companion falhou em buscar esta URL, por favor, verifique se está correta',
        failedToUpload: 'Falha ao enviar %{file}',
        filesUploadedOfTotal: {
            '0': '%{complete} de %{smart_count} arquivo enviado',
            '1': '%{complete} de %{smart_count} arquivos enviados'
        },
        filter: 'Filtrar',
        finishEditingFile: 'Finalizar a edição do arquivo',
        flipHorizontal: 'Inverter horizontalmente',
        folderAdded: {
            '0': 'Adicionado %{smart_count} arquivo de %{folder}',
            '1': 'Adicionados %{smart_count} arquivos de %{folder}'
        },
        additionalRestrictionsFailed: '%{count} restrições adicionais não foram atendidas',
        folderAlreadyAdded: 'A pasta "%{folder}" já foi adicionada',
        generatingThumbnails: 'Gerando miniaturas...',
        import: 'Importar',
        importFiles: 'Importar arquivos de:',
        importFrom: 'Importar de %{name}',
        inferiorSize: 'Este arquivo é menor do que o tamanho permitido de %{size}',
        loading: 'Carregando...',
        logOut: 'Sair',
        micDisabled: 'Acesso ao microfone negado pelo usuário',
        missingRequiredMetaField: 'Campos meta obrigatórios faltando',
        missingRequiredMetaFieldOnFile: 'Campos meta obrigatórios faltando em %{fileName}',
        missingRequiredMetaFields: {
            '0': 'Campo meta obrigatório faltando: %{fields}.',
            '1': 'Campos meta obrigatórios faltando: %{fields}.'
        },
        myDevice: 'Meu dispositivo',
        noAudioDescription: 'Para gravar áudio, por favor, conecte um microfone ou outro dispositivo de entrada de áudio',
        noAudioTitle: 'Microfone não disponível',
        noCameraDescription: 'Para tirar fotos ou gravar vídeo, conecte um dispositivo de câmera',
        noCameraTitle: 'Câmera não disponível',
        noDuplicates: "Não é possível adicionar o arquivo duplicado '%{fileName}', ele já existe",
        noFilesFound: 'Você não tem arquivos ou pastas aqui',
        noInternetConnection: 'Sem conexão com a internet',
        noMoreFilesAllowed: 'Não é possível adicionar mais arquivos',
        openFolderNamed: 'Abrir pasta %{name}',
        pause: 'Pausar',
        paused: 'Pausado',
        pauseUpload: 'Pausar envio',
        pluginNameAudio: 'Áudio',
        pluginNameBox: 'Box',
        pluginNameCamera: 'Câmera',
        pluginNameDropbox: 'Dropbox',
        pluginNameFacebook: 'Facebook',
        pluginNameGoogleDrive: 'Google Drive',
        pluginNameInstagram: 'Instagram',
        pluginNameOneDrive: 'OneDrive',
        pluginNameZoom: 'Zoom',
        poweredBy: 'Desenvolvido por %{uppy}',
        processingXFiles: {
            '0': 'Processando %{smart_count} arquivo',
            '1': 'Processando %{smart_count} arquivos'
        },
        recording: 'Gravando',
        recordingLength: 'Duração da gravação %{recording_length}',
        recordingStoppedMaxSize: 'Gravação interrompida porque o tamanho do arquivo está prestes a exceder o limite',
        recordVideoBtn: 'Gravar vídeo',
        recoveredAllFiles: 'Restauramos todos os arquivos. Agora você pode retomar o envio.',
        recoveredXFiles: {
            '0': 'Não foi possível recuperar totalmente 1 arquivo. Selecione-o novamente e retome o envio.',
            '1': 'Não foi possível recuperar totalmente %{smart_count} arquivos. Selecione-os novamente e retome o envio.'
        },
        removeFile: 'Remover arquivo',
        reSelect: 'Selecionar novamente',
        resetFilter: 'Redefinir filtro',
        resume: 'Retomar',
        resumeUpload: 'Retomar envio',
        retry: 'Tentar novamente',
        retryUpload: 'Tentar envio novamente',
        revert: 'Reverter',
        rotate: 'Girar',
        save: 'Salvar',
        saveChanges: 'Salvar alterações',
        search: 'Pesquisar',
        searchImages: 'Pesquisar imagens',
        selectX: {
            '0': 'Selecionar %{smart_count}',
            '1': 'Selecionar %{smart_count}'
        },
        sessionRestored: 'Sessão restaurada',
        showErrorDetails: 'Mostrar detalhes do erro',
        signInWithGoogle: 'Entrar com o Google',
        smile: 'Sorrir!',
        startAudioRecording: 'Iniciar gravação de áudio',
        startCapturing: 'Iniciar captura de tela',
        startRecording: 'Iniciar gravação de vídeo',
        stopAudioRecording: 'Parar gravação de áudio',
        stopCapturing: 'Parar captura de tela',
        stopRecording: 'Parar gravação de vídeo',
        streamActive: 'Stream ativo',
        streamPassive: 'Stream passivo',
        submitRecordedFile: 'Enviar arquivo gravado',
        takePicture: 'Tirar uma foto',
        takePictureBtn: 'Tirar Foto',
        timedOut: 'Upload parado por %{seconds} segundos, abortando.',
        upload: 'Enviar',
        uploadComplete: 'Envio completo',
        uploadFailed: 'Envio falhou',
        uploading: 'Enviando',
        uploadingXFiles: {
            '0': 'Enviando %{smart_count} arquivo',
            '1': 'Enviando %{smart_count} arquivos'
        },
        uploadPaused: 'Envio pausado',
        uploadStalled: 'O envio não fez nenhum progresso por %{seconds} segundos. Você pode querer tentar novamente.',
        uploadXFiles: {
            '0': 'Enviar %{smart_count} arquivo',
            '1': 'Enviar %{smart_count} arquivos'
        },
        uploadXNewFiles: {
            '0': 'Enviar +%{smart_count} arquivo',
            '1': 'Enviar +%{smart_count} arquivos'
        },
        xFilesSelected: {
            '0': '%{smart_count} arquivo selecionado',
            '1': '%{smart_count} arquivos selecionados'
        },
        xMoreFilesAdded: {
            '0': '%{smart_count} arquivo adicionado',
            '1': '%{smart_count} arquivos adicionados'
        },
        xTimeLeft: '%{time} restantes',
        youCanOnlyUploadFileTypes: 'Você só pode enviar: %{types}',
        youCanOnlyUploadX: {
            '0': 'Você só pode enviar %{smart_count} arquivo',
            '1': 'Você só pode enviar %{smart_count} arquivos'
        },
        youHaveToAtLeastSelectX: {
            '0': 'Você precisa selecionar pelo menos %{smart_count} arquivo',
            '1': 'Você precisa selecionar pelo menos %{smart_count} arquivos'
        },
        zoomIn: 'Aumentar zoom',
        zoomOut: 'Diminuir zoom'
};
import * as yup from 'yup';

const uniqueArray = (array: any) => {
    return array && array.length === new Set(array).size;
};

export const SchemaRegister = yup.object().shape({
    // bank_code: yup.string().nullable().required('O campo Banco é obrigatório'),
    beneficiary: yup.string().nullable().required('O campo Beneficiário é obrigatório'),
    name_apresentation: yup.string().nullable().required('O campo Nome da conta é obrigatório'),
    agency: yup.string().nullable().required('Obrigatório'),
    account: yup.string().nullable().required('Obrigatório'),
    carteira: yup.string().nullable().required('Obrigatório'),
    // api_integration: yup
    //     .array()
    //     .of(yup.string())
    //     .test('unique', 'Os valores devem ser únicos.', uniqueArray)
    //     .nullable()
    //     .required('O campo de api integração é obrigatório e deve ser único.'),
    // ...outras validações para seus outros campos
});
import { useEffect, useState } from "react";
import { statusContract, typeParcel } from "../../../app/Constants";
import { optionsStatusParcel } from "../../../app/globals/OptionsGlobals";
import { IHeaderWithCell, IHeader } from "../../../app/interface";
import { useAuth } from "../../../app/modules/auth";
import { downloadBoletos } from "../../../app/request/payment";
import { KTSVG } from "../../helpers";
// import { downloadBoletoPdf } from "../../helpers/functions/boleto/boleto";
import { isDateExpired } from "../../helpers/functions/dates/date";
import ComponentPermission from "../../layout/components/permissions/ComponentPermission";
  
export const HeaderForTypeContract = (type: string, handleEdit?: (row: any) => void, handleInvoice?: (row: any) => void,  handleHistory?: (row: any) => void): IHeaderWithCell[] => {
    
    const {auth} = useAuth();
    
    switch (type) {
        case 'client':
            return [
                { Header: 'Cliente', accessor: 'name' },
                { Header: 'Cpf', accessor: 'cpf' },
                { Header: 'Telefone', accessor: 'telefone' },
                { Header: 'Email', accessor: 'email' },
                { Header: 'Açoes', accessor: (row:any) => {
                        return (
                            <>
                                {/*  EXIBIÇÂO DO BOTAO EDITAR */}
                                {

                                handleEdit && typeof handleEdit === 'function' && (
                                    <ComponentPermission>
                                        <ComponentPermission.Edit>
                                            <button
                                                className="btn btn-sm ms-2 fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                                onClick={() => handleEdit(row)}
                                            >
                                                Editar
                                            </button>
                                        </ComponentPermission.Edit>
                                </ComponentPermission>
                                )
                                }   
                            </>
                        )
                    } 
                }
            ];
        case 'meat': // carne
            return [
                { Header: 'Data de Criação', accessor: 'payment' },
                { Header: 'Primeiro Venc.', accessor: 'firstExpiration' },
                { Header: 'Último Venc.', accessor: 'lastExpiration' },
                { Header: 'Email', accessor: 'email' },
                { Header: 'Ações', 
                    accessor: (row: any) => (
                        <div>
                            <button className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary" onClick={() => console.log(`Ação para ID  `, row)}>
                                Ver Pacelas 
                            </button>
                        </div>
                    )
                }
            ];
        case 'installment' : // parcelas
            return [

                { Header: 'Parcela', accessor: (row:any) => { return (`${row.number_parcel} º`) } 
                },
                { Header: 'Tipo', accessor: (row:any) => {
	                    let type_parcel = typeParcel.find(item => item.value === row.type_parcel)?.label || '...';
                        return (type_parcel);
                    }
            
                },
                { Header: 'Forma', accessor: 'form_payment' },
                { Header: 'Vencimento', accessor: 'due_date' },
                { Header: 'Valor', accessor: 'value' },
                { Header: 'Situação', accessor: (row:any) => {
                        if(row.deleted === null)
                        {
                            if(row.mark_parcel_paid !== '' && row.mark_parcel_paid !== null)
                            {                       
                                return row.mark_parcel_paid;
                                
                            }else{
                                if(row?.status == '3' || row?.status == '4')
                                {
                                    return (<span className="badge badge-light-danger fs-base">{row?.situation}</span>);

                                }else{

                                    const statusParcel = optionsStatusParcel.find(item => item.value === row.status)?.writeLabel || 'Pendente';
                                    return statusParcel
                                }

                            }
                        }else{

                            if(row?.status == '3' || row?.status == '4')
                            {
                                return (<span className="badge badge-light-danger fs-base">{row?.situation}</span>);
                            }

                            return (<span className="badge badge-light-danger fs-base">Deletado</span>)
                        }
                    }

                },
                { Header: 'Ações',  
                    accessor: (row: any) => {
                        let classBgExpirate = 'btn-light';
                        let classIcon = 'svg-icon-1x';

                        if(row?.hasOwnProperty('due_date') && !!row?.due_date){
                            if(row?.hasOwnProperty('status'))
                            {
                                if(row?.status !== '1' && row?.status !== '2')
                                {
                                    classBgExpirate = isDateExpired(row?.due_date) ? 'bg-opacity-15 bg-danger text-danger' : 'btn-light';
                                    classIcon = 'svg-icon svg-icon-1x'
                                }
                            }
                        }

                        if(row.deleted === null)
                        {
                            // const fileNameBoleto = !!row.token_boleto ? `Boleto ${row.our_number}, Vencimento ${row.due_date}, ${row.payer}` : '';
                            return (row.status === '1' || row.status === '2') && (row.confirmation_send === 'sim') 
                            ? (
                                <>
                                    <KTSVG className={`svg-icon svg-icon-2x svg-icon-success me-3`} path="/media/icons/duotune/general/gen047.svg"/>
                                    
                                    {/*  EXIBIÇÂO DO DRAWER (Nota Fiscal) */}
                                    {

                                        handleInvoice && typeof handleInvoice === 'function' && (

                                            <button
                                                className={`btn btn-sm w-20px ms-2 fw-bold btn-active-color-danger ${classBgExpirate}`}
                                                onClick={() => handleInvoice(row)}
                                            >
                                                <KTSVG className={`${classIcon} ms-n2`} path="/media/icons/duotune/communication/com008.svg"/>
                                            </button>
                                        )
                                    }
                                    
                                    {

                                        handleHistory && typeof handleHistory === 'function' && (
                                            <button
                                                className={`btn btn-sm w-20px ms-2 fw-bold btn-active-color-danger ${classBgExpirate} pulse`}
                                                onClick={() => handleHistory(row)}
                                            >
                                                <KTSVG className={`${classIcon} ms-n2`} path="/media/icons/duotune/communication/com012.svg"/>
                                                
                                                {
                                                    !!row.id_historic && (
                                                        <span style={{marginTop:'-2.2rem', marginLeft:'-1.5rem'}} className="pulse-ring"></span>
                                                    )
                                                }
                                            </button>
                                        )
                                    }
                                </>
                            ):(
                                <div className="d-flex" id={`actions_${row.id}`}>

                                    {/* EXIBIÇÂO DO BOLETO */}
                                    {
                                        (() => {
                                            
                                            if ((row.name_integration === 'api_cloud' || row.name_integration === null || row.name_integration === '') && !!row.token_boleto && !!row.link_boleto && !!row.token_remessa && row.confirmation_send === 'sim') {
                                                return (
                                                    <ComponentPermission includeVerify={['payment']}>
                                                        <ComponentPermission.Export equalVerify="payment">
                                                            <button
                                                                id={`boleto_${row.id}`}
                                                                className="btn btn-sm w-20px fw-bold btn-light btn-active-color-danger"
                                                                onClick={() => downloadBoletos(row.link_boleto)}
                                                            >
                                                                <KTSVG className={`${classIcon} ms-n2`} path={"/media/icons/duotune/files/fil021.svg"} />
                                                            </button>
                                                        </ComponentPermission.Export>
                                                    </ComponentPermission>
                                                );
                                            } else if (row.name_integration === 'itau_bank' && !!row.token_boleto && !!row.link_boleto) {
                                                
                                                return (
                                                    <ComponentPermission includeVerify={['payment']}>
                                                        <ComponentPermission.Export equalVerify="payment">
                                                            <button
                                                                id={`boleto_${row.id}`}
                                                                className="btn btn-sm w-20px fw-bold btn-light btn-active-color-danger"
                                                                onClick={() => downloadBoletos(row.link_boleto)}
                                                            >
                                                                <KTSVG className={`${classIcon} ms-n2`} path={"/media/icons/duotune/files/fil021.svg"} />
                                                            </button>
                                                        </ComponentPermission.Export>
                                                    </ComponentPermission>
                                                );
                                            } else {
                                                return <></>;
                                            }
                                        })()
                                    }


                                    {/*  EXIBIÇÂO DO BOTAO EDITAR */}
                                    {

                                        handleEdit && typeof handleEdit === 'function' && (

                                            <ComponentPermission includeVerify={['payment']}>
                                                <ComponentPermission.Edit equalVerify="payment">
                                                    <button
                                                        className={`btn btn-sm w-20px ms-2 fw-bold btn-active-color-danger ${classBgExpirate}`}
                                                        onClick={() => handleEdit(row)}
                                                    >
                                                        <KTSVG className={`${classIcon} ms-n2`} path="/media/icons/duotune/general/gen055.svg"/>
                                                    </button>
                                                </ComponentPermission.Edit>
                                            </ComponentPermission>
                                            
                                        )
                                    }   

                                    {/*  EXIBIÇÂO DO DRAWER (Nota Fiscal) */}
                                    {

                                        handleInvoice && typeof handleInvoice === 'function' && (

                                            <button
                                                className={`btn btn-sm w-20px ms-2 fw-bold btn-active-color-danger ${classBgExpirate}`}
                                                onClick={() => handleInvoice(row)}
                                            >
                                                <KTSVG className={`${classIcon} ms-n2`} path="/media/icons/duotune/communication/com008.svg"/>
                                            </button>
                                        )
                                    }

                                    {/*  EXIBIÇÂO DO DRAWER (Comentario) */}
                                    {

                                        handleHistory && typeof handleHistory === 'function' && (

                                            <button
                                                className={`btn btn-sm w-20px ms-2 fw-bold btn-active-color-danger ${classBgExpirate} pulse`}
                                                onClick={() => handleHistory(row)}
                                            >
                                                <KTSVG className={`${classIcon} ms-n2`} path="/media/icons/duotune/communication/com012.svg"/>
                                                
                                                {
                                                    !!row.id_historic && (
                                                        <span style={{marginTop:'-2.2rem', marginLeft:'-1.5rem'}} className="pulse-ring"></span>
                                                    )
                                                }
                                            </button>
                                        )
                                    }                               
                                    
                                </div>
                            )

                        }else{
                            
                            return (
                                <div>
                                    <KTSVG className="svg-icon svg-icon-2x svg-icon-danger me-3" path="/media/icons/duotune/general/gen047.svg"/>
                                    { 
                                        handleHistory && typeof handleHistory === 'function' && (
                                            <button
                                                className={`btn btn-sm w-20px ms-2 fw-bold btn-active-color-danger ${classBgExpirate} pulse`}
                                                onClick={() => handleHistory(row)}
                                            >
                                                <KTSVG className={`${classIcon} ms-n2`} path="/media/icons/duotune/communication/com012.svg"/>
                                                
                                                {
                                                    !!row.id_historic && (
                                                        <span style={{marginTop:'-2.2rem', marginLeft:'-1.5rem'}} className="pulse-ring"></span>
                                                    )
                                                }
                                            </button>
                                        )
                                    }
                                </div>
                            );                            
                                
                        }
                        
                    }
                }
            ];
        case 'contract' : // contratos
            return [                

                { Header: 'Contrato', accessor: 'name' },
                { Header: 'Valor', accessor: 'value' },
                { Header: 'Situação', accessor: (row:any) => {
                        // const status = statusContract.find(item => item.value === row.status_contract)?.label || '';
                        let color = 'primary';
                        if(row.status == 2 )
                        {
                            color = 'success';
                        }else if(row.status == 3 || row.status == 4 || row.status == 5 )
                        {
                            color = 'danger';
                        }else if(row.status == 6)
                        {
                            color = 'warning';
                        }

                        
                        return (<span className={`badge badge-lg badge-light-${color} fw-bold my-2 fs-8`}>{row.situation}</span>);
                    }
                },
                { Header: 'Próximo Pgto', accessor: 'next_payment' },
                { Header: 'Fim de contrato', accessor: 'contract_expiration' },
                { Header: 'Ações',  
                    accessor: (row: any) => {

                        return (
                            <div>
                                {handleEdit && typeof handleEdit === 'function' && (
                                    <ComponentPermission>
                                        <ComponentPermission.Edit>
                                            <button
                                                className="btn btn-sm fw-bold btn-light btn-color-gray-700 btn-active-color-primary"
                                                onClick={() => handleEdit(row)}
                                            >
                                                Editar
                                            </button>
                                        </ComponentPermission.Edit>
                                    </ComponentPermission>
                                )}
                                
                            </div>
                        )
                    }
                }
            ];
        case 'unit': // unidade
            return [
                { Header: 'Produto', accessor: 'name' },
                { Header: 'Tipo', accessor: 'type' },
                { Header: 'Contrato Atual', 
                    accessor: (row: any) => {
                        return (
                            <div>
                                {!!row.id_contract ? (
                                    <a  
                                        type="button"
                                        className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                        href={`/contract/details/${row.id_contract}/overview`}    
                                        >
                                            Detalhe do Contrato
                                    </a>
                                ): (
                                    <span>Sem contrato</span>
                                )}                                
                                
                            </div>
                        )
                    }
                },
                { Header: 'Ação', 
                    accessor: (row: any) => {
                        return (
                            <div>
                                
                                {handleEdit && typeof handleEdit === 'function' && (
                                    <ComponentPermission>
                                        <ComponentPermission.Edit>
                                            <button
                                                className="btn btn-sm ms-5 fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                                onClick={() => handleEdit(row)}
                                            >
                                                Editar
                                            </button>
                                        </ComponentPermission.Edit>
                                    </ComponentPermission>
                                )}

                                
                                
                            </div>
                        )
                    }
                }
            ];
        default:
            return [
                { Header: 'Cliente', accessor: 'name' },
                { Header: 'Cpf', accessor: 'cpf' },
                { Header: 'Telefone', accessor: 'telefone' },
                { Header: 'Email', accessor: 'email' },
                { Header: 'Contratos consfirmados', accessor: 'confirmation' }
            ];
    }

    
}; 



export default HeaderForTypeContract
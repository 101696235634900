import moment from 'moment';
moment.locale('pt-br');

export const formatDateDefaultString = (dateString: string): string => {

    const formats = ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD']; // Adicione outros formatos que você precisa suportar

    let date = moment(dateString, formats, true);

    if (date.isValid()) {
        return date.format('YYYY-MM-DD');
    } else {
        return '';
    }
}

export const formatDateToBrString = (dateString: string | undefined): string => {
    
    const formats = ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD']; // Adicione outros formatos que você precisa suportar

    let date = moment(dateString, formats, true);

    if (date.isValid()) {
        return date.format('DD/MM/YYYY');
    } else {
        return '';
    }
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { ListPayments } from './table/ListPayments'
import { IncomeStatement } from '../../../_metronic/partials/payments/IncomeStatement'

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard/access',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

const PaymentPage: FC = () => (
  <>
   
    <div className="app-container  container-xxl ">
        <div className="d-flex flex-column flex-lg-row ms-n11">
            <div className="flex-lg-row-fluid ms-lg-5 ms-xl-5">
                <IncomeStatement/>
                <ListPayments/>
            </div>        
        </div>        
    </div>
  </>
)

const PaymentsWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Pagamentos</PageTitle>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <PaymentPage />
    </>
  )
}

export {PaymentsWrapper}

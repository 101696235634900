import Swal from "sweetalert2";

export const LoadAnimation = (title:string = 'Estamos processando a planilha..') => {

    let initialTime = Date.now();
    let timerInterval:any;
    
    Swal.fire({
      title: 'Aguarde...',
      html: title,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const container = Swal.getHtmlContainer();
        if (container) {
            const b = container.querySelector('b');
            if (b) {
                timerInterval = setInterval(() => {
                    const currentTime = Date.now();
                    const elapsedTime = currentTime - initialTime;
                    b.textContent = elapsedTime.toString();
                }, 100);
            }
        }
      },
      willClose: () => {
        clearInterval(timerInterval);
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer');
      }
    });
    


}
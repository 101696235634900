const ReportFrame = ({props} : any) => {
    return (
        <>
            {   
                !!props.link && (

                    <div className="card mb-5 mb-xl-10 mt-5" key={props?.id}>
                        <div className="card-body pt-9 pb-0">
                            <iframe src={props.link} style={{width: props?.width || '100%', height: props?.height || '1000px'}}></iframe>
                        </div>
                    </div>
                )

            }
        </>
        
        
    );
}

export default ReportFrame;
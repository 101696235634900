export const ButtonSubmit = ({labelButton}:{ labelButton:string}) => (
    
    <button
        type="submit"
        className="btn btn-sm btn-flex bg-light btn-color-primary-700 btn-active-color-primary fw-bold me-2" 
        
    >
        {labelButton}
    </button>
    
);
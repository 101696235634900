import { createElement } from "react";
import { ChildComponentProps } from "../../../../app/interface/I_Permission";

const Edit: React.FC<ChildComponentProps> = ({ children }) => {
    return createElement(
        'div',
        { permissiontype:'edit' },
        children
    );
};

export default Edit;
import axios, { AxiosError, AxiosResponse } from "axios";
import BaseServiceHttp from "../../_metronic/helpers/functions/http/BaseServiceHttp";
import { AuthType, IResponse } from "../interface/I_General";

const API_URL = process.env.REACT_APP_API_URL;

// insere corretor
export async function insertCorretorAPI(data: any) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/insertCorretor`,
        data: {
            data
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar editar corretor'
                                }
                            }
                        });
    return response;

}

// edita o corretor
export async function editCorretorAPI(data: any) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/editCorretor`,
        data: {
            data
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar editar corretor'
                                }
                            }
                        });
    return response;

}

// deleta corretor
export async function deleteCorretorAPI(ids: any) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/deleteCorretor`,
        data: {
            ids
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar deletar corretor'
                                }
                            }
                        });
    return response;

}

export const getCorretorByName = async(name: string, auth: AuthType) => {
    
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getCorretorByName`,
                            { name },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}
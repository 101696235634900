export const TooltipCustom = (data:any) => {
    const { target, id, title, body } = data;
    
    const button = target.target as HTMLElement;
    const popover = document.createElement("div");
    popover.className = "popover bs-popover-auto fade show";
    popover.setAttribute("role", "tooltip");
    popover.innerHTML = `
        <div class="popover-arrow"></div>
        <h3 class="popover-header">${title}</h3>
        <div class="popover-body">${body}</div>
    `;

    // Define o ID do popover para remoção posterior
    popover.id = `popover-${id}`;

    // Obtém as coordenadas do botão em relação ao documento
    const buttonRect = button.getBoundingClientRect();
    
    const larguraPopover = 200;
    const alturaPopover = 120;
    
    // Define a posição do popover
    const top = buttonRect.bottom + window.scrollY;
    const left = buttonRect.left - larguraPopover;

    // Define o top e left do popover
    popover.style.position = 'absolute';
    popover.style.top = `${top}px`;
    popover.style.left = `${left}px`;
    popover.style.width = `${larguraPopover}px`;

    // Adiciona o popover ao corpo do documento
    document.body.appendChild(popover);
}
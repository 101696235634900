import axios, { AxiosError, AxiosResponse } from "axios";
import BaseServiceHttp from "../../_metronic/helpers/functions/http/BaseServiceHttp";
import { AuthType, IResponse } from "../interface/I_General";

const API_URL = process.env.REACT_APP_API_URL;

// retorna daods do cliente
export async function getClientByName(name: string, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getClientByName`,
                            { name },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}

// insere um cliente
export async function insertClientAPI(data: any, auth: AuthType) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/insertClient`,
        data: {
            data
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar criar cliente'
                                }
                            }
                        });
    return response;

}

// edita o cliente
export async function editClientAPI(data: any, auth: AuthType) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/editClient`,
        data: {
            data
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar editar cliente'
                                }
                            }
                        });
    return response;

}

// deleta cliente
export async function deleteClientAPI(ids: any, auth: AuthType) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/deleteClient`,
        data: {
            ids
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar deletar cliente'
                                }
                            }
                        });
    return response;

}
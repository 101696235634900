import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { TabHeaderUser } from "./TabHeaderUser";


const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Usuários',
      path: '/usuarios',
      isSeparator: false,
      isActive: false,
    },
    
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

export const UserWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Usuários</PageTitle>
            <TabHeaderUser/>
        </>

    )
}


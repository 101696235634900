
type PropsProgress = {
    height:string;
    color:string;
    progressPaid: {
        className: string;
        width: string;
        color: string;
        title: string;
        description: string;
        progressBar: string;
      };
      progressOverdue: {
        className: string;
        width: string;
        color: string;
        title: string;
        description: string;
        progressBar: string;
      };
      progressOutstanding: {
        className: string;
        width: string;
        color: string;
        title: string;
        description: string;
        progressBar: string;
      };
}
export const ProgressOneLine : React.FC<PropsProgress> = ({
    height,
    color,
    progressPaid, 
    progressOverdue,
    progressOutstanding,

}) => {
    // const {width} = progressSettle
    return (
        <>
            <div className={`progress ${height} bg-${color} bg-opacity-50`}>
                <div
                    className={`progress-bar bg-${progressPaid.color}`}
                    role='progressbar'
                    style={{width:progressPaid.width}}
                    
                >
                    <span className="text-center text-white">{progressPaid.width}</span>
                </div>

                <div
                    className={`progress-bar bg-${progressOverdue.color}`}
                    role='progressbar'
                    style={{width:progressOverdue.width}}
                    
                >
                    <span className="text-center text-white">{progressOverdue.width}</span>
                </div>

                <div
                    className={`progress-bar bg-${progressOutstanding.color}`}
                    role='progressbar'
                    style={{width:progressOutstanding.width}}
                    
                >
                    <span className="text-center text-white">{progressOutstanding.width}</span>
                </div>
                
            </div>
            
        </>
    )
}
export function isUri(path?: string)
{
    if (path !== undefined)
    {
        try {
            new URL(path);
            return true;
        } catch (_) {
            return false;  
        }
    }
    return false;

}
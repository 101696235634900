import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IFilterState {
    [key: string]: any;
}


const initialState: IFilterState = {};

const permissionSlice:any = createSlice({
    name: 'permissionSlice',
    initialState,
    reducers: {
        setPermission: (
            state,
            action: PayloadAction<IFilterState>
        ) => {
            const filterPayload = action.payload;

            Object.keys(filterPayload).forEach((key) => {
                
                state[key] = filterPayload[key];
            }); 
        },
        // setError: (state, action: PayloadAction<any | null>) => {
        //   state.error = action.payload;
        // },
    },
});

export const { setPermission  } = permissionSlice.actions;
export default permissionSlice.reducer;

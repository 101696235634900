import React, {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react'
import { useLocation } from 'react-router-dom'
import { permissionsUser } from '../../../app/request/permission'
import usePermissionStore from '../../../app/zustand/PermissionStore'
import {WithChildren} from '../../helpers'

const MetronicSplashScreenContext = createContext<Dispatch<SetStateAction<number>> | undefined>(
    undefined
)

const MetronicSplashScreenProvider: FC<WithChildren> = ({children}) => {
    const [count, setCount] = useState(0)
    let visible = count > 0


    useEffect(() => {
        const splashScreen = document.getElementById('splash-screen')

        // Show SplashScreen
        if (splashScreen && visible) {
            splashScreen.classList.remove('hidden')

            return () => {
                splashScreen.classList.add('hidden')
            }
        }

        // Hide SplashScreen
        let timeout: number
        if (splashScreen && !visible) {
            timeout = window.setTimeout(() => {
            splashScreen.classList.add('hidden')
        }, 3000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [visible])

    return (
        <MetronicSplashScreenContext.Provider value={setCount}>
        {children}
        </MetronicSplashScreenContext.Provider>
    )
}

const LayoutSplashScreen: FC<{visible?: boolean}> = ({visible = true}) => {
    // Everything are ready - remove splashscreen
    const setCount = useContext(MetronicSplashScreenContext)

    const permissionStore = usePermissionStore();  
    const location = useLocation();
    const path = location.pathname;

    // aplica permissao sempre que houver um carregamento da pagina, impedindo de perder permissoes setadas
    const loadPermission = async () => {        

        const partesDoCaminho = path.split('/');
        const parteEspecifica = partesDoCaminho.slice(1, 2).join('.');
        permissionStore.setNavigate(parteEspecifica);    
    }

    useEffect(() => {
        if (!visible) {
        return
        }

        loadPermission();

        if (setCount) {
        setCount((prev) => {
            return prev + 1
        })
        }

        return () => {
        if (setCount) {
            setCount((prev) => {
            return prev - 1
            })
        }
        }
  }, [setCount, visible])

    return null
}

export {MetronicSplashScreenProvider, LayoutSplashScreen}

import { KTSVG } from "../../../../_metronic/helpers";
import { FormPropsPaymentsButtons } from "../../../interface/I_Payments";

export const CampareBaixaBoletos = ({closeModal, data}: FormPropsPaymentsButtons) => {

    
    console.log('CampareBaixaBoletos ', data)
    return (
        <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal? "": "show"}`} id="modalEditParcel" tabIndex={-1} aria-modal="true" role="dialog" style={{"display": !closeModal? "none": 'block'}}>
                <div className="modal-dialog modal-xl p-5 p-lg-10" id="">
                    <div className="modal-content rounded-4">
                        <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                            <div id="" className="position-relative z-index-1"></div>

                            <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                                <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg"/>
                            </div>
                        </div>

                        <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="modaBody">
                            <div className="container-fluid">
                                <div className="d-block" id="kt_app_engage_prebuilts_view_image">
                                    <div className="tab-content">
                                        <div className="pt-5 tab-pane fade active show" id="" role="tabpanel">
                                            <div className="separator d-flex flex-center">
                                                <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Compare os pagamentos que foram dado baxa</span>
                                            </div>
                                            <div className="pe-12 me-n12" >
                                                <div className="row gy-5">
                                                    <div className="card-body pt-6">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                                                <thead>
                                                                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                                        <th className="p-0 pb-3 w-15px text-start">Identificador</th>
                                                                        <th className="p-0 pb-3 w-250px text-start">Pagador</th>
                                                                        <th className="p-0 pb-3 w-50px text-start">Valor</th>
                                                                        <th className="p-0 pb-3 w-75px text-start pe-12">Valor Pago</th>
                                                                        <th className="p-0 pb-3 w-75px text-start pe-7">Vencimento</th>
                                                                        <th className="p-0 pb-3 w-85px text-start">Data de pagamento</th>
                                                                        <th className="p-0 pb-3 w-50px text-start">Juros Mora</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {data ? (
                                                                        data.map((item: any, key: number) => (
                                                                            <tr key={key}>
                                                                                    <td className="text-start pe-0">
                                                                                        <span className="text-gray-600 fw-bold fs-6">#{item.identificador || '...'}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div className="d-flex justify-content-start flex-column">
                                                                                                <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                                                                                                    {item.pagador}
                                                                                                </a>
                                                                                                <span className="text-gray-400 fw-semibold d-block fs-7">
                                                                                                    Nosso Número: {item.numero} - Documento: {item.documento}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="text-start pe-0">
                                                                                        <span className="text-gray-600 fw-bold fs-6">{item.valor || '...'}</span>
                                                                                    </td>
                                                                                    <td className="text-start pe-0">
                                                                                        <span className="text-gray-600 fw-bold fs-6">{item.valor_pago || '...'}</span>
                                                                                    </td>
                                                                                    <td className="text-start pe-0">
                                                                                        <span className="text-gray-600 fw-bold fs-6">{item.vencimento || '...'}</span>
                                                                                    </td>
                                                                                    <td className="text-start pe-0">
                                                                                        <span className="text-gray-600 fw-bold fs-6">{item.data_credito || '...'}</span>
                                                                                    </td>
                                                                                    <td className="text-start pe-0">
                                                                                        <span className="text-gray-600 fw-bold fs-6">{item.juros_mora || '...'}</span>
                                                                                    </td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </tbody>


                                                            </table>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="modal-footer border-0">
                                                <button type="button" className="btn btn-light" onClick={closeModal}>Fechar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}
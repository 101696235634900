import { KTSVG } from "../../../../_metronic/helpers"; 
import { useEffect, useState } from "react";

import HeaderTableRemessa from "./HeaderTableRemessa";
import { TableOptions } from "../../../interface/I_Table";
import TableSimple from "../../../../_metronic/layout/components/tables/TableSimple";
import { IListRemessa } from "../../../interface/I_Configurations"; 
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { confirmationSendRemessaToBank, gerarRemessaApi } from "../../../request/configurations/_request";
import Swal from "sweetalert2";
import { useAuth } from "../../../modules/auth";
import { base64ToBlob, downloadRemessa, lastDownload } from "../../../../_metronic/helpers/functions/cnb/remessa";
import { IResponse } from "../../../interface/I_General";
import { LoadAnimation } from "../../../../_metronic/helpers/functions/swal/LoadAnimation";

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Configuração',
      path: '/configuration/access',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

export const ArquivoRemessa = () => {

    const {auth} = useAuth();
    const [isReload, setIsReload] = useState(false);

    const handlerDownloadRemessa = async (row:any,auth:any) => {
        
        let timerInterval : any
        Swal.fire({
            title: 'Fazendo download!',
            html: 'Aguarde seu download começará em  <b></b> ',
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                const b:any = Swal.getHtmlContainer()?.querySelector('b')
                timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
                }, 100)
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {}
        })

        const response = await downloadRemessa(row.pathSeverLocal, auth)
        
        if(response.data)
        {
            
            reloadTable()

            // Criar uma URL Blob a partir da string base64
            const pdfBlob = base64ToBlob(response.data);

            // Criar uma URL temporária para o Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Criar um link de download e disparar o clique nele
            const downloadLink = document.createElement('a');
            downloadLink.href = pdfUrl;
            downloadLink.download = row.name_file; // Nome do arquivo a ser baixado
            downloadLink.click();
            lastDownload(row.id, auth);
        }
    }

    const handlerConfirmationSendRemessaToBank = async (row:any,auth:any) => {
        const response = await confirmationSendRemessaToBank(row, auth)

        if(response.data)
        {
            Swal.fire(
                'Enviado!',
                'Marcado como enviado',
                'success'
            )

            reloadTable()
        }
    }
    
    // Propopriedade para montar header e  lass e endopoint dinamico
    const optionsInstallment: TableOptions = {
        header: HeaderTableRemessa('remessa', handlerDownloadRemessa, handlerConfirmationSendRemessaToBank),
        iInterface: 'IListRemessa',
        endPoint: 'tableFileRemessa',
        methodEndPoint: 'post',
        title:'Exportação de Arquivo Bancário CNAB',
        classTable: 'table table-hover table-rounded table-striped border gy-4 gs-4',
        classHeadTr: 'fw-semibold table-striped fs-6 text-gray-800 border-bottom border-gray-200',
        classHeadTd: '',
        classTbody:'fw-semibold text-gray-800 text-center',
        classtbodyTr:'',
        classTbodyTd:'',
        classTbodyTdDiv:'fw-normal text-gray-600'
    };

    const confirmGerarRemessa = () => {
        Swal.fire({
            title: 'Atenção',
            icon: 'info',
            html:`Deseja gerar remessa?`,
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
              'Sim, gerar!',
            confirmButtonAriaLabel: 'Sim, gerar!',
            cancelButtonText:'Não'
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                LoadAnimation('Estamos processando...');
                gerarRemessa()
            }
        });
    }

    const gerarRemessa = async () => {
        
        const response = await gerarRemessaApi(auth);

        if(response.data.success)
        {
            Swal.fire(
                'Tudo certo!',
                response.data.message,
                'success'
            )
            reloadTable()

        }else{
            Swal.fire(
                'Oops!',
                'Tente novamente em instantes',
                'info'
            )
        }
    }

    
    const reloadTable = () => {
        setIsReload(true);
    }

    useEffect(() => {
        if (isReload) {
          reloadTable();
          setIsReload(false);
        }
      }, [isReload]);
    
    
    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Exportação CNAB</PageTitle>
            <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
                <KTSVG path={""}/>
                <div className="d-flex flex-column">
                    <h4 className="mb-1 text-primary text-center mb-3">
                        <KTSVG className="svg-icon-2x svg-icon-primary" path={"/media/icons/duotune/communication/com013.svg"}></KTSVG>
                        <KTSVG className="svg-icon-2x svg-icon-success" path={"/media/icons/duotune/arrows/arr034.svg"}></KTSVG>
                        <KTSVG className="svg-icon-2x svg-icon-primary" path={"/media/icons/duotune/general/gen028.svg"}></KTSVG>
                        <KTSVG className="svg-icon-2x svg-icon-success" path={"/media/icons/duotune/arrows/arr034.svg"}></KTSVG>
                        <KTSVG className="svg-icon-2x svg-icon-primary" path={"/media/icons/duotune/finance/fin001.svg"}></KTSVG>
                    </h4>
                    <span>DICA: Exporte o arquivo remessa e envie-o para o banco após gerar todos os boletos do dia, assim você perderá menos tempo no sistema do banco.</span>
                    <span>Exibindo até no máximo 30 exportações de remessa em ordem a partir da mais antiga e não marcada como enviada ao banco.</span>
                </div>
            </div>
            <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100 ms-n4 mb-2 mt-3">
                <div className="page-title d-flex flex-column justify-content-center gap-1 me-3"></div>

                <div className="d-flex align-items-center gap-2 gap-lg-3">
                    <a 
                        type="button" 
                        className="btn btn-flex btn-outline btn-color-gray-700 btn-active-color-primary bg-body h-40px fs-7 fw-bold" 
                        onClick={() =>confirmGerarRemessa()}    
                    >
                        Gerar Remessa
                    </a>
                </div>
            </div>
            <div className="card card-flush mb-5 mb-xl-10">
                <TableSimple options={optionsInstallment} reloadTable={reloadTable} />
            </div>
        </>
    );
}

import { useEffect, useState } from "react";
import * as yup from 'yup';
import InputMask from 'react-input-mask';
import { SchemaBeneficiary } from "./SchemaBeneficiary";
import Swal from "sweetalert2";
import axios from "axios";
import { LoadAnimation } from "../../../../../_metronic/helpers/functions/swal/LoadAnimation";
import { useAuth } from "../../../../modules/auth";
import { ativarInativarBeneficiary, getBeneficiary, saveBeneficiary } from "../../../../request/configurations/_request_banks";
import { KTSVG } from "../../../../../_metronic/helpers";
import { Link } from "react-router-dom";

export const Beneficiary = () => {

    const {auth} = useAuth();
    
    const [listBeneficiarys, setListBeneficiarys] = useState<any>([]);    

    useEffect(() => {
        loadListBeneficiary();
    }, [])

    const loadListBeneficiary = async () => {

        if( auth && auth.api_token)
        {
            try {
                const response = await getBeneficiary(auth);
                if (response.data && response.data && Array.isArray(response.data)) {
                    
                    setListBeneficiarys(response.data);
                }

            } catch (error) {
                console.error('Erro ao carregar bancos:', error);
            }

        }
    }

    const [formValues, setFormValues] = useState<any>({
        id:'',
        name: "",
        name_apresentation: "",
        registration_type: "cpf",
        phone: "",
        email: "",
        cpf: "",
        cnpj: "",
        zip_code: "",
        country: "",
        number: "",
        address: "",
        neighborhood:"",
        city: "",
        state: "",
        active: "",
        state_full: "",
        complement: "",
    });    

    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({
        name: "",
        name_apresentation: "",
        registration_type: "",
        phone: "",
        zip_code: "",
        country: "",
        number: "",
        address: "",
        neighborhood:"",
        city: "",
        state: "",
    });
    
    const [tabList, setTabList] = useState(true);
    const [tabRegister, setRegister] = useState(false);

    const clickTabListBeneficiary = () => {
        setFormValues({})
        setFormErrors({})
        setTabList(true);
        setRegister(false);
    }

    const clickTabRegisterBeneficiary = () => {

        setTabList(false);
        setRegister(true);
    }

    const removerChave = (chaveParaRemover:string) => {
        
        if(!!chaveParaRemover)
        {
            const novoEstado = { ...formErrors };
            delete novoEstado[chaveParaRemover];
        
            setFormErrors(novoEstado);
        }
    };

    const handleInputChange = (
        value: string|number,
        fieldName: string
      ) => {

        setFormValues((prevState:any) => ({
            ...prevState,
            [fieldName]: value 
        }));

        let removeChave = fieldName === 'cpf' ? 'cnpj' : fieldName === 'cnpj' ? 'cpf' : ''
        removerChave(removeChave)

        yup
        .reach(SchemaBeneficiary, fieldName)
        .validate(value)
        .then(() => {
            // Se o campo for válido, remove o erro correspondente
            setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '' }));
        })
        .catch((error: { message: any; }) => {
            // Se o campo for inválido, define o erro correspondente
            setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName]: error.message }));
        });

    };

    // cep
    const handleCepBlur = async () => {
        
        const cep = formValues.zip_code.replace(/\D/g, '');

        if (!cep)
        {
            return;
        }
      
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            
            if (response.data.erro) {
                // CEP não encontrado
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Cep não encontrado'
                }); 
                setFormValues({
                    ...formValues,
                    zip_code: '',
                    address: '',
                    neighborhood: '',
                    city: '',
                    state: '',
                    country: '',
                }); 
            } else {
                setFormValues({
                    ...formValues,
                    zip_code: response.data.cep || "",
                    address: response.data.logradouro || "",
                    neighborhood: response.data.bairro || "",
                    city: response.data.localidade || "",
                    state: response.data.uf || "",
                    country: response.data.pais || "",
                });
            }

        } catch (error) {
            
            setFormValues({
                ...formValues,
                zip_code: '',
                address: '',
                neighborhood: '',
                city: '',
                state: '',
                country: '',
            });
        }
    };


    const confirmSaveForm = () => {
        Swal.fire({
            icon: "info",
            title: "Atenção!",
            text: 'Deseja salvar este Beneficiario?',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, Salvar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed)
            {
                saveForm();
            }
        }); 
    }

    const saveForm = async () => {
        
        try {
            await SchemaBeneficiary.validate(formValues, { abortEarly: false });

            setFormErrors({}); 
            
            LoadAnimation('Estamos processando...');

            if( auth && auth.api_token)
            {
                const response = await saveBeneficiary(formValues, auth);

                if(response.data.success)
                {
                    Swal.fire({
                        icon: "success",
                        title: "Parabéns",
                        text: `Dados salvo com sucesso`,
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                    });
                    
                }else{
                    Swal.fire({
                        icon: "info",
                        title: "Atenção",
                        html: `Não foi possível salvar, verifique a conexao com interne, <br> ou fale com um administrador do sistema.`,
                        showCancelButton: false,
                        confirmButtonText: 'Entendi',
                    });
                }
            }
        }catch (errors) {

            if (errors instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                errors.inner.forEach((error) => {
                                        
                    validationErrors[error.path as string] = error.message
                });
                setFormErrors(validationErrors);
            }
        }
    }
    
    const handlerEditAccount = (data:any) => {

        setTabList(false);
        setRegister(true);
        setFormValues({...data})
    }
    
    const handleCheckboxChange = (index: number, id:any) => {
        
        const updatedBeneficiary = [...listBeneficiarys];
        
        updatedBeneficiary[index].active   = updatedBeneficiary[index].active === '1' ? '2' : '1';

        setFormValues(updatedBeneficiary);

        handlerAtivarInativar(id, updatedBeneficiary[index].active);
    };

    const handlerAtivarInativar = async (id:any, active:any) => {

        if( auth && auth.api_token)
        {
            let dataActive = {
                id:id,
                active:active
            }
            const responseActive = await ativarInativarBeneficiary(dataActive, auth);
        }
    }
    
    return (
        <>
            <div className="card-header mt-6">
                <div className="d-flex flex-wrap flex-stack pb-7">
                    <div className="d-flex flex-wrap align-items-center my-1">
                        <h3 className="fw-bold me-5 my-1"></h3>
                    </div>

                    <div className="d-flex flex-wrap my-1">
                        <ul className="nav nav-pills me-6 mb-2 mb-sm-0" role="tablist">
                            <li className="nav-item m-0" role="presentation">
                                <a 
                                    className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3 ${tabList ? 'active' : ''}`} 
                                    data-bs-toggle="tab" 
                                    href="#list_beneficiary" 
                                    aria-selected="true" 
                                    role="tab"
                                    onClick={() => clickTabListBeneficiary()}
                                >
                                    <KTSVG path="/media/icons/duotune/layouts/lay010.svg"/>
                                </a>
                            </li>

                            <li className="nav-item m-0" role="presentation">
                                <a 
                                    className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary ${tabRegister ? 'active' : ''}`} 
                                    data-bs-toggle="tab" 
                                    href="#register_beneficiary" 
                                    aria-selected="false" 
                                    tabIndex={1} 
                                    role="tab"
                                    onClick={() => clickTabRegisterBeneficiary()}
                                >
                                    <KTSVG path="/media/icons/duotune/general/gen025.svg"/>
                                </a>
                            </li>
                        </ul>

                        <div className="d-flex my-0"></div>
                    </div>
                </div>

            </div>
            <div className="row gx-6 gx-xl-9">
                <div className="col-lg-12">
                    <div className="card card-flush h-lg-100">
                        {
                            tabList && (

                                <div id="list_beneficiary" className="tab-pane fade show active" role="tabpanel">
                                    <div className="card-header">
                                        <div className="card-title flex-column">
                                            <h3 className="fw-bold mb-1">Beneficiarios cadastrados</h3>
                                        </div>
                                    </div>
            
                                    <div className="card-body p-9 pt-5">
                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <div className="fw-semibold">
                                                    <div className="fs-6 text-gray-700">Beneficiários ativos a que podem serem vinculados a uma ocnta bancaria</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-2">
            
                                            {
                                                listBeneficiarys && listBeneficiarys.map((beneficiary:any, index:number) => (
                                                        <div key={index} className="d-flex flex-stack mt-7">
                                                            <div className="d-flex">
                                                                            
                                                                <div className="d-flex flex-column">
                                                                    <Link className="fs-5 text-dark text-hover-primary fw-bold"  onClick={() => handlerEditAccount(beneficiary)} to={`#`}>{beneficiary.name_apresentation} <KTSVG path="/media/icons/duotune/general/gen055.svg"/></Link>
                                                                    {/* <a href="#" className="fs-5 text-dark text-hover-primary fw-bold"></a> */}
                                                                    <div className="fs-6 fw-semibold text-gray-400">
                                                                        {
                                                                            
                                                                            beneficiary.registration_type === 'cpf' ? `${beneficiary.cpf} - ${beneficiary.name}` : `${beneficiary.cnpj} - ${beneficiary.name}`   
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-end">
                                                                <div className="form-check form-check-solid form-check-custom form-switch">
                                                                    <input 
                                                                        className="form-check-input w-45px h-30px" 
                                                                        type="checkbox" 
                                                                        id={beneficiary.id}
                                                                        checked={beneficiary.active === '1'} // Use o valor do estado banks
                                                                        onChange={() => handleCheckboxChange(index, beneficiary.id)} 
                                                                    />
                                                                    <label className="form-check-label" htmlFor="googleswitch"></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                            }
                                            
            
                                            <div className="separator separator-dashed my-5"></div>
                                        </div>
            
                                    </div>
                                </div>
                            )
                        }

                        {
                            tabRegister && (

                                <div id="register_beneficiary" className="tab-pane fade show active" role="tabpanel">
                                    <div className="card-header">
                                        <div className="card-title flex-column">
                                            <h3 className="fw-bold mb-1">Dados cadastrais do beneficiário</h3>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-4">
                                                <span className="">DADOS</span>
                                                <div className="col-sm-12 col-md-12 col-lg-12 mt-3 mb-4">
                                                    
                                                    <div className="col-md-12 mt-3 row">
                                                        <div className="form-check col-md-3 col-sm-12 form-check-custom form-check-solid form-check-sm">
                                                            <input 
                                                                className="form-check-input" 
                                                                type="radio" 
                                                                id="cpf"
                                                                onChange={(e:any) => {

                                                                    setFormValues((prevState:any) => ({
                                                                        ...prevState,
                                                                        ['cnpj']: '',
                                                                    }));

                                                                    handleInputChange('cpf', "registration_type");
                                                                    }
                                                                }
                                                                name="registration_type"  
                                                                checked={formValues.registration_type === 'cpf'}
                                                                value={formValues.registration_type || 'cpf'} 
                                                            />
                                                            <label className="ms-3" htmlFor="">CPF</label>
                                                        </div>

                                                        <span className="fs-9 col-md-6">TIPO DE INSCRIÇÃO</span>

                                                        <div className="form-check col-md-3 col-sm-12 form-check-custom form-check-solid form-check-sm">
                                                            <input 
                                                                className="form-check-input" 
                                                                type="radio" 
                                                                id="cnpj"
                                                                onChange={(e:any) => {

                                                                    setFormValues((prevState:any) => ({
                                                                        ...prevState,
                                                                        ['cpf']: '',
                                                                    }));

                                                                    handleInputChange('cnpj', "registration_type");
                                                                    }
                                                                }
                                                                name="registration_type" 
                                                                checked={formValues.registration_type === 'cnpj'}
                                                                value={formValues.registration_type || 'cnpj'} 
                                                            />
                                                            <label className="ms-3" htmlFor="">CNPJ</label>
                                                        </div>
                                                        
                                                        {formErrors.registration_type && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.registration_type}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-12 col-lg-12 fv-row fv-plugins-icon-container form-floating mb-7">
                                                    { 
                                                        formValues.registration_type === 'cpf' 
                                                        ? (
                                                            <>
                                                                <InputMask
                                                                    className={`form-control form-control-lg ${formErrors.cpf ? ' is-invalid' : ''}`} placeholder="cpf"
                                                                    mask="999.999.999-99"
                                                                    name="cpf"
                                                                    value={formValues.cpf || ''}
                                                                    // onChange={handleChange}
                                                                    onChange={(e:any) => {
                                                                        handleInputChange(e.target.value || '', "cpf");
                                                                        }
                                                                    }
                                                                />
                                                                <label className="ms-3" htmlFor="">CPF</label>
                                                                {formErrors.cpf && (
                                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.cpf}</div>
                                                                )} 
                                                            </>
                                                        )
                                                        : (
                                                            <>
                                                                <InputMask
                                                                    className={`form-control form-control-lg ${formErrors.cnpj ? ' is-invalid' : ''}`} placeholder="cnpj"
                                                                    mask="99.999.999/9999-99"
                                                                    // 14.785.152/0001-51
                                                                    name="cnpj"
                                                                    value={formValues.cnpj || ''}
                                                                    // onChange={handleChange}
                                                                    onChange={(e:any) => {
                                                                        handleInputChange(e.target.value || '', "cnpj");
                                                                        }
                                                                    }
                                                                />
                                                                <label className="ms-3" htmlFor="">CNPJ</label>
                                                                {formErrors.cnpj && (
                                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.cnpj}</div>
                                                                )} 
                                                            </>
                                                        )
                                                
                                                    }
                                                                                        
                                                </div>
                                                

                                                <div className="col-sm-12 col-md-12 col-lg-12 fv-row fv-plugins-icon-container form-floating">

                                                    <input 
                                                    type="text" 
                                                        name="name" value={formValues.name || ''} id="name" 
                                                        onChange={(e:any) => {

                                                            handleInputChange(e.target.value || '', "name");
                                                            }
                                                        }
                                                        className={`form-control ${formErrors.name ? ' is-invalid' : ''}`} 
                                                        placeholder="Beneficiário" 
                                                    />
                                                    <label className="ms-3" htmlFor="name">Beneficiário (Razão Social)</label>
                                                    {formErrors.name && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.name}</div>
                                                    )}
                                                    
                                                </div>

                                                <div className="col-sm-12 col-md-12 mt-4 col-lg-12 fv-row fv-plugins-icon-container form-floating">

                                                    <input 
                                                        type="text" 
                                                        name="name_apresentation" 
                                                        value={formValues.name_apresentation || ''} 
                                                        id="name_apresentation" 
                                                        onChange={(e:any) => {

                                                            handleInputChange(e.target.value || '', "name_apresentation");
                                                            }
                                                        }
                                                        className={`form-control ${formErrors.name_apresentation ? ' is-invalid' : ''}`} 
                                                        placeholder="Nome Fantasia" 
                                                    />
                                                    <label className="ms-3" htmlFor="name_apresentation">Nome Fantasia</label>
                                                    {formErrors.name_apresentation && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.name_apresentation}</div>
                                                    )}
                                                    
                                                </div>

                                                <div className="col-sm-12 col-md-12 col-lg-12 mt-3 fv-row fv-plugins-icon-container form-floating">

                                                    <input 
                                                        type="email" 
                                                        name="email" 
                                                        value={formValues.email || ''} 
                                                        id="email" 
                                                        onChange={(e:any) => {

                                                            handleInputChange(e.target.value || '', "email");
                                                            }
                                                        } 
                                                        className={`form-control ${formErrors.email ? ' is-invalid' : ''}`} 
                                                        placeholder="Email" 
                                                    />
                                                    <label className="ms-3" htmlFor="email">Email</label>
                                                    {formErrors.email && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.email}</div>
                                                    )}
                                                    
                                                </div>

                                                <div className="col-sm-12 col-md-12 col-lg-12 mt-3 fv-row fv-plugins-icon-container form-floating">
                                                    
                                                    <InputMask
                                                        className={`form-control ${formErrors.phone ? ' is-invalid' : ''}`}
                                                        placeholder="Telefone" 
                                                        id="phone"
                                                        mask="(99) 99999-9999"
                                                        name="phone"
                                                        value={formValues.phone || ''}
                                                        onChange={(e:any) => {

                                                            handleInputChange(e.target.value || '', "phone");
                                                            }
                                                        }
                                                    />
                                                    <label className="ms-3" htmlFor="phone">Telefone</label>
                                                    
                                                    {formErrors.phone && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.phone}</div>
                                                    )} 
                                                                                        
                                                </div>


                                            </div>

                                            <div className="col-sm-12 col-md-8 row">
                                                <span className="mb-3">ENDEREÇO</span>
                                                <div className="col-sm-12 col-md-6 col-lg-6 fv-row fv-plugins-icon-container form-floating">
                                                    
                                                    <InputMask
                                                        className={`form-control ${formErrors.zip_code ? ' is-invalid' : ''}`}
                                                        placeholder="Cep" 
                                                        id="zip_code"
                                                        mask="99999-999"
                                                        name="zip_code"
                                                        value={formValues.zip_code || ''}
                                                        // unmask
                                                        onBlur={handleCepBlur}
                                                        onChange={(e:any) => {

                                                            handleInputChange(e.target.value || '', "zip_code");
                                                            }
                                                        }
                                                    />
                                                    <label className="ms-3" htmlFor="zip_code">Cep</label>
                                                    
                                                    {formErrors.zip_code && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.zip_code}</div>
                                                    )} 
                                                                                        
                                                </div>
                                                                                    
                                                <div className="col-sm-12 col-md-6 col-lg-6 fv-row fv-plugins-icon-container form-floating">
                                                    
                                                    <input 
                                                        type="text" 
                                                        name="address" 
                                                        id="address" 
                                                        className={`form-control ${formErrors.address ? ' is-invalid' : ''}`}  
                                                        placeholder="Endereço" 
                                                        value={formValues.address || ''} 
                                                        onChange={(e:any) => {

                                                            handleInputChange(e.target.value || '', "address");
                                                            }
                                                        }
                                                    />
                                                    <label className="ms-3" htmlFor="address">Endereço</label>
                                                    {formErrors.address && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.address}</div>
                                                    )} 
                                                                                        
                                                </div>
                                                
                                                <div className="col-sm-12 col-md-2 col-lg-2 fv-row fv-plugins-icon-container form-floating">
                                                                                                    
                                                    <input 
                                                        type="text" 
                                                        name="number" 
                                                        id="" 
                                                        className={`form-control ${formErrors.number ? ' is-invalid' : ''}`}  
                                                        placeholder="Número" 
                                                        value={formValues.number || ''} 
                                                        onChange={(e:any) => {

                                                            handleInputChange(e.target.value || '', "number");
                                                            }
                                                        }
                                                    />
                                                    <label className="ms-3" htmlFor="number">Número</label>
                                                    {formErrors.number && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.number}</div>
                                                    )}                       
                                                </div>
                                                
                                                <div className="col-sm-12 col-md-5 col-lg-5 fv-row fv-plugins-icon-container form-floating">
                                                                                                    
                                                    <input 
                                                        type="text" 
                                                        name="neighborhood"
                                                        className={`form-control ${formErrors.neighborhood ? ' is-invalid' : ''}`} 
                                                        placeholder="Bairro" 
                                                        value={formValues.neighborhood || ''} 
                                                        onChange={(e:any) => {

                                                            handleInputChange(e.target.value || '', "neighborhood");
                                                            }
                                                        }
                                                    />
                                                    <label className="ms-3" htmlFor="">Bairro</label>
                                                    {formErrors.neighborhood && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.neighborhood}</div>
                                                    )} 
                                                                                        
                                                </div>
                                                
                                                <div className="col-sm-12 col-md-5 col-lg-5 fv-row fv-plugins-icon-container form-floating">
                                                    
                                                    <input 
                                                        type="text" 
                                                        name="city" 
                                                        id="city"  
                                                        className={`form-control ${formErrors.city ? ' is-invalid' : ''}`} 
                                                        placeholder="Cidade" 
                                                        value={formValues.city || ''}
                                                        onChange={(e:any) => {

                                                            handleInputChange(e.target.value || '', "city");
                                                            }
                                                        } 
                                                    />
                                                    <label className="ms-3" htmlFor="city">Cidade</label>
                                                    {formErrors.city && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.city}</div>
                                                    )}                                  
                                                </div>
                                                
                                                <div className="col-sm-12 col-md-2 col-lg-2 fv-row fv-plugins-icon-container form-floating">
                                                    
                                                    <input 
                                                        type="text" 
                                                        name="state" 
                                                        id="state"
                                                        className={`form-control ${formErrors.state ? ' is-invalid' : ''}`} 
                                                        placeholder="Estado" 
                                                        value={formValues.state || ''}
                                                        onChange={(e:any) => {

                                                            handleInputChange(e.target.value || '', "state");
                                                            }
                                                        } 
                                                    />
                                                    <label className="ms-3" htmlFor="state">Estado</label>
                                                    {formErrors.state && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.state}</div>
                                                    )}                                
                                                </div>
                                                
                                                {/* <div className="col-sm-12 col-md-2 col-lg-2 fv-row fv-plugins-icon-container form-floating mb-7">
                                                        
                                                    <input type="text" name="country" id="country" onChange={handleChange} className={`form-control ${formErrors.country ? ' is-invalid' : ''}`} placeholder="Nacionalidade" value={formValues.country || ''} />
                                                    <label className="ms-3" htmlFor="country">Nacionalidade</label>
                                                    {formErrors.country && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.country}</div>
                                                    )}
                                                                                        
                                                </div> */}
                                                
                                                <div className="col-sm-12 col-md-10 col-lg-10 fv-row fv-plugins-icon-container form-floating">
                                                    <input 
                                                        type="text" 
                                                        name="complement" 
                                                        id="complement" 
                                                        className={`form-control ${formErrors.complement ? ' is-invalid' : ''}`} 
                                                        placeholder="" 
                                                        value={formValues.complement || ''} 
                                                        onChange={(e:any) => {

                                                            handleInputChange(e.target.value || '', "complement");
                                                            }
                                                        }
                                                    />
                                                    <label className="ms-3" htmlFor="complement">Complemento</label>
                                                    {formErrors.complement && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.complement}</div>
                                                    )}
                                                                                        
                                                </div>

                                            </div>
                                            
                                        </div>
                                        
                                        <div className="card-footer pt-4 mt-3" >
                                            <div className='d-flex justify-content-center'>

                                                <button type='button' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'
                                                    onClick={()  => confirmSaveForm()}
                                                >
                                                    Salvar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    
                </div>
            </div>
        </>
    )
}
import * as yup from 'yup';

export const SchemaBeneficiary = yup.object().shape({

    name: yup.string().nullable().required('O campo Beneficiário é obrigatório'),
    name_apresentation: yup.string().nullable().required('O campo nome de apresentação é obrigatório'),
    registration_type: yup.string().nullable().required('O tipo de inscrição é obrigatório'),
    phone: yup.string().matches(/^\(\d{2}\)\s\d{4,5}-\d{4}$/, 'Insira um telefone válido').nullable().required('O campo Telefone é obrigatório'),
    email: yup.string().email('Por favor, insira um e-mail válido').nullable().required('O campo Email é obrigatório'),
    zip_code: yup.string().matches(/^\d{5}-\d{3}$/, 'Insira um CEP válido').nullable().required('O campo CEP é obrigatório'),
    cpf: yup.string().when('registration_type', {
        is: 'cpf',
        then: yup.string()
          .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'Insira um CPF válido')
          .required('O campo CPF é obrigatório'),
        otherwise: yup.string().nullable(),
    }), 
    cnpj: yup.string().when('registration_type', {
        is: 'cnpj',
        then: yup.string()
          .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'Insira um CNPJ válido')
          .required('O campo CNPJ é obrigatório'),
        otherwise: yup.string().nullable(),
    }), 
    // rg: yup.string().nullable().required('O campo Rg é obrigatório'),
    // country: yup.string().nullable().required('O campo País é obrigatório'),
    number: yup.string().nullable().required('Obrigatório'),
    address: yup.string().nullable().required('O campo Endereço é obrigatório'),
    neighborhood: yup.string().nullable().required('O campo Bairro é obrigatório'),
    city: yup.string().nullable().required('O campo Cidade é obrigatório'),
    state: yup.string().nullable().required('Obrigatório')
});
import React, { useEffect, useState } from "react";
import { SchemaRegisterUser } from "./SchemaRegisterUser";
import * as yup from 'yup';
import Swal from "sweetalert2";
import { LoadAnimation } from "../../../_metronic/helpers/functions/swal/LoadAnimation";
import { useAuth } from "../../modules/auth";
import { registerUser, updateUser } from "../../request/user";
import Select from "react-select";
import { Option } from "../../interface/I_General";
import { IPermission } from "../../interface/IUser";
import { optionsUsers } from "../../globals/OptionsGlobals";

export const RegisterUser = ({user}:any) => {

    const {auth} = useAuth();

    const [formValues, setFormValues] = useState<any>(user || {
        
        email:'',
        username:'',
        password:'',
        password_confirm:'',
        validate_password:true
        // permission:'',
    });

    const [formErrors, setFormErrors] = useState<any>({
        email:'',
        username:'',
        password:'',
        password_confirm:'',
        // permission:''
    });

    const handleInputChange = (
        value: string|number,
        fieldName: string
      ) => {

        setFormValues((prevState:any) => ({
            ...prevState,
            [fieldName]: value 
        }));

        
        yup
        .reach(SchemaRegisterUser, fieldName)
        .validate(value)
        .then(() => {
            // Se o campo for válido, remove o erro correspondente
            setFormErrors((prevErrors:any) => ({ ...prevErrors, [fieldName]: '' }));
        })
        .catch((error: { message: any; }) => {
            // Se o campo for inválido, define o erro correspondente
            setFormErrors((prevErrors:any) => ({ ...prevErrors, [fieldName]: error.message }));
        });

    };

    const confirmSaveForm = () => {
        Swal.fire({
            icon: "info",
            title: "Atenção!",
            text: 'Deseja salvar este Usuario?',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, Salvar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed)
            {
                saveForm();
            }
        }); 
    }

    const saveForm = async () => {
        
        try {
            await SchemaRegisterUser.validate(formValues, { abortEarly: false });

            setFormErrors({}); 
            
            LoadAnimation('Estamos processando...');

            if( auth && auth.api_token)
            {
                let response;
                if(!!formValues.id)
                {
                    response = await updateUser(formValues, auth);
                }else{
                    
                    response = await registerUser(formValues, auth);
                }
                
                if(response.data.success)
                {
                    Swal.fire({
                        icon: "success",
                        title: "Parabéns",
                        text: `Dados salvo com sucesso`,
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                    });
                    
                }else{
                    Swal.fire({
                        icon: "info",
                        title: "Atenção",
                        html: `Não foi possível salvar, verifique a conexao com interne, <br> ou fale com um administrador do sistema.`,
                        showCancelButton: false,
                        confirmButtonText: 'Entendi',
                    });
                }
            }
        }catch (errors) {

            if (errors instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                errors.inner.forEach((error) => {
                                        
                    validationErrors[error.path as string] = error.message
                });
                setFormErrors(validationErrors);
            }
        }
    }

    const handleSelectChange = <K extends keyof IPermission>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";
        const label = selectedOption ? selectedOption.label : "";
        
        setFormValues((prevState:any) => ({
            ...prevState,
            [fieldName]: value
        }));
        
    };

    const handleCheckboxChange = () => {
        
        setFormValues((prevState:any) => ({
            ...prevState,
            ['validate_password']: !formValues.validate_password

        }));
    };
    

    return (

        <form >
            <div className="row">
                <div className="col-md-12">
                    <div className="pb-4 border-bottom">
                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                            <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                                Validar senhas?
                            </span>

                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                value={formValues.validate_password ? 1 :  0} 
                                checked={formValues.validate_password || false}
                                onChange={() => handleCheckboxChange()} 
                            />
                        </label>
                    </div>

                    <div className='mb-10 row'>

                        <div className="col-md-6 col-sm-12 form-floating mt-4">
                            <input 
                                className={`form-control ${formErrors.email ? ' is-invalid' : ''}`}
                                id="email"
                                onChange={(e:any) => {

                                        setFormValues((prevState:any) => ({
                                            ...prevState,
                                            ['email']: '',
                                        }));

                                        handleInputChange(e.target.value || '', "email");
                                    }
                                }
                                placeholder="Email"
                                name="email"
                                value={formValues.email || ''} 
                            />
                            <label className="ms-3" htmlFor="">Email</label>
                            {formErrors.email && (
                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.email}</div>
                            )}
                        </div>   

                        <div className="col-md-6 col-sm-12 form-floating mt-4">
                            <input  
                                name="username" value={formValues.username || ''} 
                                id="username" 
                                onChange={(e:any) => {

                                    handleInputChange(e.target.value || '', "username");
                                    }
                                }
                                className={`form-control ${formErrors.username ? ' is-invalid' : ''}`} 
                                placeholder="Nome de usuário" 
                            />
                            <label className="ms-3" htmlFor="name">Nome de usuário</label>
                            {formErrors.username && (
                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.username}</div>
                            )}
                        </div>

                        {/* Permissao */}
                        {/* <div className="col-md-4 col-sm-12 ">
                            <h5 className="fs-6">SELECIONE UMA PERMISSÃO</h5>
                            <div className=" mb-7 mt-1">                            
                                <div className='d-flex form-floating'>                                
                                    <Select
                                        styles={{ container: (base) => ({ ...base, zIndex: 11 }) }}
                                        className={`w-100 ${formErrors.permission ? ' is-invalid' : ''}`}
                                        placeholder="Tipo de permissão"
                                        value={
                                            !!formValues.permission
                                            ? optionsUsers.find((option:Option) => option.value === formValues.permission)
                                            : {value:'', label:'Selecione'}
                                        }
                                        onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "permission")}
                                        options={optionsUsers}
                                    />
                                    <label className="ms-3" htmlFor="name">Tipo de permissão</label>
                                </div>
                            </div>
                            {formErrors.permission && (
                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.permission}</div>
                            )}
                        </div> */}
                        
                        {
                            formValues.validate_password && (
                                <>
                                    <div className="col-md-6 col-sm-12 form-floating mt-3">
                                        <input
                                            name="password"
                                                value={formValues.password || ''} 
                                            id="password" 
                                            onChange={(e:any) => {

                                                handleInputChange(e.target.value || '', "password");
                                                }
                                            }
                                            className={`form-control ${formErrors.password ? ' is-invalid' : ''}`} 
                                            placeholder="Senha" 
                                        />
                                        <label className="ms-3" htmlFor="name">Senha</label>
                                        {formErrors.password && (
                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.password}</div>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-sm-12 form-floating mt-3">
                                        <input
                                            name="password_confirm"
                                            value={formValues.password_confirm || ''} 
                                            id="password_confirm" 
                                            onChange={(e:any) => {

                                                handleInputChange(e.target.value || '', "password_confirm");
                                                }
                                            }
                                            className={`form-control ${formErrors.password_confirm ? ' is-invalid' : ''}`} 
                                            placeholder="Repetir senha" 
                                        />
                                        <label className="ms-3" htmlFor="name">Repetir senha</label>
                                        {formErrors.password_confirm && (
                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.password_confirm}</div>
                                        )}
                                    </div>
                                </>
                            )
                        }                        

                    </div>
                </div>
                
            </div>
            <div className="card-footer pt-4" >
                <div className='d-flex justify-content-center'>

                    <button type='button' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'
                        onClick={()  => confirmSaveForm()}
                    >
                        Salvar
                    </button>
                </div>
            </div>
        </form>
    )
}
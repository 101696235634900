import React, { useMemo, useState, useEffect, InputHTMLAttributes } from "react";
import { useTable, usePagination,  TableToggleAllRowsSelectedProps, useRowSelect, Column, Row, IdType } from "react-table";
import axios from "axios";
import { KTSVG } from "../../../../_metronic/helpers"; 
import getHeaderForType from "./HeaderTableTasks";

interface Data {
  contrato: string;
  cliente: string;
  data_solicitacao: string;
  contato:string;
}

interface IPropops {
    gotoPage: (go:number) => void;
    previousPage: () => void;
    nextPage: () => void;
    page:any;
    totalCount:number;
    pageCount:number;
    disabledPrevious:string;
    disabledNext:string;
}

const TablesTasks = ({ api, type }: { api?: string, type: string }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Data[]>([]);
    
    // const [selectedRows, setSelectedRows] = useState<Data[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);

    const [propsPaginate, setProposPaginate] = useState<IPropops>();

    /**
     * Inicio cria um componente Checkbox e  resolve problema com inderteminate
     */
  
    const IndeterminateCheckbox = React.forwardRef<
        HTMLInputElement,
        { indeterminate?: boolean } & InputHTMLAttributes<HTMLInputElement>
        >(
            ({ indeterminate, ...rest }, ref) => {
                const defaultRef = React.useRef<HTMLInputElement>(null);
                const resolvedRef = (ref || defaultRef) as React.MutableRefObject<HTMLInputElement>;

                React.useEffect(() => {
                    if (resolvedRef && resolvedRef.current) {
                        resolvedRef.current.indeterminate = indeterminate ?? false;
                    }
                }, [resolvedRef, indeterminate]);

                return (
                    <>
                        <input type="checkbox" ref={resolvedRef} {...rest} />
                    </>
                );
            }
        );


    /**
     * Fim checkBox
     */

    const API_URL = process.env.REACT_APP_API_URL
  

    const columns = React.useMemo(
        () =>

        getHeaderForType(type).map((header) => ({
            Header: header.Header,
            accessor: header.accessor as keyof Data,
          })),
        [type]
      );

    let _api_dinamic = api != null ? api : 'tableInstallmentAnticipation'; // antecipação de parcelas

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            const result = await axios.post(

                `${API_URL}/module/${_api_dinamic}?page=${pageIndex}&pageSize=${pageSize}`,
                {}, // passando um corpo vazio, caso não precise enviar nenhum dado no corpo da requisição
                {
                    headers: {
                    'Content-Type': 'application/json'
                    }
                }
            // `https://example.com/api/data?page=${pageIndex}&pageSize=${pageSize}`
            );

            setData(result.data.records);
            setTotalCount(result.data.totalRecords);
            setLoading(false);
            
            setProposPaginate(
                {
                    gotoPage,
                    previousPage,
                    nextPage,
                    page,
                    totalCount,
                    pageCount,
                    disabledPrevious, 
                    disabledNext
                }
            );
        };

        fetchData();

    }, [pageIndex, pageSize, api]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        } = useTable<Data>(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
              {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                        {/* <input  className="form-check-input widget-9-check" type="checkbox" {...getToggleAllRowsSelectedProps()} /> */}
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="form-check-input widget-9-check"/>
                    </div>
                ),
                Cell: ({ row }: { row: Row<Data> }) => (
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                        {/* <input
                            type="checkbox" className="form-check-input widget-9-check"
                            {...row.getToggleRowSelectedProps(
                            
                            )}
                        /> */}
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} className="form-check-input widget-9-check"/>
                    </div>
                ),
              },
              ...columns,
            ]);
        }

    );
    const disabledPrevious = canPreviousPage == true ? 'disabled' : '';
    const disabledNext = canNextPage == true ? 'disabled' : '';

  return (
            <div className="flex-lg-row-fluid ms-lg-5 ms-xl-5">
                <div className="card">           
                    <div className="card-header align-items-center py-5 gap-5">
                        <div className="d-flex">
                            

                            <a href="#" className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Archive" data-bs-original-title="Archive" data-kt-initialized="1">
                                <KTSVG path="/media/icons/duotune/general/gen043.svg" className="svg-icon-muted svg-icon-1hx" />
                            </a>

                            <a href="#" className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Spam" data-bs-original-title="Spam" data-kt-initialized="1">
                                <KTSVG path="/media/icons/duotune/communication/com002.svg" className="svg-icon-muted svg-icon-1hx" />
                            </a>

                            <a href="#" className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Delete" data-bs-original-title="Delete" data-kt-initialized="1">
                                <KTSVG path="/media/icons/duotune/general/gen002.svg" className="svg-icon-muted svg-icon-1hx" />
                            </a>

                            <a href="#" className="btn btn-sm btn-icon btn-light btn-active-light-primary" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Move" data-bs-original-title="Move" data-kt-initialized="1">
                                <KTSVG path="/media/icons/duotune/general/gen040.svg" className="svg-icon-muted svg-icon-1hx" />
                            </a>
                        </div>

                        <div className="d-flex align-items-center">
                            
                            <div className="d-flex align-items-center pagination">
                                {/*  */}
                                
                                    <span className="fw-semibold text-muted me-2 mt-2">{page.length} de {totalCount}</span>
                                    
                                    <li className={`${disabledPrevious} page-item previous mt-2`}  >
                                        <a type="button" onClick={() => gotoPage(0)} className="page-link">
                                            <KTSVG path="/media/icons/duotune/arrows/arr021.svg" className="svg-icon-muted svg-icon-1hx" />
                                        </a>
                                        {' '}
                                    </li>
                                    <li className={`${disabledPrevious} page-item previous mt-2`}>
                                        <a type="button" onClick={() => previousPage()} className="page-link" >
                                            <i className="previous"></i>
                                        </a>
                                        {' '}
                                    </li>
                                    <li className={`${disabledNext} page-item next mt-2`}>
                                        <a type="button" onClick={() => nextPage()} className="page-link">
                                            <i className="next"></i>
                                        </a>
                                        {' '}
                                    </li>
                                    <li className={`${disabledNext} page-item next mt-2`}>
                                        <a type="button" onClick={() => gotoPage(pageCount - 1)} className="page-link">
                                            <KTSVG path="/media/icons/duotune/arrows/arr024.svg" className="svg-icon-muted svg-icon-1hx" />
                                            
                                        </a>
                                        {' '}
                                    </li>
                                
                                {/*  */}
                                
                            </div>
                        
                            <div>
                                <a
                                    href="#"
                                    className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-end"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    aria-label="Settings"
                                    data-bs-original-title="Settings"
                                    data-kt-initialized="1"
                                >
                                    <KTSVG path="/media/icons/duotune/general/gen052.svg" className="svg-icon-muted svg-icon-1hx" />
                                </a>
                                
                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-250px py-4" data-kt-menu="true">
                                    
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                            <i className="ki-duotone ki-element-11 fs-3 me-3"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></i> New Group
                                        </a>
                                    </div>
                                
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                            <i className="ki-duotone ki-badge fs-3 me-3"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></i> Contacts
                                        </a>
                                    </div>
                                    
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                            <i className="ki-duotone ki-people fs-3 me-3"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></i> Groups
                                            <span className="badge badge-light-primary ms-auto">new</span>
                                        </a>
                                    </div>
                                
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                            <i className="ki-duotone ki-element-2 fs-3 me-3"><span className="path1"></span><span className="path2"></span></i> Calls
                                        </a>
                                    </div>
                            
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                            <i className="ki-duotone ki-setting-2 fs-3 me-3"><span className="path1"></span><span className="path2"></span></i> Settings
                                        </a>
                                    </div>

                                    <div className="separator my-5"></div>

                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                            <i className="ki-duotone ki-magnifier fs-3 me-3"><span className="path1"></span><span className="path2"></span></i> Help
                                        </a>
                                    </div>
                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                            <i className="ki-duotone ki-shield-tick fs-3 me-3"><span className="path1"></span><span className="path2"></span></i> Privacy
                                            <span className="badge badge-light-danger ms-auto">5</span>
                                        </a>
                                    </div>
                                    
                                </div>
                                
                            </div>
                
                            <a
                                href="#"
                                className="btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none"
                                data-bs-toggle="tooltip"
                                data-bs-dismiss="click"
                                data-bs-placement="top"
                                id="kt_inbox_aside_toggle"
                                aria-label="Toggle inbox menu"
                                data-bs-original-title="Toggle inbox menu"
                                data-kt-initialized="1"
                            >
                                <i className="ki-duotone ki-burger-menu-2 fs-3 m-0">
                                    <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span>
                                    <span className="path9"></span><span className="path10"></span>
                                </i>
                            </a>
                            
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="flex-wrap gap-2 justify-content-between mb-8">                                        
                            <div className="table-responsive table-loading">
                                <table {...getTableProps()} className="table table-hover table-rounded table-striped border gy-7 gs-7">
                                    <thead className="">
                                        {
                                            headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()} className="fw-semibold table-striped fs-6 text-gray-800 border-bottom border-gray-200">
                                                    {
                                                        headerGroup.headers.map((column) => (
                                                            <th {...column.getHeaderProps()} className="min-w-100px sorting">{column.render("Header")}</th>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </thead>
                                    <tbody {...getTableBodyProps()} className="min-w-100px sorting">
                                    {loading ? (
                                        <tr>
                                            <td>
                                                <div className="table-loading-message">
                                                Carregando...
                                            </div>
                                            </td>
                                        </tr>
                                    ) : rows.length > 0 ? (
                                        page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} className="text-start text-muted gs-0">
                                            {row.cells.map((cell) => {
                                                const _isArray = Array.isArray(cell.value);
                                                return <td  {...cell.getCellProps()}>
                                                            
                                                            {_isArray ? (
                                                                
                                                                
                                                                    <div className="d-flex justify-content-start flex-column">
                                                                        <span className="text-gray-400 fw-semibold d-block fs-7">{cell.value[0]}</span>
                                                                        <span className="text-gray-800 fw-bold text-hover-primary mb-1 fs-8">{cell.value[1]}</span>
                                                                    </div>

                                                                
                                                                ):(
                                                                // `${cell.value[0]} - ${cell.value[1]}`
                                                                 cell.render("Cell")
                                                                )}
                                                            
                                                        </td>;
                                            })}
                                            </tr>
                                        );
                                        })
                                    ) : (
                                        <tr className=" form-row text-start text-muted gs-0">
                                            <td colSpan={columns.length} className="text-center">Nenhum dado disponível</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};


export default TablesTasks;

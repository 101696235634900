import { Link, useLocation } from "react-router-dom"
import { KTSVG } from "../../../helpers";

export const TabHeaderBillingBank = () => {
    
    const location = useLocation();
    
    return (
            <div className="card mb-6 mb-xl-9">
                <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
                        <div className="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4">
                            <KTSVG className="svg-icon svg-icon-5x" path="/media/icons/duotune/finance/fin001.svg"/>
                        </div>

                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-1">
                                        <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bold me-3">Configurações Contas e Boletos</a>
                                    </div>

                                    <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">
                                        Criar beneficiario e pagador de forma simpes e ainda boleto unitário 
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                    </div>

                    <div className="separator"></div>

                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                        <li className="nav-item">
                            <Link
                            className={
                                    `nav-link text-active-primary pb-4 me-6 ` +
                                    (location.pathname === `/accountsBilling/bank`  && 'active')
                                
                                }
                                
                                to={`/accountsBilling/bank`}
                            >
                                Bancos
                            </Link>
                            {/* <a className="nav-link text-active-primary py-5 me-6 active" href="/metronic8/demo1/../demo1/apps/projects/project.html">  </a> */}
                        </li>
                        

                        <li className="nav-item">
                            <Link
                                className={
                                        `nav-link text-active-primary pb-4 me-6 ` +
                                        (location.pathname === `/accountsBilling/beneficiary`  && 'active')
                                    
                                    }
                                    
                                    to={`/accountsBilling/beneficiary`}
                                >
                                    Beneficiario
                            </Link>
                            {/* <a className="nav-link text-active-primary py-5 me-6" href="/metronic8/demo1/../demo1/apps/projects/targets.html"> Beneficiario </a> */}
                        </li>

                        <li className="nav-item">                                    
                            <Link
                                className={
                                        `nav-link text-active-primary pb-4 me-6 ` +
                                        (location.pathname === `/accountsBilling/boleto`  && 'active')
                                    
                                    }
                                    
                                    to={`/accountsBilling/boleto`}
                                >
                                    Boleto unitário
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
    )
}
import React, {FC, useEffect, useState} from 'react'
import { NumericFormat } from 'react-number-format'
import { Upload } from '../../../../app/interface/I_Files'
import { DrawePropsInvoice, FormInvoice } from '../../../../app/interface/I_Parcel'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { SchemaInvoice } from './SchemaInvoice'
import * as Yup from 'yup'
import { PDFObject } from 'react-pdfobject'
import axios from 'axios'
import { useAuth } from '../../../../app/modules/auth'
import Swal from 'sweetalert2'
import { isUri } from '../../../helpers/functions/url'
import { formatDateDefaultString } from '../../../helpers/functions/export/format'
import Select from "react-select";
import { Option } from '../../../../app/interface/I_General'
import { Importacao } from '../../../layout/components/configurations/importacao/Importacao'
import { deleteDocumentParcel, documentsParcel } from '../../../../app/request/parcel'
import { createEditInvoice, deleteInvoice } from '../../../../app/request/invoice'


const Drawerfile = ({
  closeDrawer,
    handlerSaveForm,
    formValues,
    handleChange
}: DrawePropsInvoice) => {

    const [loadPath, setLoadPath] = useState('');
    const [isInvoice, setIsInvoice] = useState(false);
    const [isViewClick, setIsViewClick] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const [invoiceActive, setInvoiceActive] = useState(true);
    const [documentsActive, setDocumentsActive] = useState(false);

    const {auth, currentUser} = useAuth();    
    const [formData, setFormData] = useState<FormInvoice>(formValues);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [competenciaInvoice, setCompetenciaInvoice] = useState('');

    const [listDocuments, setListDocuments] = useState({});

    interface OptionSelected {
        month: string;
        year: string;
    }
    
    useEffect(()=>{

        if(!!formValues.base64)
        {
            let fileBlob = convertbase64ToBlob(formValues.base64 || '');
    
            if(!!fileBlob)
            {
                setFormData((prevState :any) => ({
                    ...prevState,
                    ['file'] : convertbase64ToBlob(formValues.base64 || '')
                }) );
            }
        }

    },[formValues.base64]);

        
    useEffect(()=>{
        
        if(formValues.competencia && formValues.competencia !== '' && formValues.competencia !== null)
        {
            let competencia  = formValues.competencia.split('-');
            setMonth(competencia[0]);
            setYear(competencia[1]);

            setCompetenciaInvoice(new Date(0, parseInt(competencia[0]) - 1).toLocaleString("pt-BR", { month: "long" }) + `/${competencia[1]}`);
            
        }else{
            setMonth('');
            setYear('');
            setCompetenciaInvoice('Sem data');
        }
    },[]);

    // Obtém o ano atual
    const currentYear = new Date().getFullYear();

    const optionMOnth = Array.from({ length: 12 }, (_, i) => ({
        value: (i + 1) < 10 ? '0' + (i + 1).toString() : (i + 1).toString(), // Valor é o número do mês como uma string (1 a 12)
        label:  new Date(0, i).toLocaleString("pt-BR", { month: "long" }).charAt(0).toUpperCase() +
                new Date(0, i).toLocaleString("pt-BR", { month: "long" }).slice(1)
    }));

    let optionYear = [];

    // Adiciona os últimos 10 anos e os próximos 10 anos
    for (let i = currentYear - 10; i <= currentYear + 10; i++)
    {
        optionYear.push({ value: `${i}`, label: `${i}` });
    }

    const handleSelectChange = <K extends keyof OptionSelected>(selectedOption: Option | null,fieldName: K) => {
        const value = selectedOption ? selectedOption.value : '';
        if(fieldName === 'month')
        {
            setMonth(value);
        }else{
            setYear(value);
        }
    };

    const onLoadPath = () => {
        setLoadPath(formData?.base64 || '')
        setIsInvoice(formData?.base64 != '')
        setIsUpload(formData?.base64 == '')
    }

    useEffect(()=>{
        onLoadPath();
    },[formData.path, formData.base64, formData.created_at, loadPath]);
    
    const onLoadCompetencia = () => {
        
        setFormData((prevState) => ({
            ...prevState,
            ['competencia']: !!month ? `${month}-${year}` : null
        }) );

        if(!!month)
        {
            setCompetenciaInvoice(new Date(0, parseInt(month) - 1).toLocaleString("pt-BR", { month: "long" }) + `/${year}`);
        }else{
            
            setCompetenciaInvoice('Sem competência');
        }
            
    }


    useEffect(()=> {
        onLoadCompetencia()
    },[month, year, competenciaInvoice]);

    useEffect(()=>{
        setIsInvoice(formData.base64 !== '')
    },[]);

    const [formErrors, setFormErrors] = useState<{ [key: string | number]: string | number }>({        
        value: '',
        issuance_date: '',
        invoice_number: ''
    });

    const handleInputChange = (
        value: string|number,
        fieldName: keyof FormInvoice
      ) => {

        setFormData((prevState) => ({
            ...prevState,
            [fieldName]:value
        }) )
    };

    const handleFileChange = (event:any) => {
        if (event.target.files.length > 0) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                file: event.target.files[0] // Armazena o arquivo selecionado
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                file: null // Remove o arquivo selecionado
            }));
        }
    };

    const handleFileUpload = async () => {
        
        try{            
            await SchemaInvoice.validate(formData, { abortEarly: false });
            setFormErrors({});

            onLoadCompetencia();

            let timerInterval;
                
            Swal.fire({
                title: 'Aguarde...',
                html: 'Estamos realizando a ação solicitada..',
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                    timerInterval = setInterval(() => {
                        const b = Swal.getHtmlContainer();
                        if (b != null) {
                            const bElement = b.querySelector('b');
                            if (bElement != null) {
                            bElement.textContent = 'Open';
                            }
                        }
                    }, 100);
                },
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                }
            });

            if( (formData.id == null || formData.id === '') && (formData.file == null || formData.file === ''))
            {
                Swal.fire(
                    'Atenção',
                    'Selecione um arquivo',
                    'info'
                )
            }else{
            
                const formDataWithFile = new FormData();
                formDataWithFile.append("file", formData.file || '');

                formDataWithFile.append("id", formData.id.toLocaleString());
                formDataWithFile.append("id_parcel", formData.id_parcel.toLocaleString());
                formDataWithFile.append("id_contract", formData.id_contract.toLocaleString());
                formDataWithFile.append("id_client", formData.id_client.toLocaleString());
                formDataWithFile.append("path", formData.path.toLocaleString());
                formDataWithFile.append("value", formData.value);
                formDataWithFile.append("issuance_date", formatDateDefaultString(formData.issuance_date));
                formDataWithFile.append("invoice_number", formData.invoice_number.toString());

                if(formData.competencia && formData.competencia !== '' && formData.competencia !== null)
                {
                    formDataWithFile.append("competencia", formData.competencia.toString());
                }

                // const API_URL_UPLOAD = `${process.env.REACT_APP_API_URL}/module/insertInvoice`;
                const response = await createEditInvoice(formDataWithFile);

                if(response.data.success)
                {                        
                    Swal.fire(
                        response.data.title,
                        response.data.message,
                        response.data.icon
                    )
                    
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        base64: response.data.base64,
                        path: response.data.path,
                        created_at: response.data.created_at
                    }));

                }else if(response.data.status && response.data.status !== 403)
                {
                    Swal.fire(
                        'Atenção',
                        'Não foi possível concluir a solicitação, por favor entrar em contato com administrador',
                        'info'
                    )
                }
                
                onLoadPath()  
            }             
    

        }catch(errors){

            if (errors instanceof Yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                errors.inner.forEach((error) => {
                    validationErrors[error.path as string] = error.message
                });
                setFormErrors(validationErrors);
            }
        }
    };

    const handlerConfirmeDelete = () => {
        Swal.fire({
            icon:'info',
            title: 'Atenção',
            text:'Essa ação não poderá ser desfeita.',
            showCancelButton: true,
            confirmButtonText: 'Sim, deletar',
            cancelButtonText: `Fechar`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                handlerDelete();
            }
        })
    }
    
    const handlerEdit = () => {
        setIsInvoice(false);
        setIsViewClick(true);
        setIsUpload(true);
    }

    const handlerCancel = () => {
        setIsInvoice(true);
        setIsViewClick(false);
        setIsUpload(false);
    }

    const handlerDelete = async () => {
        const API_URL_UPLOAD = `${process.env.REACT_APP_API_URL}/module/deleteInvoice`;
        
        let timerInterval;

        Swal.fire({
            title: 'Aguarde...',
            html: 'Estamos realizando a ação solicitada..',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                const b = Swal.getHtmlContainer();
                if (b != null) {
                    const bElement = b.querySelector('b');
                    if (bElement != null) {
                    bElement.textContent = 'Open';
                    }
                }
                }, 100);
            },
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });
        

        if(formData.id)
        {
            const response = await deleteInvoice(formData.id, formData.id_client, formData.id_contract, formData.id_parcel, formData.path);
            if(response.data.success)
            {                    
                Swal.fire(
                    'Tudo certo!',
                    'Deletado com sucesso',
                    'success'
                )

                closeDrawer();
                
            }else if(response.data.status && response.data.status !== 403)
            {                                    
                Swal.fire(
                    'Atenção',
                    'Não foi possivel concluir essa ação',
                    'info'
                )
            }
        }
    }

    const handlerIsUpload = () =>{
        setIsUpload(true);
    }

    const showListDoc = () =>{
        setIsUpload(false);
    }

    const proposUpload: Upload = {
        idUppy:'upload',
        idImageEditor:'Dashboard',
        plugins:['Dashboard'],
        isDebug:true,
        isAutoProceed: false,
        isAllowMultipleUploads: false,
        isShowProgressDetails:true,
        isHideUploadButton:false,
        fileTypes: ['.png', 'application/pdf', '.jpg', '.jpeg'],
        endPoint: 'upload/documents/parcel',
        width: 'w-650px mt-9',
        height: '350px',        
        isMessageReturn:true,
        limit:10,
        form:{
            id_parcel: formData.id_parcel,
            id_contract: formData.id_contract,
            id_client: formData.id_client,
        }
    }

    const handleFileDocuments = (response:any) => {

        if (
            response.body.response.message &&
            response.body.response.message.icon === "success"
        ){             
            getDocumentsParcel();
        } else {
            setIsUpload(true);
            Swal.fire(
                response.body.response.message.title,
                response.body.response.message.text,
                response.body.response.message.icon
            )
        }
    };    

    const getDocumentsParcel = async () => {

        setDocumentsActive(true);
        setInvoiceActive(false);

        if(auth && auth?.api_token !== '')
        {
            const documents = await documentsParcel(formData.id_parcel, auth)

            if(documents.data && Array.isArray(documents.data) && documents.data.length > 0)
            {
                setListDocuments(documents.data);
                setIsUpload(false);
            }else{
                
                setIsUpload(true);
                setListDocuments({});
            }
        }        
    }

    const tabInvoice = () => {
        
        setDocumentsActive(false);
        setInvoiceActive(true);
    }


    const confirmeDeleteDocument = (id_document : any) => {
        Swal.fire({
            title: "Atenção",
            text: "Deseja deletar esse arquivo, a ação não poderá ser desfeita",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
          }).then((result) => {
            if (result.isConfirmed)
            {
                deleteDocument(id_document)
            }
        });
    }

    const deleteDocument = async (id_document : any) => {

        const response = await deleteDocumentParcel({
            id_parcel: formData.id_parcel,
            id_contract: formData.id_contract,
            id_client: formData.id_client,
            id_document: id_document,
        });

        if(response.data.success)
        {
            Swal.fire({
                title: "Deletado",
                text: "Tudo certo.",
                icon: "success"
            });
            getDocumentsParcel();
        }else if(response.data.status && response.data.status !== 403)
        {
            Swal.fire({
                title: "Opps",
                text: "Não foi possível deletar este documento.",
                icon: "warning"
            });
        }
    }

    const viewFile = (base64:string, type:string) => {
    
        if(type === 'jpeg' || type === 'png' )
        {
            
            const novaJanela = window.open('', '_blank')!;
            novaJanela.document.write(`
            <html>
                <body>
                <img src="${base64}" />
                </body>
            </html>
            `);
        }else{

            const binaryData = atob(base64);

            const arrayBuffer = new ArrayBuffer(binaryData.length);
            const uint8Array = new Uint8Array(arrayBuffer);

            for (let i = 0; i < binaryData.length; i++)
            {
                uint8Array[i] = binaryData.charCodeAt(i);
            }

            const blob = new Blob([uint8Array], { type: 'application/pdf' });
            const pdfURL = URL.createObjectURL(blob);

            window.open(pdfURL, '_blank');
        }
    };

    const tooltipeNameDoc = (doc:any, elem:any) => {

        const popover = document.createElement("div");
        popover.className = "popover bs-popover-auto fade show";
        popover.setAttribute("role", "tooltip");
        popover.innerHTML = `
            <div className="popover-arrow"></div>
            <h3 className="popover-header">${doc.name}</h3>
            ${ 
                doc.description  !== null 
                ? <div className="popover-body">{doc.description}</div> 
                : ''
            }
        `;

        // Define o ID do popover para remoção posterior
        popover.id = `popover_${doc.id}`;

        const button = elem.target as HTMLElement;
        // Obtém as coordenadas do botão em relação ao documento
        const buttonRect = button.getBoundingClientRect();
        
        const larguraPopover = 130;
        const alturaPopover = 120;
        
        // Define a posição do popover
        const top = buttonRect.bottom + window.scrollY;
        const left = (buttonRect.left);

        // Define o top e left do popover
        popover.style.position = 'absolute';
        popover.style.top = `${top}px`;
        popover.style.left = `${left}px`;
        popover.style.width = `${larguraPopover}px`;

        // Adiciona o popover ao corpo do documento
        document.body.appendChild(popover);
    }

    const removeTooltip = (id:any) => {
        
        var body = document.body;
        var tooltip = document.getElementById(`popover_${id}`);
        if (tooltip)
        {
            // Remove o elemento
            body.removeChild(tooltip);
        }
    }

    const convertbase64ToBlob = (base64String : string) => {
        
        const prefix = 'data:application/pdf;base64,';
        if (base64String.startsWith(prefix)) {
            // Removendo o prefixo da string Base64
            base64String = base64String.substring(prefix.length);
        }
        
        // Decodificando a string Base64
        var byteCharacters = atob(base64String);

        // Convertendo os caracteres decodificados em uma matriz de bytes
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Criando um ArrayBuffer e uma visualização de bytes para ele
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: 'application/pdf' }); // data:application/pdf;base64,

        return blob;

    }

  return (
        <div
          id='kt_activities'
          className={`bg-body ${!closeDrawer? "": "drawer drawer-end on drawer-on"}`}
          data-kt-drawer='true'
          data-kt-drawer-name='activities'
          data-kt-drawer-activate='true'
          data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
          data-kt-drawer-direction='end'
          // data-kt-drawer-toggle='#kt_activities_toggle'
        >        
          <div className='card shadow-none rounded-0'>
            
            
            <div className='card-body position-relative'>              
                
                <div className="tab-content">

                    <div className='d-flex align-items-center gap-2 gap-lg-3'>
                        <ul className="nav nav-tabs w-100 nav-line-tabs mb-5 fs-6">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab"  onClick={()=> tabInvoice()} href="#invoice_tab"><h3 className={`card-title text-dark  ${invoiceActive ? 'fw-bolder' : 'fs-5' }`}>Nota Fiscal</h3></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab"  onClick={()=> getDocumentsParcel()} href="#documents_tab"><h3 className={`card-title text-dark  ${documentsActive ? 'fw-bolder' : 'fs-5' }`}>Outros Documentos</h3></a>
                                {/* <a className="nav-link" data-bs-toggle="tab" href="#documents_tab">Outros documentos</a> */}
                            </li>
                        </ul> 
                        <button
                            type='button'
                            className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                            id='kt_activities_close'
                            onClick={closeDrawer}
                        >
                            
                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </button>
                    </div>
                    

                    {/* nota fiscal */}
                    <div className="tab-pane fade show active" id="invoice_tab" role="tabpanel">
                        <div className='flex-grow-1'>
                            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                <div></div>
                                <div className='d-flex'>
                                    {/* editar */}
                                    {isInvoice === true &&
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-light btn-active-light-primary me-1'
                                            onClick={() => handlerEdit()}
                                        >
                                            
                                            <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-1' />Editar
                                        </button>

                                    }

                                    {/* remover */}
                                    {/* {isInvoice === true && */}
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-light btn-active-light-primary me-1'
                                            onClick={() => handlerConfirmeDelete()}
                                        >
                                        
                                            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1' />Remover
                                        </button>
                                    
                                    {/* } */}

                                    {/* cancelar */}
                                    {isViewClick === true &&
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-light btn-active-light-primary me-15'
                                            onClick={() => handlerCancel()}
                                        >
                                            
                                            <KTSVG path='/media/icons/duotune/files/fil025.svg' className='svg-icon-1' />Visualizar
                                        </button>
                                    }

                                    
                                </div>
                            </div>
                        </div>
                    
                        {isInvoice === true
                            ?(<>
                                <div className="row g-5 mb-12">
                                    <div className="pe-3 mb-5">
                                        <div className="fs-5 fw-bold mb-2"></div>
                                        <div className="d-flex align-items-center mt-1 fs-6">
                                            <div className="text-muted me-2 fs-7">Criado em {formData.created_at} por {currentUser?.fullname}</div>
                                            <div className="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Joannes Waquim">
                                                <div className="d-flex justify-content-center col-md-12 text-light-secondary">
                                                {
                                                        isUri(currentUser?.photo) ? (
                                                            <img src={currentUser?.photo} alt={currentUser?.fullname} />
                                                        ) : (
                                                            
                                                            <div className="d-flex justify-content-center col-md-12 text-light-secondary">
                                                                <span className="align-self-center text-center fs-5">{currentUser?.name_short}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="fw-bold fs-6 text-gray-800">Número da nota fiscal</div>

                                        <div className="fw-semibold fs-7 text-gray-600">{ '#' + formData.invoice_number || '...'}</div>
                                    </div>

                                    <div className="col-sm-6 col-md-3">
                                        <div className="fw-bold fs-6 text-gray-800">Data da emissão</div>

                                        <div className="fw-semibold fs-7 text-gray-600">
                                            {formData.issuance_date.split('-').reverse().join('/') || '...'}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-3">
                                        <div className="fw-bold fs-6 text-gray-800">Competência</div>

                                        <div className="fw-semibold fs-7 text-gray-600">
                                        { competenciaInvoice}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-3">
                                        <div className="fw-bold fs-6 text-gray-800">Valor da Nota</div>

                                        <div className="fw-semibold fs-7 text-gray-600">
                                        { 'R$ ' + formData.value || 'R$ 0,00'}
                                        </div>
                                    </div>
                                </div>

                                <div className='timeline-content mb-10 mt-n1'>
                                    
                                    <div className='overflow-auto pb-5'>
                                        <div className='d-flex align-items-center min-w-700px p-7'>
                                            <div className='overlay me-10'>
                                                <PDFObject height={'500px'} width={'700px'} url={loadPath} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)
                            :(
                                <form id='form_invoice' >
                                    <div className="pe-12 me-n12 mt-7 mb-10">
                                        <div className="row gy-5 mb-2">
                                                <input type="hidden" value={formValues.id} name="id" />

                                                <div className='col-lg-3 form-floating fw-bold fv-plugins-icon-container'>
                                                    <input 
                                                        placeholder='Número da Nota Fiscal' 
                                                        className={`form-control`} 
                                                        name="invoice_number"
                                                        value={formData.invoice_number || ''}
                                                        onChange={(e) => {
                                                                handleInputChange(e.target.value, "invoice_number")
                                                            }
                                                        }
                                                    />
                                                    
                                                    <label htmlFor="invoice_number">Número da nota fiscal</label>
                                                    {formErrors.invoice_number && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.invoice_number}</div>
                                                    )}
                                                </div>

                                                <div className='col-lg-3 form-floating fw-bold fv-plugins-icon-container'>
                                                    <input 
                                                        type="date" 
                                                        placeholder='Data de emissão'
                                                        className={`form-control`} 
                                                        name="issuance_date"
                                                        value={!!formData.issuance_date ? formatDateDefaultString(formData.issuance_date) : formData.issuance_date || ''}
                                                        onChange={(e) => {
                                                                handleInputChange(e.target.value, "issuance_date")
                                                            }
                                                        }
                                                    />
                                                    
                                                    <label htmlFor="issuance_date">Data de emissão</label>
                                                    {formErrors.issuance_date && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.issuance_date}</div>
                                                    )}
                                                </div>

                                                <div className='col-lg-3 d-flex fw-bold fv-plugins-icon-container'>
                                                        
                                                    <div className='row'>
                                                        
                                                        <label className='col-12 text-center mt-n3' htmlFor="month">Competência</label>
                                                        <div className='col-5 form-floating '>
                                                            <Select
                                                                styles={{ container: (base) => ({ ...base, zIndex: 99 }) }}
                                                                className="w-125px"
                                                                placeholder="Mês"
                                                                name='month'
                                                                id='month'
                                                                // value={month||''}
                                                                value={month !== '' && month !== null   ? { value: month, label: optionMOnth.find(option => option.value === month)?.label } : null}
                                                                onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "month")}
                                                                options={optionMOnth}
                                                            />
                                                            <label htmlFor="month">Mês</label>
                                                        </div>
                                                        <span className="col-1 mt-3 input-text">-</span>
                                                        <div className='col-5 form-floating '>
                                                            <Select
                                                                styles={{ container: (base) => ({ ...base, zIndex: 99 }) }}
                                                                className="w-125px"
                                                                placeholder="Ano"
                                                                name='year'
                                                                id='year'
                                                                value={year !== '' && year !== null ? { value: year, label: optionYear.find(option => option.value === year)?.label } : null}
                                                                onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "year")}
                                                                options={optionYear}
                                                            />
                                                            <label htmlFor="year">Ano</label>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </div>

                                                <div className='col-lg-3 form-floating fw-bold fv-plugins-icon-container'>
                                                    <NumericFormat
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        prefix="R$ "
                                                        thousandsGroupStyle="thousand"
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        allowLeadingZeros={true}
                                                        className={`form-control`} 
                                                        name="value"
                                                        value={formData.value || '0,00'}
                                                        onValueChange={(values) => {
                                                            handleInputChange(values.floatValue || 0, "value");
                                                            }}
                                                    />
                                                    
                                                    <label htmlFor="value">Valor da Nota Fiscal</label>
                                                    {formErrors.value && (
                                                        <div className="fv-plugins-message-container invalid-feedback">{formErrors.value}</div>
                                                    )}
                                                </div>                                
                                        </div>
                                    </div>

                                    <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-15">
                                        <KTSVG className='svg-icon svg-icon-2x svg-icon-info' path='/media/icons/duotune/general/gen007.svg'/>

                                        <div className="d-flex flex-column pe-0 pe-sm-10">
                                            <h4 className="fw-semibold">Só um lembrate!!</h4>

                                            <span>Todos os campos são obrigatórios, e aceita apenas aquivo pdfs.</span>
                                        </div>

                                    </div>


                                    <div className='col-lg-12 form-floating fw-bold fv-plugins-icon-container'>
                                        <input 
                                            type="file" 
                                            placeholder='Incluir Nota Fiscal'
                                            className={`form-control`} 
                                            name="file"
                                            accept="application/pdf"
                                            onChange={handleFileChange}
                                            
                                        />
                                        
                                        <label htmlFor="file">Incluir Nota Fiscal</label>
                                        {formData.id == null || formData.id === '' && (
                                            <div className="fv-plugins-message-container invalid-feedback">A primeira nota é obriagatório.</div>
                                        )}
                                        {/* {formErrors.file && (
                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.file}</div>
                                        )} */}
                                    </div>
                                    
                                </form>
                            )
                    
                        } 

                        {/* botao enviar nota fiscal */}
                        {isUpload === true
                            ?(
                                <div className='card-footer py-5 text-center' id=''>
                                    <button 
                                        className='btn btn-sm btn-ligth text-primary'
                                        onClick={()=>handleFileUpload()}
                                        >
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr064.svg'
                                            className='svg-icon-3 svg-icon-primary'
                                        />
                                        Enviar
                                    
                                    </button>
                                </div>
                            ):
                            (<></>)
                        }
                    {/*  */}
                    </div>

                    {/* outros documentos */}
                    <div className="tab-pane fade" id="documents_tab" role="tabpanel">

                        
                        {
                            isUpload === true ?
                                <>
                                    <div className="card-toolbar mb-5">
                                        <div className="d-flex justify-content-end" data-kt-filemanager-table-toolbar="base">
                                            <button type="button" className="btn btn-flex btn-primary" onClick={() => showListDoc()}>
                                                <KTSVG path='/media/icons/duotune/files/fil002.svg'/> Listar
                                            </button>
                                        </div>
                                    </div>
                                    <Importacao 
                                        propsFiles={proposUpload}                                
                                        onFile={handleFileDocuments}
                                    />
                                </>

                            :
                            listDocuments && Array.isArray(listDocuments) && listDocuments.length > 0 &&
                            (<>
                                <div className="card-toolbar mb-5">
                                    <div className="d-flex justify-content-end" data-kt-filemanager-table-toolbar="base">
                                        <button type="button" className="btn btn-flex btn-primary" onClick={() => handlerIsUpload()}>
                                            <KTSVG path='/media/icons/duotune/files/fil018.svg'/> Upload
                                        </button>
                                    </div>
                                </div>
                                <div className="row g-6 g-xl-9 mb-6 mb-xl-9 min-w-550px mw-650px">
                                        {/* <div className="overflow-auto pb-5">
                                            <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7"> */}

                                    {
                                        listDocuments.map((document:any, index:number) => {

                                            const style = {
                                                    backgroundImage: `url(${document.base64})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: 'cover',
                                            };
                                            return (
                                                

                                                <div className='col-md-3 col-lg-3 col-xl-3 overlay' key={`document-${document.id}`}>
                                                    <div className="image-input image-input-outline" >
                                                        {
                                                            document.icon && document.icon !== 'pdf' 
                                                            ?                                                        
                                                            <div className="image-input-wrapper w-125px h-125px" style={style} ></div>
                                                            :
                                                            <div className="image-input-wrapper w-125px h-125px" style={{backgroundImage: `url(/media/svg/files/pdf.svg)`, backgroundSize: 'cover'}}></div>

                                                        }

                                                        <div className="overlay-layer bg-dark bg-opacity-10 rounded"
                                                            onMouseEnter={(e) => tooltipeNameDoc(document, e)}
                                                            onMouseLeave={() => removeTooltip(document.id)}
                                                        >
                                                            
                                                            <a onClick={() => viewFile(document.base64, document.icon)} className="btn btn-sm btn-primary btn-shadow">Abrir</a>                                         
                                                        </div>
                                                        
                                                        <span
                                                            onClick={()=>confirmeDeleteDocument(document.id)}
                                                            className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                                                            data-kt-image-input-action="remove"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-dismiss="click"
                                                            aria-label="Remove avatar"
                                                            data-bs-original-title="Remove avatar"
                                                            data-kt-initialized="1"
                                                        >
                                                            <KTSVG path='/media/icons/duotune/general/gen034.svg'/>  
                                                        </span>
                                                        
                                                    </div>
                                                </div>
                                            )

                                        })
                                    }
                                        {/* </div>
                                    </div> */}
                                </div>
                            </>)
                        }

                        {
                            
                            // isUpload &&
                            //     <Importacao 
                            //         propsFiles={proposUpload}                                
                            //         onFile={handleFileDocuments}
                            //     />
                        }

                    </div>
                </div>
                   
            </div>
                        
          </div>
        </div>
      )

}

export {Drawerfile}

import SearchGlobalsDasboard, {SearchGlobalsProps} from "../_metronic/partials/dashboard/SearchGlobalsDasboard";
import { useLocation } from 'react-router-dom';
import FormFilterLisContract from "../_metronic/partials/contract/filter/FormFilterLisContract";
import FormFilterProduct from "../_metronic/partials/product/filter/FormFilterProduct";
import FormFilterUnit from "../_metronic/partials/unit/filter/FormFilterUnit";
import FormFilterPayment from "../_metronic/partials/payments/filter/FormFilterPayment";
import FormFilterLisParcel from "../_metronic/partials/contract/filter/FormFilterLisParcel";
import { FormFilterEmpty } from "../_metronic/layout/components/filter/FormFilterEmpty";

type _url_ = {
  params?: string;
  urlPrincipal?: string | undefined;
  urlPartials?: string;
  urlAbsolute?: string;
}

var URI = process.env.REACT_APP_API_URL;
const URL: _url_ = ({
  urlPrincipal: window.location.pathname,

});

export const SwitchSearchGlobals = () => {
    
    const location = useLocation();

    const isDashboardRoute = location.pathname.includes('dashboard/access');
    const isContractRoute = location.pathname === '/contract/access' ? true : false // location.pathname.includes('contract');
    const isContractDetailsRoute = location.pathname.includes('overview')
    const isParceltRoute = location.pathname.includes('installment');
    const isProductRoute = location.pathname.includes('product/access');
    const isUnitRoute = location.pathname.includes('unit/access');
    const isPaymentRoute = location.pathname.includes('payment/access');
    const isConfigurationsRoute = location.pathname.includes('configuration/access');
    const isClients = location.pathname.includes('/client/access');
    const isCorretores = location.pathname.includes('/corretor/access');
    const isParcelsCanceled = location.pathname.includes('parcel/access');

    return (
        <>
            {/* {isDashboardRoute && <SearchGlobalsDasboard/>} arrumar depois o filtro para voltar */}
            {isDashboardRoute && <FormFilterEmpty/>}
            {isContractRoute && <FormFilterLisContract />}
            {isContractDetailsRoute && <FormFilterEmpty />}
            {isParceltRoute && <FormFilterLisParcel />}
            {isProductRoute && <FormFilterProduct />}
            {isUnitRoute && <FormFilterUnit />}
            {isPaymentRoute && <FormFilterPayment />}
            {isConfigurationsRoute && <FormFilterEmpty />}

            {isClients && <FormFilterEmpty />}
            {isCorretores && <FormFilterEmpty />}
            {isParcelsCanceled && <FormFilterEmpty />}
        </>
    );
}

import {createRoot} from 'react-dom/client'

import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import { MetronicI18nProvider } from '../../../_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './../../../_metronic/assets/sass/style.scss'
import './../../../_metronic/assets/sass/plugins.scss'
import './../../../_metronic/assets/sass/style.react.scss'
import { Sidebar } from '../../../_metronic/layout/components/documentation/sidebar'
import { MenuDocumentation } from '../../../_metronic/layout/components/documentation/menu/MenuDocumentation'


export const DocumentationWrapper = () => {
    return (
            <>      <div className='d-flex flex-column flex-root app-root'>
                        <div className='app-page flex-column flex-column-fluid'>
                            <div className='app-wrapper flex-column flex-row-fluid'>
                                <MenuDocumentation titleSecond={''} titleBase={''} />
                                <Sidebar/>
                            </div>
                        </div>
                    </div>
            </>
    )
}
import { useEffect, useState } from "react"
import { NumericFormat } from "react-number-format";
import { KTSVG } from "../../../../../_metronic/helpers";
import { DrawePropsCalculo, ISimulation } from "../../../../interface/I_Parcel";
import { useAuth } from "../../../../modules/auth";
import { simulationDistrato } from "../../../../request/parcel";
import { Details } from "../Details";
import { Summary } from "../Summary";

export const DrawerDistrato = ({
    closeDrawer,
    formValues
}:DrawePropsCalculo) => {

    const {auth} = useAuth();

    const [formData, setFormData] = useState<ISimulation>({
        id_contract:formValues?.id_contract || 0,
        name_contract:formValues?.name_contract || '',
        type_simulation:'distrato',
    });

    const [calculoSimulation, setCalculoSimulation] = useState<any>({});

    const [loadingBoleto, setLoadingBoleto] = useState({
        submit:false,
    });
    const [loadingEmitirDistrato, setLoadingEmitirDistrato] = useState({
        submit:false,
    });


    useEffect(() => {
        loadSimulation();
    }, [])

    
    const loadSimulation = async () => {

        if(auth?.api_token)
        {

            const response = await simulationDistrato(formData, auth);
            if(response.data && Object.keys(response.data).length > 0)
            {
                setCalculoSimulation({
                    ...calculoSimulation,
                    ...response.data,
                    ['name_contract'] : formData.name_contract
                });

                // calculoSimulation?.data?.pendentes
                
                console.log('pendentes ', response.data)
            }

        }
    }

    const emitirDistrato = () => {

        setLoadingEmitirDistrato((prevState) => ({
            ...prevState,
            submit:true,         
        }));
    }

    console.log('pendentes 2 ', calculoSimulation?.extract?.pendentes)
    return (
        <div
          id='kt_activities'
          className={`bg-body w-1000px ${!closeDrawer? "": "drawer drawer-end on drawer-on"}`}
          data-kt-drawer='true'
          data-kt-drawer-name='activities'
          data-kt-drawer-activate='true'
          data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'1500px', 'lg': '1500px'}"
          data-kt-drawer-direction='end'
        >
            
        <div className='card shadow-none rounded-0'>
            <div className='card-header' id='kt_activities_header'>
              <h3 className='card-title fw-bolder text-dark'>Simulação de Distrato</h3>

              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                  id='kt_activities_close'
                  onClick={closeDrawer}
                >
                    
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </button>
              </div>
            </div>
            {/* <div className="card card-flush pt-3 mb-5 mb-xl-10"> */}
                <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                    <div className="card-body">
                    
                        <div >
                            <div className="d-flex flex-wrap gap-2 justify-content-between mb-8">
                                
                                <div className="">
                                    
                                    {/* Resumo */}                                    
                                    <div className="m-0">
                                        <div className="fw-semibold fs-5 mb-3 text-dark00">RESUMO</div>
                                        <div className="fw-semibold fs-5 text-gray-600 mb-2">Contrato: 
                                            <span className="fw-bold fs-4 text-gray-800"> {formData.name_contract || '...'}</span>
                                        </div>
                                    </div>

                                    {/* <Summary calculoSimulation={calculoSimulation}/> */}

                                    <div className="separator border-gray-200 mb-6"></div>

                                    <div className="d-flex flex-column w-100">
                                        <div className="fw-semibold fs-5 mb-3 text-dark00">EXTRATO</div>

                                        <div className="py-1">
                                            <div className="py-3 d-flex flex-stack flex-wrap">                 
                                                <div className="me-3">
                                                    <div className="d-flex align-items-center fw-bold">
                                                        Valor atualizado do Contrato : {calculoSimulation?.extract?.valorContratoAtualizado || '...'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="separator separator-dashed"></div>

                                        <div className="py-1">
                                            <div className="py-3 d-flex flex-stack flex-wrap">                 
                                                <div className="me-3">
                                                    <div className="d-flex align-items-center fw-bold">
                                                        Valor multa rescisória do Contrato : {calculoSimulation?.extract?.multaRecisoria || '...'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="separator separator-dashed"></div>

                                        <div className="py-1">
                                            <div className="py-3 d-flex flex-stack flex-wrap">
                                                <div className="d-flex align-items-center collapsible toggle collapsed" data-bs-toggle="collapse" data-bs-target="#number_parcel_remaining_update" aria-expanded="false">
                                                    <div className="btn btn-sm btn-icon btn-active-color-primary ms-n3 me-2">
                                                        <KTSVG className="toggle-off fs-2" path="/media/icons/duotune/arrows/arr075.svg"/>
                                                        <KTSVG className="toggle-on fs-2" path="/media/icons/duotune/arrows/arr090.svg"/>
                                                    </div>                  
                                                    <div className="me-3">
                                                        <div className="d-flex align-items-center fw-bold">
                                                            Valores pendentes: {calculoSimulation?.extract?.valorPendencia || 'R$ 0,00'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="number_parcel_remaining_update" className="fs-6 ps-10 collapse" >
                                        
                                                <div className="d-flex flex-wrap py-5">                                                    
                                                    { 
                                                        calculoSimulation?.extract?.pendentes ? (
                                                            <div className="scroll h-400px px-5"> 
                                                                
                                                                <Details
                                                                    pendentes={calculoSimulation?.extract?.pendentes}
                                                                    type_simulation={'distrato'}
                                                                />
                                                            
                                                            </div>
                                                        
                                                        ):(<></>)
                                                        
                                                    }  
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="separator separator-dashed"></div>

                                        <div className="py-1">
                                            <div className="py-3 d-flex flex-stack flex-wrap">                
                                                <div className="me-3">
                                                    <div className="d-flex align-items-center fw-bold">
                                                        Valor total de multa rescisória: {calculoSimulation?.extract?.multaRecisoriaTotal || '...'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="separator separator-dashed"></div>

                                        <div className="py-1">
                                            <div className="py-3 d-flex flex-stack flex-wrap">                 
                                                <div className="me-3">
                                                    <div className="d-flex align-items-center fw-bold">
                                                        Valores pagos referentes ao contrato: {calculoSimulation?.extract?.valorPagoAtualizado || '...'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="separator separator-dashed"></div>

                                        <div className="py-1">
                                            <div className="py-3 d-flex flex-stack flex-wrap">                 
                                                <div className="me-3">
                                                    <div className="d-flex align-items-center fw-bold">
                                                        Valor devido pela 1ª Distratante: {calculoSimulation?.extract?.valorDevido || '...'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="separator separator-dashed"></div>

                                        <div className="py-1">
                                            <div className="py-3 d-flex flex-stack flex-wrap">                 
                                                <div className="me-3">
                                                    <div className="d-flex align-items-center fw-bold">
                                                        Valor à devolver: {calculoSimulation?.extract?.devolucao || '...'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
            {/* botoes voltar e avançar */}
            <div className='card-footer py-5 text-center' id=''>
                <div className="d-flex flex-stack pt-10"> 
                    
                    
                    <div>
                        {/* <button type="button" onClick={() => emitirDistrato()} data-kt-indicator={`${loadingEmitirDistrato.submit === true ? 'on' : 'off'}`}  className="btn btn-lg btn-primary me-3" data-kt-stepper-action="submit">
                            <span className="indicator-label">
                                Emitir Distrato
                                <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/arrows/arr064.svg"/>
                            </span>
                            <span className="indicator-progress">
                                Aguarde... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button> */}
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

    )
}
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../../../_metronic/helpers";
import { useAuth } from "../../../../modules/auth";
import { ativarInativarBank, getBanks } from "../../../../request/configurations/_request_banks";
import { Register } from "./Register";

export const AccountsBilling = () => {

    const {auth} = useAuth();

    const [banks, setBanks] = useState<any>([]);
    const [itemDataBanks, setItemDataBanks] = useState<any>([]);

    const [tabList, setTabList] = useState(true);
    const [tabRegister, setRegister] = useState(false);

    const clickTabList = () => {
        setItemDataBanks([])
        setTabList(true);
        setRegister(false);
    }

    const clickTabRegister = () => {

        setTabList(false);
        setRegister(true);
    }
    
    useEffect(() => {
        loadBanks();
    }, [])

    const loadBanks = async () => {

        if( auth && auth.api_token)
        {
            try {
                const response = await getBanks(auth);
                if (response.data && response.data && Array.isArray(response.data)) {
                    // Verifiqca se a resposta contém dados válidos e se é uma matriz
                    setBanks(response.data); // Defina banks com os dados recebidos

                }

            } catch (error) {
                console.error('Erro ao carregar bancos:', error);
            }

        }
    }

    const handlerEditAccount = (data:any) => {

        setTabList(false);
        setRegister(true);
        setItemDataBanks(data)
    }

    const handleCheckboxChange = (index: number, id:any) => {
        
        const updatedBanks = [...banks];
        
        updatedBanks[index].active = updatedBanks[index].active  === '1' ? '2' : '1';
        
        setBanks(updatedBanks);

        handlerAtivarInativar(id, updatedBanks[index].active)
    };
    
    const handlerAtivarInativar = async (id:any, active:any) => {

        if( auth && auth.api_token)
        {
            let dataActive = {
                id:id,
                active:active
            }
            const responseActive = await ativarInativarBank(dataActive, auth);
        }
    }
    
    return (
        <>
        <div className="card-header mt-6">
            <div className="d-flex flex-wrap flex-stack pb-7">
                <div className="d-flex flex-wrap align-items-center my-1">
                    <h3 className="fw-bold me-5 my-1"></h3>
                </div>

                <div className="d-flex flex-wrap my-1">
                    <ul className="nav nav-pills me-6 mb-2 mb-sm-0" role="tablist">
                        <li className="nav-item m-0" role="presentation">
                            <a 
                                className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3 ${tabList ? 'active' : ''}`} 
                                data-bs-toggle="tab" 
                                href="#list_accountBilling" 
                                aria-selected="true" 
                                role="tab"
                                onClick={() => clickTabList()}
                            >
                                <KTSVG path="/media/icons/duotune/layouts/lay010.svg"/>
                            </a>
                        </li>

                        <li className="nav-item m-0" role="presentation">
                            <a 
                                className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary ${tabRegister ? 'active' : ''}`} 
                                data-bs-toggle="tab" 
                                href="#register_accountBilling" 
                                aria-selected="false" 
                                tabIndex={1} 
                                role="tab"
                                onClick={() => clickTabRegister()}
                            >
                                <KTSVG path="/media/icons/duotune/general/gen025.svg"/>
                            </a>
                        </li>
                    </ul>

                    <div className="d-flex my-0"></div>
                </div>
            </div>

        </div>
        <div className="row gx-6 gx-xl-9">
            <div className="col-lg-12">
                <div className="card card-flush h-lg-100 p-7">                    
                    {
                        tabList && (

                            <div id="list_accountBilling" className="tab-pane fade show active" role="tabpanel">

                                <div className="card-title flex-column">
                                    <h3 className="fw-bold mb-1">Contas cadastradas</h3>
                                </div>
        
                                <div className="card-body p-9 pt-5">
                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
                                        <div className="d-flex flex-stack flex-grow-1">
                                            <div className="fw-semibold">
                                                <div className="fs-6 text-gray-700">Contas ativas relacionados aos bancos e os beneficiário</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-2">
        
                                        {
                                            banks && banks.map((bank:any, index:number) => (
                                                    <div key={index} className="d-flex flex-stack mt-7">
                                                        <div className="d-flex">
                                                            <img src={bank.logo_bank || ''} className="w-30px me-6" alt="" />
        
                                                            <div className="d-flex flex-column">
                                                                <Link className="fs-5 text-dark text-hover-primary fw-bold" onClick={() => handlerEditAccount(bank)} to={`#`}>BANCO - {bank.name_bank} <KTSVG path="/media/icons/duotune/general/gen055.svg"/> </Link>
                                                                {/* <a href="#" className="fs-5 text-dark text-hover-primary fw-bold"></a> */}
                                                                <div className="fs-6 fw-semibold text-gray-400">{bank.beneficiary}</div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <div className="form-check form-check-solid form-check-custom form-switch">
                                                                <input 
                                                                    className="form-check-input w-45px h-30px" 
                                                                    type="checkbox" 
                                                                    id={bank.id}
                                                                    checked={bank.active === '1'} // Use o valor do estado banks
                                                                    onChange={() => handleCheckboxChange(index, bank.id)} 
                                                                />
                                                                <label className="form-check-label" htmlFor="googleswitch"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                        }
                                        
        
                                        <div className="separator separator-dashed my-5"></div>
                                    </div>
        
                                </div>
        
                            </div>
                        )
                    }

                    {
                        tabRegister && (

                            <div id="register_accountBilling" className="tab-pane fade show active" role="tabpanel">
                                <div className="notice d-flex mb-4 bg-light-primary rounded border-primary border border-dashed p-6">
                                    <div className="d-flex flex-stack flex-grow-1">
                                        <div className="fw-semibold">
                                            <div className="fs-6 text-gray-700">Aqui você pode Cadastrar as conta bancarias para geração de boletos</div>
                                        </div>
                                    </div>
                                </div>
                                <Register dataBank={itemDataBanks} />
                            </div>
                        )
                    }


                    
                </div>
            </div>
        </div>
        </>
    )
}
import { useEffect, useState } from "react";
import { authGroups } from "../../../request/configurations/_request";

type AllRolesType = {
    [key: string]: {
        title: string;
        description: string;
    };
};

type AllModulesType = Array<{
    [key: string]: string[];
}>; 


const PermissionWrapper = () => {

    const [allRoles, setAllRoles] = useState<any>([]);
    const [allModules, setAllModules] = useState<any>([]);

    const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');

    const listModules = [
        {module:'All', describe: 'Todos os controles administrativos'},
        {module:'Dashboard', describe:'Relatórios de pagamentos em massa'},
        {module:'Contratos', describe:'Lista, vizualizar detalhes e criação de condição de pagamentos e parcelas...'},
        {module:'Clientes', describe:'Listar, editar informações do cliente'},
        {module:'Corretores', describe:'Listar, editar informações do corretor'},
        {module:'Produtos', describe:'Listar, visualizar e editar informações do produto'},
        {module:'Unidades', describe:'Listar, editar informação da unidade'},
        {module:'Pagamentos', describe:'Lista, visualizar e editar pagamentos'},
        {module:'Deletados', describe:'Lista'},
        {module:'Configuração', describe:'Lista'},
    ];

    const listRoles = [
        {
            roles:'Root', 
            describe: 'Acesso irrestrito', 
            module: [
                {
                    Dashboard : [
                        {
                            permission: [
                                'vizualizar'
                            ]
                        }
                    ]
                },
                {
                    'Contratos' : [
                        {
                            permission: [
                                'listar', 'vizualizar', 'editar', 'criar', 'deletar', 'comments', 'attachment'
                            ]
                        }
                    ]
                }, 
                
                'Clientes', 'Corretores', 'Produtos', 'Unidades', 'Pagamentos', 'Deletados', 'Configuração'
            ],
            
        },
        {
            roles:'Desenvolvedor', 
            describe: 'Acesso irrestrito', 
            module: [
                'Dashboard', 'Contratos', 'Clientes', 'Corretores', 'Produtos', 'Unidades', 'Pagamentos', 'Deletados', 'Configuração'
            ],
            permission: [
                'listar', 'vizualizar', 'editar', 'criar', 'deletar', 'comments', 'attachment'
            ]
        },
        {
            roles:'Administrador', 
            describe: 'Todos os controles administrativos', 
            module: [
                'Dashboard', 'Contratos', 'Clientes', 'Corretores', 'Produtos', 'Unidades', 'Pagamentos', 'Deletados', 'Configuração'
            ],
            permission: [
                'listar', 'vizualizar', 'editar', 'criar', 'deletar', 'comments', 'attachment'
            ]
        },
        {
            roles:'Convidado', 
            describe: 'Acesso excessivamente restrito', 
            module: [
                'Dashboard', 'Contratos', 'Pagamentos', 'Deletados'
            ],
            permission: [
                'listar', 'vizualizar', 'comments', 'attachment'
            ]
        },
        {
            roles:'Editor', 
            describe: 'Acesso excessivamente restrito', 
            module: [
                'Contratos', 'Pagamentos'
            ],
            permission: [
                'comments', 'attachment'
            ]
        },
        
    ];

    

    useEffect(() => {
        const loadAuthGroups = async () => {
            const response = await authGroups();
            if(response.data && response.data.all_modules)
            {
                
                const roles: AllRolesType = response.data.all_roles;
                setAllRoles(roles);
            }

            if(response.data && response.data.all_modules)
            {
                const modules: AllModulesType = response.data.all_modules;
                setAllModules(modules);
            }
        }
        loadAuthGroups();
    },[]);

    const handlerViewRoles = () => {

    }

    const handlerEditRoles = () => {

    }

    return (
        <div className="row">
            {
                allModules && Array.isArray(allModules) && Object.keys(allModules).length > 0 
                    ? allModules.map((modulesObj, index) => {
                        const roleName: string = Object.keys(modulesObj)[0];
                        const modules: string[] = modulesObj[roleName];

                        // Obter as informações de allRoles correspondentes a roleName
                        const roleInfo = allRoles[roleName] as { title: string; description: string } | undefined;


                        if (roleInfo)
                        {
                            // Agrupar as permissões por seção
                            const groupedModules: Record<string, string[]> = modules.reduce((acc, module) => {
                            const section = module.split('.')[0]; // Obtém a primeira parte antes do ponto
                            if (!acc[section]) {
                                acc[section] = [];
                            }
                            acc[section].push(module);
                            return acc;
                            }, {} as Record<string, string[]>);

                            return (
                                <div className="col-md-3 mb-2" key={index}>
                                    <div className="card h-md-100">
                                        <div className="card-header pt-7">
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="card-label fw-bold text-gray-800">{roleInfo?.title}</span>
                                                <span className="text-gray-500 mt-1 fw-semibold fs-9">{roleInfo?.description}</span>
                                            </h3>
                                        </div>

                                        <div className="card-body pt-1">
                                            <div className="d-flex flex-column text-gray-600">
                                            {
                                                Object.entries(groupedModules).map(([section, permissions], sectionIndex) => (
                                                    <div key={sectionIndex}>
                                                    
                                                        <div className="fw-bold mt-2">
                                                            {section}
                                                        </div>
                                                        {permissions.map((permission, permissionIndex) => (
                                                            
                                                            <div key={permissionIndex} className="d-flex align-items-center py-2">
                                                                <span className="bullet bg-primary me-3"></span>
                                                                {permission}
                                                            </div>
                                                            
                                                        ))}
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>

                                        <div className="card-toolbar ms-3 px-2 mb-3 flex-wrap">
                                            <button 
                                                className="btn btn-sm btn-light btn-active-primary"
                                                onClick={() => handlerViewRoles()}
                                            >
                                                Ver função
                                            </button>

                                            <button                                     
                                                className="btn btn-sm  btn-light btn-active-light-primary"
                                                onClick={() => handlerEditRoles()}
                                            >
                                                Editar função
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })
                : <></>
            }
        </div>
    );
}

export default PermissionWrapper;
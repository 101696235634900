import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilterGLobals } from '../../interface/I_Filter'; 

interface IFilterState {
    [key: string]: any;
}


const initialState: IFilterState = {};

const productSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
        setFilterProduct: (
            state,
            action: PayloadAction<IFilterState>
        ) => {
            const filterPayload = action.payload;

            Object.keys(filterPayload).forEach((key) => {
                
                state[key] = filterPayload[key];
            }); 
        },
    },
});

export const { setFilterProduct } = productSlice.actions;
export default productSlice.reducer;

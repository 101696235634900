import React from "react";

interface DetailsProps {
    pendentes?: string;
    type_simulation: string;
    parcelas?: string;
    proRata?: string;
    valorQuitacao?: string;
}

export const Details: React.FC<DetailsProps> = ({ parcelas, pendentes, proRata, valorQuitacao, type_simulation }: any) => {
    let parcels: any[] = [];
    let pending: any[] = [];
    

    console.log('parcelas ', parcelas)
    console.log('pendentes ', pendentes)
    if(parcelas && Object.keys(parcelas).length > 0)
    {
        parcels = Object.entries(parcelas).map(([key, value]) => value);
    }else if(parcelas && Array.isArray(parcelas) && parcelas.length > 0){
        parcels = parcelas;
    }
    
    if(pendentes && Object.keys(pendentes).length > 0)
    {
        pending = Object.entries(pendentes).map(([key, value]) => value);
    }else if(pendentes && Array.isArray(pendentes) && pendentes.length > 0){
        pending = pendentes;
    }


    let acumuladoAntecipation   = ``;

    let count_pendente      = 100000;
    return (
        <div className="flex-grow-1">
            <div className="table-responsive border-bottom-0 mb-9">
                <table className="table mb-3">
                    <thead>
                        <tr className="border-bottom fs-6 fw-bold text-muted">
                            <th className="px w-auto pb-2"></th>
                            <th className="min-w-175px pb-2">Data vencimento</th>
                            <th className="min-w-70px text-end pb-2">Valor original</th>
                            <th className="min-w-80px text-end pb-2">Valor atualizado</th>
                            <th className="min-w-100px text-end pb-2">Valor acumulado</th>
                            <th className="min-w-100px text-end pb-2">Tipo de parcela</th>
                        </tr>
                    </thead>

                    <tbody>
                        
                        {
                            
                            parcels.length > 0 ? parcels.map((item, index) => {
                                let label: JSX.Element | null = null;
                                let subtotal: JSX.Element | null = null;
                                const uniqueKeyAmotization = `${index * 3}-${parcels.length * (index * 3) }-${index}`;

                                if((index + 1) === 1)
                                {
                                    label = (
                                        <td className="d-flex align-items-center text-dark fw-bolder">Parcelas amortizadas</td>
                                    );
                                }else if((index + 1) === parcels.length){
                                    
                                    label = (
                                        <td><div className="bullet w-8px h-3px rounded-2 me-3" style={{background:'1ca017', padding:'0px'}}></div></td>
                                    );

                                    subtotal = (
                                        <tr key={(index + 1) * parcels.length }>                                            
                                          <td colSpan={4} className="fw-bold text-gray-700 fs-5 text-end">Subtotal</td>
                                          <td colSpan={1} className="fw-bold text-gray-700 fs-5 text-end">{item.acumulado}</td>
                                        </tr>
                                      );
                                    
                                }else{
                                    label = (
                                        <td><div className="bullet w-8px h-3px rounded-2 me-3" style={{background:'1ca017', padding:'0px'}}></div></td>
                                    );
                                }

                                acumuladoAntecipation = item.acumulado;

                                return(
                                    <React.Fragment key={uniqueKeyAmotization}>
                                        <tr key={(index + 1) * parcels.length + uniqueKeyAmotization} className="fw-bold text-gray-700 fs-7 text-end">
                                            {label}
                                            <td className="">{item.due_date}</td>
                                            <td className="">{item.value}</td>
                                            <td className="text-dark fw-bolder">{item.updated_value}</td>
                                            <td className="text-dark fw-bolder">{item.acumulado}</td>
                                            <td className="fw-bolder">{item.type_parcel}</td>
                                        </tr>
                                        {subtotal}
                                    </React.Fragment>
                                )}
                            ) : <></>
                        }

                        {/* parcelas pendentes */}
                        {
                            
                            (type_simulation === 'quitacao' || type_simulation === 'distrato') && pending.length > 0 ? pending.map((value, pos) => {

                                const uniqueKeyPendding = `${(pos + 1) * 10}-${pending.length * ( (pos+ 1) * 3) }-${(pos+ 1)  * parcels.length}`;
                                let label: JSX.Element | null = null;
                                let subtotal: JSX.Element | null = null;
                                let pro_rata: JSX.Element | null = null;
                                let valor_quitacao: JSX.Element | null = null;

                                console.log('pos: ', (pos + 1), 'pending length: ',  pending.length)
                                console.log('uniqueKeyPendding: ', uniqueKeyPendding)
                                if(pending.length === 1)
                                {
                                    label = (
                                        <td className="d-flex align-items-center text-dark fw-bolder">Parcelas pendentes</td>
                                    );

                                    subtotal = (
                                        <tr key={(count_pendente + 1) * (pending.length + (pos + 1))  }>                                            
                                          <td colSpan={4} className="fw-bold text-gray-700 fs-5 text-end">Subtotal</td>
                                          <td colSpan={1} className="fw-bold text-gray-700 fs-5 text-end">{value.acumulado}</td>
                                        </tr>
                                    );

                                    pro_rata = (
                                        <tr key={(count_pendente + 1) * count_pendente }>                                            
                                          <td colSpan={4} className="fw-bold text-gray-700 fs-5 text-end">Pró-rata</td>
                                          <td colSpan={1} className="fw-bold text-gray-700 fs-5 text-end">{proRata || '...'}</td>
                                        </tr>
                                    );

                                    valor_quitacao = (
                                        <tr key={count_pendente *  pending.length }>                                            
                                          <td colSpan={4} className="fw-bold text-gray-700 fs-5 text-end">Total simulado</td>
                                          <td colSpan={1} className="fw-bold text-gray-700 fs-5 text-end">{valorQuitacao || '...'}</td>
                                        </tr>
                                    );

                                }else if( (pos + 1) === 1)
                                {
                                    label = (
                                        <td className="d-flex align-items-center text-dark fw-bolder">Parcelas pendentes</td>
                                    );

                                }else if( (pos + 1) === pending.length )
                                {
                                    label = (
                                        <td><div className="bullet w-8px h-3px rounded-2 me-3" style={{background:'1ca017', padding:'0px'}}></div></td>
                                    );
                                    
                                    subtotal = (
                                        <tr key={(count_pendente + 1) * (pending.length + (pos + 1))  }>                                            
                                          <td colSpan={4} className="fw-bold text-gray-700 fs-5 text-end">Subtotal</td>
                                          <td colSpan={1} className="fw-bold text-gray-700 fs-5 text-end">{value.acumulado}</td>
                                        </tr>
                                    );

                                    pro_rata = (
                                        <tr key={(count_pendente + 1) * count_pendente }>                                            
                                          <td colSpan={4} className="fw-bold text-gray-700 fs-5 text-end">Pró-rata</td>
                                          <td colSpan={1} className="fw-bold text-gray-700 fs-5 text-end">{proRata || '...'}</td>
                                        </tr>
                                    );

                                    valor_quitacao = (
                                        <tr key={count_pendente *  pending.length }>                                            
                                          <td colSpan={4} className="fw-bold text-gray-700 fs-5 text-end">Total simulado</td>
                                          <td colSpan={1} className="fw-bold text-gray-700 fs-5 text-end">{valorQuitacao || '...'}</td>
                                        </tr>
                                    );
                                    
                                }else{
                                    label = (
                                        <td><div className="bullet w-8px h-3px rounded-2 me-3" style={{background:'1ca017', padding:'0px'}}></div></td>
                                    );
                                }

                                acumuladoAntecipation = value.acumulado;
                                count_pendente++;
                                return(
                                    <React.Fragment key={uniqueKeyPendding}>
                                        <tr key={(count_pendente * 2) + uniqueKeyPendding} className="fw-bold text-gray-700 fs-7 text-end">
                                            {label}
                                            <td className="">{value.due_date}</td>
                                            <td className="">{value.value}</td>
                                            <td className="text-dark fw-bolder">{value.updated_value}</td>
                                            <td className="text-dark fw-bolder">{value.acumulado}</td>
                                            <td className="fw-bolder">{value.type_parcel}</td>
                                        </tr>
                                        {subtotal}
                                        {type_simulation === 'quitacao' && pro_rata}
                                        {type_simulation === 'quitacao' && valor_quitacao}
                                    </React.Fragment>
                                )}
                            ) : <></>
                        }
                    </tbody>
                </table>
            </div>

        </div>
    )
}
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { bool, boolean } from "yup"
import { KTSVG } from "../../../../_metronic/helpers"
import { LoadAnimation } from "../../../../_metronic/helpers/functions/swal/LoadAnimation"
import { Importacao } from "../../../../_metronic/layout/components/configurations/importacao/Importacao" 
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { Upload } from "../../../interface/I_Files"
import { useAuth } from "../../../modules/auth"
import { paidParcelInSpreadSheet } from "../../../request/configurations/_request"
import { CampareBaixaBoletos } from "../modal/CampareBaixaBoletos"
import { PreviewTable } from "./spreadSheet/PreviewTable"

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Configuração',
      path: '/configuration/access',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

export const PlanilhasGerais = () => {

    const {auth} = useAuth();

    const [isViewUpload, setIsViewUpload] = useState(true);
    
    // const [data, setData] = useState(teste);    
    const [table, setTable] = useState('');    
    const [data, setData] = useState([]);    
    const [columns, setColumns] = useState([]);    
    const [selectFields, setSelectFields] = useState([]);    
    const [nameXlsx, setNameXlsx] = useState([]);
    
    const newUpload = () => {
        setIsViewUpload(true)
    }
    
    const send = async (data:any) => {
        if(auth?.api_token)
        {            
            LoadAnimation();
            const response = await paidParcelInSpreadSheet(data, auth);
            Swal.fire(
                response.data.title,
                response.data.message,
                response.data.icon
            );

            setTimeout(() => {
                setIsViewUpload(true);                    
            }, 3000);
        }
    }

    const proposUpload: Upload = {
        idUppy:'importacao',
        idImageEditor:'ImageEditor',
        plugins:['ImageEditor'],
        isDebug:true,
        isAutoProceed: false,
        isAllowMultipleUploads: false,
        isShowProgressDetails:true,
        isHideUploadButton:false,
        fileTypes: ['.xlsx', '.xls'],
        endPoint: 'upload/payments',
        width: 'auto',
        height: '350px',        
        isMessageReturn:true
    }
    
    const handleFile = (response:any) => {

        if (
            response.body.table &&
            response.body.table.length > 0
        ){            
            setTable(response.body.table)
            setColumns(response.body.columns)
            setSelectFields(response.body.select)
            setNameXlsx(response.body.name)
            setIsViewUpload(false)

        } else {
            Swal.fire(
                'Atenção.',
                'Não foi possível concluir a solicitação.',
                'info'
            )
        }
    };

    useEffect(()=> {

    }, [isViewUpload])

    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Importação Planilha</PageTitle>
            <div className="col-xl-12 mb-xl-10">
                <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
                    <KTSVG path={""}/>
                    <div className="d-flex flex-column">
                        <h4 className="mb-1 text-primary">Área de Importação de Arquivo xlsx</h4>
                        <span>Importe seus arquivo retorno xlsx.</span>
                    </div>
                </div>
            
			    <div className="card">
				
                    <div className="card-body">
                        <div className="row align-items-center">
                            { isViewUpload ? (                            
                                <Importacao 
                                    propsFiles={proposUpload}                                
                                    onFile={handleFile}
                                />
                                ):(
                                    <PreviewTable
                                        data={data}
                                        table={table}
                                        columns={columns}
                                        selects={selectFields}
                                        name={nameXlsx}
                                        newUpload={newUpload}
                                        send={send}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
import axios from "axios";
import { AuthType, IResponse } from "../interface/I_General";

const API_URL = process.env.REACT_APP_API_URL;


// recupera dados do histroico
export async function getHistoric(id: number, auth: AuthType) 
{
    try {
        const response = await axios.get<IResponse>(
            `${API_URL}/module/historicList/${id}`,
            {
              headers: auth && auth.api_token ? { Authorization: `Bearer ${auth.api_token}` } : {}
            }
        );
        return response.data;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar os dados:", error);
        throw error;
    }

}


// recupera dados do histroico
export async function getHistoricByIdParcel(id: number| string, auth: AuthType) 
{
    try {
        const response = await axios.get<IResponse>(
            `${API_URL}/module/historicListByParcel/${id}`,
            {
              headers: auth && auth.api_token ? { Authorization: `Bearer ${auth.api_token}` } : {}
            }
        );
        return response.data;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar os dados:", error);
        throw error;
    }

}

// insere historico
export async function insertHistoricAPI(data: any, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/historicInsert`,
                            { data },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}

// deleta historico
export async function deleteHistoricAPI(ids: any, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/historicDelete`,
                            { ids },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}



// recupera dados do histroico
export async function getVerifyHistory(idParcel: number| string, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getVerifyHistory`,
                            { idParcel },
                            { headers }
                        );

        return response;
        
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar os dados:", error);
        throw error;
    }

}
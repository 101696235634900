export const isDateExpired =(dateString: string): boolean => {
    const currentDate = new Date();
    const [day, month, year] = dateString.split('/').map(Number);
    
    // Lembrando que os meses em JavaScript vão de 0 a 11, então subtraímos 1 do mês.
    const providedDate = new Date(year, month - 1, day);
  
    return providedDate < currentDate;
}

export function formatarDataPtBr(data: string): string {
    // Cria um objeto Date a partir da string da data
    const dataObj = new Date(data);

    // Extrai o dia, mês e ano da data
    const dia = String(dataObj.getDate()).padStart(2, '0');
    const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
    const ano = dataObj.getFullYear();

    // Formata a data no formato desejado (dd/mm/yyyy)
    return `${dia}/${mes}/${ano}`;
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IFilterState {
    [key: string]: any;
}


const initialState = {};;

const resetSlice = createSlice({
  name: 'reset',
  initialState,
  reducers: {
    resetFilter: (state) => {

      return initialState;
    },
  },
});

export const { resetFilter } = resetSlice.actions;
export default resetSlice.reducer;

import * as yup from 'yup';
import { TestCPF } from '../../../_metronic/helpers/functions/TestCpf';

export const SchemaClient = yup.object().shape({

    name: yup.string().nullable().required('O campo nome é obrigatório'),
    telefone: yup.string().matches(/^\(\d{2}\)\s\d{4,5}-\d{4}$/, 'Insira um telefone válido').nullable().required('O campo Telefone é obrigatório'),
    zip_code: yup.string().matches(/^\d{5}-\d{3}$/, 'Insira um CEP válido').nullable().required('O CEP é obrigatório'),
    cpf: yup.string().test(
        'test-invalid-cpf',
        'Cpf inválido',
        (cpf) =>  TestCPF(cpf)).required('O campo Cpf é obrigatório'),
    number: yup.string().nullable().required('O campo Número é obrigatório'),    
    address: yup.string().nullable().required('O campo Endereço é obrigatório'),
    neighborhood: yup.string().nullable().required('O campo Bairro é obrigatório'),
    city: yup.string().nullable().required('O campo Cidade é obrigatório'),
    state: yup.string().nullable().required('O Estado é obrigatório')
});
import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react'

import "@yaireo/tagify/dist/tagify.css" // Tagify CSS
import Tags from '@yaireo/tagify/dist/react.tagify'
import { useAuth } from '../../../app/modules/auth'
import { optionsTypePayment } from '../../../app/globals/OptionsGlobals'

const baseTagifySettings: TagifySettings = {
    blacklist: [],
    maxTags: 50,
    //backspace: "edit",
    placeholder: "FORMA DE COBRANÇA",
    enforceWhitelist: true, // força a selecionar apenas da lista
    dropdown: {
      enabled: 0, // Desativa o dropdown quando o limite de tags é atingido 1 é true
      maxItems: 200,
    },
    editTags: false // Desabilita a edição de tags
}

interface TagifySettings {
    blacklist: never[];
    maxTags: number;
    placeholder: string;
    enforceWhitelist: boolean;
    dropdown: {
      enabled: number;
      maxItems: number
    };
    editTags: boolean;
    // readOnly?: boolean; // Adicionando a propriedade opcional readOnly,
    // userInput?:boolean
}

interface ITagiFy  {
    value:string,
    name:string
}

export const TagifyListCustom = ({ onSelectTagify, selectedValues, className }: any) => {

    const tagifyRef1 = useRef<any>(null);

    const [tagifySettings, setTagifySettings] = useState(baseTagifySettings)
    const [readOnly, setReadOnly] = useState(false)

    const _whitelist = optionsTypePayment.map((row: any) => ({
        value: row.label === 'Todos' ? row.label : row.value,
        name: row.label,
        icon:''
    }));

    const [tagifyProps, setTagifyProps] = useState<{ whitelist: ITagiFy[]; }>({
        
        whitelist: _whitelist
        // loading: false
    });
    
    const handleRemoveTag = (e: any) => {        
        onSelectTagify('remove', e.detail.data.value);
    };  

    useEffect(() => {
        setTagifyProps(prevState => ({ ...prevState }))
        if (tagifyRef1.current) {
            tagifyRef1.current.on('remove', handleRemoveTag);
        }

    }, [tagifyRef1.current])

     // merged tagify settings (static & dynamic)
    const settings = {
        ...baseTagifySettings,
        ...tagifySettings
    }

    // aplica selecao do cliente na caixa do dropdown
    const onDropdownSelect = (e: any) => {
        
        if (e.detail.data.value === '' || e.detail.data.value === 'Todos' )
        {            
            // Se a opção "Todos" vazia for selecionada, aplique todos os valores da lista, exceto "Todos"            
            const valuesToApply = _whitelist.filter(item => item.value !== '' && item.value !== 'Todos');
            
            const updatedValues = valuesToApply
                    .filter((item: any) => item.value !== '' && item.value !== 'Todos')
                    .map((item: any) => item.value);

            // devolve para o componente do produto
            onSelectTagify('addAll', updatedValues);
            
            // seleciona todos na lista
            e.detail.tagify.dropdown.selectAll();

            e.detail.tagify.removeTags('Todos');
            // removeAllTags
        } else {
            onSelectTagify('add', e.detail.data.value);
        }
        
    };
    

  return (
    <>
      <Tags
        tagifyRef={tagifyRef1}
        settings={settings}
        className={className || 'w-100'}
        // autoFocus={true}
        {...tagifyProps}
        // onInput={(e: any) => console.log("onInput ", e)}
        inputMode={'select'}
        onDropdownSelect={onDropdownSelect}
        value={selectedValues || []}
        
    
        // onEditInput={() => console.log("onEditInput")}
        // onEditBeforeUpdate={() => console.log`onEditBeforeUpdate`}
        // onEditUpdated={() => console.log("onEditUpdated")}
        // onEditStart={() => console.log("onEditStart")}
        // onEditKeydown={() => console.log("onEditKeydown")}
        // onDropdownShow={() => console.log("onDropdownShow")}
        // onDropdownHide={() => console.log("onDropdownHide")}
        // onDropdownScroll={() => console.log("onDropdownScroll")}
        // onDropdownNoMatch={() => console.log("onDropdownScroll")}
        // onDropdownUpdated={() => console.log("onDropdownUpdated")}
      />

    </>
  )
}

export const serverDelay = (func: () => any) => (duration: number) =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      resolve(func())
    }, duration || 1000)
)

export const getWhitelistFromServer = serverDelay(() => []);

export const getValue = serverDelay(() => []);

import { createElement } from "react";
import { ChildComponentProps } from "../../../../app/interface/I_Permission";

const Retorno: React.FC<ChildComponentProps> = ({ children, ...props }) => {
    return createElement(
        'div',
        { permissiontype:'retorno', className: props.className || '' },
        children
    );
};

export default Retorno;
import React, { useState, useRef, useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { useAuth } from '../../../app/modules/auth';
import { isUri } from '../../helpers/functions/url';
import { PropsSandHistoric } from '../../../app/interface/I_Historic';
import { IFormHistoric } from '../../../app/interface/I_Historic';
import usePermissionStore from '../../../app/zustand/PermissionStore';

interface MyQuillEditorProps {
    onUpdateContent: (content: string) => void;
  }
  
const MyQuillEditor: React.FC<MyQuillEditorProps> = ({onUpdateContent }) => {

    const {currentUser} = useAuth()
    const permissionStore = usePermissionStore();

    const [formData, setFormData] = useState<IFormHistoric>({
        id_parent: '',
        id_contract: '',
        id_client: '',
        title: '',
        description:'',
        // image:{},
    });

    // Defina os módulos personalizados (se necessário)
    const CustomModules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ font: [] }],
            [{ align: [] }],
            ['link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }, { background: [] }],
        ],
    };
  
    // Defina os formatos personalizados (se necessário)
    const CustomFormats = [
        'header',
        'bold',
        'italic',
        'underline',
        'link',
        'list',
        'bullet',
    ];

    const [editorContent, setEditorContent] = useState<string>('');
    
    const {quill, quillRef } = useQuill({
        modules: CustomModules,
        formats: CustomFormats,
        theme: 'snow',
        // Adicione outras propriedades personalizadas, se necessário
    });


    useEffect(() => {

        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
            //   console.log('Text change!');
            //   console.log(quill.getText()); // Get text only
            //   console.log(quill.getContents()); // Get delta contents
            //   console.log(quill.root.innerHTML); // Get innerHTML using quill
            //   console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
              
                onUpdateContent(quill.root.innerHTML.toString())
            });

          }
    }, [quill]);


  return (
    <div>
        <div >
            {/* <div ref={quillRef} /> */}
                <div className="card-body pb-0 mb-15" style={{background:'#f5f8fa', borderRadius:'9px'}}>
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center flex-grow-1">
                        <div className='symbol symbol-40px me-5 w-40px h-40px d-flex align-items-center bg-opacity-50 bg-primary'>
                            {
                                isUri(currentUser?.photo) ? (
                                    <img src={currentUser?.photo} alt={currentUser?.fullname} />
                                ) : (
                                    
                                    <div className="d-flex justify-content-center col-md-12 text-light-secondary">
                                        <span className="align-self-center text-center fs-5">{currentUser?.name_short}</span>
                                    </div>
                                )
                            }
                            </div>

                            <div className="d-flex flex-column">
                                <a href="#" className="text-gray-900 text-hover-primary fs-6 fw-bold">{currentUser?.first_name} {currentUser?.last_name}</a>

                                <span className="text-gray-400 fw-bold">{permissionStore.roles || ''}</span>
                            </div>
                        </div>
                    </div>

                    <form id="" className="ql-quil ql-quil-plain pb-3">
                        <div id="" className="py-6 ql-container ql-snow" style={{background:'#f5f8fa'}}>                            
                            <div className="separator mb-7"></div>
                            <div className="ql-editor ql-blank" style={{ height: "100px" }} ref={quillRef} ></div>                            
                        </div>
                    </form>
            </div>

        </div>
    </div>
  );
};

export default MyQuillEditor;

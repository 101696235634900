import QRCode from 'qrcode.react';
import React, { useEffect, useState } from "react";
import { Gradient, PixFormParams } from "../../../../../app/interface/I_Configurations";
import { toAbsoluteUrl } from "../../../../helpers";
import { getConfigurationPix, postConfigurationPix } from "../../../../../app/request/configurations/_request";
import { useLocation } from "react-router-dom";
import { ButtonDownload } from "../../buttons/ButtonDownload";
import { ButtonSubmit } from "../../buttons/ButtonSubmit"
import Select from "react-select";
import { InputActionMeta } from 'react-select';
interface Option {
    value: string;
    label: string;
}

export const ConfigurationPix:React.FC = () => {

    const location = useLocation();
    const [selectedDirectionGradient, setSelectedDirectionGradient] = useState<Option | null>(null);

    const [fillLevel, setFillLevel] = useState('L');
    const [iconPix, setIconPix] = useState(toAbsoluteUrl('/media/logos/hbr/cerebro-hbr.png'));

    const [active, setActive] = useState(false);
    const [gradient, setGradient] = useState<Gradient>({
        fbColorStart:"#00ff00",
        fbColorEnd:"#ff0000",
        directionGradient:'center-to-edges',
    });

    const [editingPixParams, setEditingPixParams] = useState<PixFormParams>({
        chave: "",
        cidade: "",
        recebedor: "",
        valor: 0.0,
        identificador: "",
        mensagem: "",
        cep: "",
        icon: toAbsoluteUrl('/media/logos/hbr/cerebro-hbr.png')
    });

    /**
     top-to-bottom -> de cima pra baixo
     bottom-to-top -> de baixo pra cima
     left-to-right -> da esquerda para a direita
     right-to-left -> da direita pra esquerda
     center-to-edges -> do centro para as bordas
     edges-to-center -> das bordas para o centro
     */

    const optionsDirectionGradient: Option[] = [
        { value: "top-to-bottom", label: "De cima pra baixo" },
        { value: "bottom-to-top", label: "De baixo pra cima" },
        { value: "left-to-right", label: "Da esquerda para a direita" },
        { value: "right-to-left", label: "Da direita pra esquerda" },
        { value: "center-to-edges", label: "Do centro para as bordas" },
        { value: "edges-to-center", label: "Das bordas para o centro" },
    ];    
    
    useEffect(() => {

        async function fetchData() {
            
            const configPix = await getConfigurationPix();
            if(location.pathname === `/configurations/pix/settings`)
            {                
                setEditingPixParams(prevState => ({
                    ...prevState,
                    chave: configPix.data.chave || '',
                    recebedor: configPix.data.recebedor || '',
                    cidade: configPix.data?.cidade || '',
                    valor: configPix.data?.valor ? configPix.data?.valor : 0.0,
                    cep: configPix.data?.cep || '',
                    identificador: configPix.data?.identificador || '',
                    mensagem: configPix.data?.mensagem || ''
                }));

                setGradient({
                    fbColorStart:configPix.data?.fbColorStart || "#00ff00",
                    fbColorEnd:configPix.data?.fbColorEnd || "#ff0000",
                    directionGradient:configPix.data?.directionGradient || 'center-to-edges',
                })

                setActive(configPix.data?.ativo || false)                
                setFillLevel(configPix.data?.nivel || 'L')
            
                console.log('configPix ', configPix)
                
            }else if(location.pathname === `/configurations/pix/qrcode`)
            {
                setEditingPixParams(prevState => ({
                    ...prevState,
                    chave: configPix.data.chave || '',
                    recebedor: '',
                    cidade: '',
                    valor: 0.0,
                    cep: configPix.data?.cep || '',
                    identificador: '',
                    mensagem: '',
                }));

                setGradient({
                    fbColorStart: "#00ff00",
                    fbColorEnd:"#ff0000",
                    directionGradient:'center-to-edges',
                })

                setActive(true)
                setFillLevel('L')
            }

        }
        fetchData();
    }, [location.pathname]);    

    const { ...pixParams } = editingPixParams;
    const params = {
        value: JSON.stringify(editingPixParams),
        size: 307,
        level:fillLevel,
        includeMargin: true,
        style: {
            marginTop: '10%',
        },
        fgColor: '#366ec3', // cor de fundo do qrcode
        // renderAs:'canvas'
                
    };
    
    
    const handleDirectionGradient = (option: Option | null) => {
        setSelectedDirectionGradient(option);

        setGradient((prevState) => ({
            ...prevState,
            directionGradient: option?.value || '',
        }));
        console.log('handleDirectionGradient ', option)
    };

    
    const configIconpix = {
        src: iconPix || toAbsoluteUrl('/media/logos/hbr/cerebro-hbr.png'),
        x: params.size / 2 - 35,
        y: params.size / 2 - 30,
        height: 24,
        width: 24,
        excavate: true,
        asColor:'gradiente',
        gradient: {
            start: gradient.fbColorStart || "#00ff00",
            end: gradient.fbColorEnd || "#ff0000",
            direction:gradient.directionGradient || 'center-to-buttom',
            x1: 0,
            y1: 0,
            x2: params.size,
            y2: params.size

          }
    }

    /** Valida codigo hexadecimal */
    const validHexColor = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const response = await postConfigurationPix(editingPixParams);
          console.log(response.data);
        } catch (error) {
          console.log(error);
        }
    };


    console.log('gradient ', gradient)
    var colMd = 'col-md-6 col-lg-6 col-sm-12';
    if( location.pathname === `/configurations/pix/qrcode` )
    {
        colMd = 'col-md-4 col-lg-4 col-sm-12';
    }else{
        colMd = 'col-md-6 col-lg-6 col-sm-12';
    }

    return (
        <div className="col-xxl-6">
            <div className="card card-flush h-xl-100">
                <div className="card-body py-9">
                    <div className="row gx-9 h-100">
                        <div className="ms-n3 col-sm-4 mb-10 mb-sm-0" style={{borderRadius: '3%', border:'1px solid #d9d8d8'}}>
                            <div style={{ borderRadius: '20px', overflow: 'hidden' }} className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-400px min-h-sm-100 h-100" >
                                <QRCode id={'QrCode'} renderAs={'canvas'} title={'HBR'}  {...params} imageSettings={configIconpix} />
                            </div>
                        </div>
                                    
                        <div className="ms-3 col-sm-8">                                        
                            <div className="d-flex flex-column h-100">                                            
                                <div className="mb-7">  
                                    <form onSubmit={handleSubmit}> 
                                        {
                                            (location.pathname === `/configurations/pix/settings` && 
                                            
                                                <div className="d-flex flex-stack mb-6">                                                    
                                                    <div className="flex-shrink-0 me-5">
                                                        <span className="text-gray-400 fs-7 fw-bold me-2 d-block lh-1 pb-1">Inativar - Ativar</span>
                                                        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                                                            <input
                                                                className='form-check-input'
                                                                type='checkbox'
                                                                // value=''
                                                                checked={active}
                                                                onChange={(e) =>
                                                                    setActive(e.target.checked)
                                                                }
                                                            />
                                                            {/* <label className='form-check-label'>Enabled</label> */}
                                                        </div>
                                                    </div>
                                                    
                                                </div> 
                                            )
                                        }                                             
                                                                                       
                                        <div className="d-flex align-items-center flex-wrap d-grid gap-2">                                        
                                            <div className="row align-items-center">
                                                
                                                <div className={`${colMd}`}>
                                                    <div className="form-floating mb-7">
                                                        <input 
                                                            className={`form-control ${!active ? 'form-control-solid' : ''} `}
                                                            id="chave" 
                                                            placeholder="Chave Pix"
                                                            value={editingPixParams.chave}
                                                            onChange={(e) =>
                                                                setEditingPixParams((prevState) => ({
                                                                ...prevState,
                                                                chave: e.target.value,
                                                                }))
                                                            }
                                                            readOnly={!active}
                                                        />
                                                        <label htmlFor="chave">Chave</label>
                                                    </div>
                                                </div>
                                                {
                                                    (location.pathname === `/configurations/pix/qrcode` && 
                                                        <>
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-7">
                                                                    <input 
                                                                        className="form-control" 
                                                                        id="cidade" 
                                                                        placeholder="Cidade"
                                                                        value={editingPixParams.cidade}
                                                                        onChange={(e) =>
                                                                            setEditingPixParams((prevState) => ({
                                                                            ...prevState,
                                                                            cidade: e.target.value,
                                                                            }))
                                                                        }
                                                                    />
                                                                    <label htmlFor="cidade">Cidade</label>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-7">
                                                                    <input 
                                                                        className="form-control" 
                                                                        id="recebedor" 
                                                                        placeholder="Recebedor"
                                                                        value={editingPixParams.recebedor}
                                                                        onChange={(e) =>
                                                                            setEditingPixParams((prevState) => ({
                                                                            ...prevState,
                                                                            recebedor: e.target.value,
                                                                            }))
                                                                        }
                                                                    />
                                                                    <label htmlFor="recebedor">Recebedor</label>
                                                                </div>
                                                            </div>                                            
                                                            
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-7">
                                                                    <input 
                                                                        className="form-control" 
                                                                        id="valor" 
                                                                        placeholder="Valor"
                                                                        value={editingPixParams.valor}
                                                                        onChange={(e) =>
                                                                            setEditingPixParams((prevState) => ({
                                                                            ...prevState,
                                                                            valor: parseFloat(e.target.value),
                                                                            }))
                                                                        }
                                                                    />
                                                                    <label htmlFor="valor">Valor</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-7">
                                                                    <input 
                                                                        className="form-control" 
                                                                        id="identificador" 
                                                                        placeholder="Identificador"
                                                                        value={editingPixParams.identificador}
                                                                        onChange={(e) =>
                                                                            setEditingPixParams((prevState) => ({
                                                                            ...prevState,
                                                                            identificador: e.target.value,
                                                                            }))
                                                                        }
                                                                    />
                                                                    <label htmlFor="identificador">Identificador</label>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-7">
                                                                    <input 
                                                                        className="form-control" 
                                                                        id="mensagem" 
                                                                        placeholder="Mensagem"
                                                                        value={editingPixParams.mensagem}
                                                                        onChange={(e) =>
                                                                            setEditingPixParams((prevState) => ({
                                                                            ...prevState,
                                                                            mensagem: e.target.value,
                                                                            }))
                                                                        }
                                                                    />
                                                                    <label htmlFor="mensagem">Mensagem</label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    
                                                    )
                                                }
                                                
                                                
                                                <div className={`${colMd}`}>
                                                    <div className="form-floating mb-7">
                                                        <input 
                                                            className={`form-control ${!active ? 'form-control-solid' : ''}`} 
                                                            readOnly={!active} 
                                                            id="icon" 
                                                            placeholder="Icon"
                                                            onChange={(e) =>
                                                                setIconPix(e.target.value)
                                                            } 
                                                        />
                                                        <label htmlFor="icon">Icon</label>
                                                    </div>
                                                </div>
                                                <div className={`col-md-4`}>
                                                    <div className="form-floating mb-7">
                                                        <input 
                                                            className={`form-control`}
                                                            id="fbColorStart" 
                                                            placeholder="Cor Gradiente Inicial" 
                                                            value={gradient.fbColorStart}                                                           
                                                            onChange={
                                                                (e) => {
                                                                    const value = e.target.value;
                                                                    if (validHexColor.test(value)) {
                                                                        
                                                                    }
                                                                    setGradient((prevState) => ({
                                                                        ...prevState,
                                                                        fbColorStart: value,
                                                                    }));
                                                                }
                                                            } 
                                                        />
                                                        <label htmlFor="fbColorStart">Cor Gradiente Inicial</label>
                                                    </div>
                                                </div>

                                                <div className={`col-md-4`}>
                                                    <div className="form-floating mb-7">
                                                        <input 
                                                            className={`form-control`}
                                                            id="fbColorEnd" 
                                                            placeholder="Cor Gradiente Final" 
                                                            value={gradient.fbColorEnd}                                                              
                                                            onChange={
                                                                (e) => {
                                                                    const value = e.target.value;
                                                                    if (validHexColor.test(value)) {
                                                                        setGradient((prevState) => ({
                                                                            ...prevState,
                                                                            fbColorEnd: value,
                                                                        }));
                                                                    }
                                                                }
                                                            }  
                                                        />
                                                        <label htmlFor="fbColorEnd">Cor Gradiente Final</label>
                                                    </div>
                                                </div>
                                                
                                                <div className={`col-md-4`}>
                                                    
                                                    <div className="form-floating mb-7">
                                                        <Select
                                                            className=""
                                                            placeholder="Direção do Gradiente"
                                                            value={selectedDirectionGradient}
                                                            options={optionsDirectionGradient} 
                                                            
                                                            onChange={handleDirectionGradient}
                                                            // onChange={(selectedOption) =>
                                                            //     setGradient((prevState) => ({
                                                            //       ...prevState,
                                                            //       directionGradient: selectedOption?.value || 'top-to-bottom',
                                                            //     }))
                                                            //   }
                                                            
                                                        />
                                                        
                                                        {/* <label htmlFor="directionGradient">Direção do Gradiente</label> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="flex-shrink-0 me-5">
                                                    <span className="text-gray-400 fs-7 fw-bold me-2 d-block lh-1 pb-1">Nivel preenchimento</span>
                                                </div>
                                                <div className="mb-n5">
                                                    <div className="nav-group nav-group-fluid">
                                                        <label>
                                                            <input 
                                                                type="radio" 
                                                                className="btn-check"
                                                                checked={
                                                                    fillLevel == 'L' && true
                                                                } 
                                                                value={'L'}
                                                                onChange={(e) =>
                                                                    setFillLevel('L')
                                                                }
                                                            />
                                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary">
                                                                L
                                                            </span>
                                                        </label>

                                                        <label>
                                                            <input 
                                                                type="radio" 
                                                                className="btn-check"
                                                                checked={
                                                                    fillLevel == 'M' && true
                                                                }
                                                                value={'M'}
                                                                onChange={(e) =>
                                                                    setFillLevel('M')
                                                                }
                                                            />
                                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                                                                M
                                                            </span>
                                                        </label>

                                                        <label>
                                                            <input 
                                                                type="radio" 
                                                                className="btn-check"
                                                                value={'Q'}
                                                                checked={
                                                                    fillLevel == 'Q' && true
                                                                }
                                                                onChange={(e) =>
                                                                    setFillLevel('Q')
                                                                }
                                                            />
                                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                                                                Q
                                                            </span>
                                                        </label>

                                                        <label>
                                                            <input 
                                                                type="radio" 
                                                                className="btn-check"
                                                                value={'H'}
                                                                checked={
                                                                    fillLevel == 'H' && true
                                                                }
                                                                onChange={(e) =>
                                                                    setFillLevel('H')
                                                                }
                                                            />
                                                            <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                                                                H
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-2 mt-9">                                                
                                                <ButtonDownload idElem={"QrCode"} labelButton={"Download"} />
                                            </div>
                                            {
                                                // exibe o botao de submeter somente se a rota for setting e for habilitao para ativo
                                                ( location.pathname === `/configurations/pix/settings` && 
                                                    
                                                        active ? (
                                                            
                                                        <div className="col-md-2 ms-n4 mt-9">                                                
                                                            <ButtonSubmit labelButton={"Salvar"} />
                                                        </div>
                                                        ):(<></>)
                                                    
                                                )
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>                            
                        </div>                        
                    </div>                            
                </div>                
            </div>
        </div>
    )
}
import * as Yup from 'yup'

export const SchemaUnits = Yup.object().shape({

    id_product: Yup.string().test('is-zero-value', 'É necessário adicionar um produto', (value) => {
        if (value === '0' || value === '') {
            return false;
        }
        return true;
    }),
    name: Yup.string().required('O campo Nome da Unidade é obrigatório')
    // description: Yup.string().required('É necessário adicionar uma descrição'),

});


import { useState } from "react";
import { NumericFormat } from "react-number-format";
import Select from "react-select";
import { IBank, IBeneficiary, optionsStatusBank } from "../../../../../interface/I_Configurations";
import { Option } from "../../../../../interface/I_General";

export const CaixaEconomica:any = ({listBeneficiary, formDataEdit, combineFormValues}:any) => {
    const [formValues, setFormValues] = useState(formDataEdit || {
        id : '',
        id_sacador_avalista : '',
        bank_code:'',
        beneficiary : '',	
        name_apresentation : '',		
        agency : '',
        agency_dv : '',
        account : '',
        account_dv : '',
        beneficiary_code : '',
        code_beneficiario_dv : '',		
        carteira : '',
        status : '',
        message : '',
        
    });
    
    const handleInputChange = (
        value: string|number,
        fieldName: keyof IBank
      ) => {

        setFormValues({
            ...formValues,
            [fieldName]: value 
        });

        combineFormValues({
            [fieldName]: value
        });

    };

    const handleSelectChange = <K extends keyof IBank>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";
        const label = selectedOption ? selectedOption.label : "";
        if(fieldName === 'beneficiary')
        {
            setFormValues({
                ...formValues,
                [fieldName]: label, 
                ['id_sacador_avalista']: value
            });

            // setFormValues((prevState:any) => ({
            //     ...prevState, 
            // }));
            
            combineFormValues({
                [fieldName]: label
            });

            combineFormValues({
                [fieldName]: label,
                ['id_sacador_avalista']: value
            });
            
        }else{

            setFormValues({
                ...formValues,
                [fieldName]: value 
            });
            
            combineFormValues({
                [fieldName]: value
            });
        }
        
    };

    return (<>
                <h5 className="fs-6 mb-3">DADOS DA CONTA</h5>

                <div className="col-md-12">
                    
                    <label htmlFor="">Beneficiário</label>                               
                    <div className="form-floating mb-7">
                        <Select
                            styles={{ container: (base) => ({ ...base, zIndex: 10 }) }}
                            className="w-100"
                            placeholder="Beneficiário"
                            value={
                                !!formValues.id_sacador_avalista
                                ? listBeneficiary.find((option:IBeneficiary) => option.value === formValues.id_sacador_avalista)
                                : {value:'', label:''}
                            }
                            onChange={(selectedOption:any) => handleSelectChange(selectedOption as Option | null, "beneficiary")}
                            options={listBeneficiary}
                        />
                        
                    </div>
                </div>

                <div className="col-md-12">                                        
                    <div className="form-floating mb-7">
                        <input className="form-control" 
                            placeholder="Empresa" 
                            name="name_apresentation" 
                            id="name_apresentation"                                                                         
                            value={formValues.name_apresentation || ''}                                   
                            onChange={(e:any) => {
                                handleInputChange(e.target.value || '', "name_apresentation");
                                }
                            }
                        />
                        <label htmlFor="name_apresentation">Nome da conta</label>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="form-floating mb-7">
                        <input className="form-control" 
                            placeholder="Agência" 
                            name="agency" 
                            id="agency"                                                                         
                            value={formValues.agency || ''}                                   
                            onChange={(e:any) => {
                                handleInputChange(e.target.value || '', "agency");
                                }
                            }
                        />
                        <label htmlFor="agency">Agência</label>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="form-floating mb-7">
                        <input type="number" 
                            className="form-control" 
                            placeholder="Agência digito" 
                            name="agency_dv" 
                            id="agency_dv"                                                                         
                            value={formValues.agency_dv || ''}                                   
                            onChange={(e:any) => {
                                handleInputChange(e.target.value || '', "agency_dv");
                                }
                            }
                        />
                        <label htmlFor="agency_dv">Ag. dígito</label>
                    </div>
                </div> 
                <div className="col-md-3">
                    <div className="form-floating mb-7">
                        <input className="form-control" 
                            placeholder="Conta" 
                            name="account" 
                            id="account"                                     
                            value={formValues.account || ''}                                   
                            onChange={(e:any) => {
                                handleInputChange(e.target.value || '', "account");
                                }
                            }
                        />
                        <label htmlFor="account">Conta</label>
                    </div>
                </div>


                <div className="col-md-3">
                    <div className="form-floating mb-7">
                        <input className="form-control" 
                            placeholder="Conta digito" 
                            name="account_dv" 
                            id="account_dv"                                     
                            value={formValues.account_dv || ''}                                   
                            onChange={(e:any) => {
                                handleInputChange(e.target.value || '', "account_dv");
                                }
                            }
                        />
                        <label htmlFor="account_dv">Conta digito</label>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="form-floating mb-7">
                        <input className="form-control" 
                            placeholder="Código do beneficiário" 
                            name="beneficiary_code" 
                            id="beneficiary_code" 
                            value={formValues.beneficiary_code || ''}                                   
                            onChange={(e:any) => {
                                handleInputChange(e.target.value || '', "beneficiary_code");
                                }
                            }
                        />
                        <label htmlFor="beneficiary_code">Cód. Beneficiário</label>
                    </div>
                    
                </div> 

                <div className='col-md-4'>
                    <div className="form-floating mb-7">
                        <input className="form-control" 
                            placeholder="Beneficiário digito" 
                            name="code_beneficiario_dv" 
                            id="code_beneficiario_dv" 
                            value={formValues.code_beneficiario_dv || ''}                                   
                            onChange={(e:any) => {
                                handleInputChange(e.target.value || '', "code_beneficiario_dv");
                                }
                            }
                        />
                        <label htmlFor="code_beneficiario_dv">Beneficiário digito</label>
                    </div>                            
                </div>

                <div className='col-md-4'>
                    <div className="form-floating mb-7">
                        <input className="form-control" 
                            placeholder="Carteira" 
                            name="carteira" 
                            id="carteira"  
                            value={formValues.carteira || ''}                                   
                            onChange={(e:any) => {
                                handleInputChange(e.target.value || '', "carteira");
                                }
                            }
                        />
                        <label htmlFor="carteira">Carteira</label>
                    </div>                            
                </div> 

                <div className='col-md-4'>
                    <label htmlFor="">Status</label>
                    <div className="form-floating mb-7">
                        <div className='d-flex'>
                            <Select
                                styles={{ container: (base) => ({ ...base, zIndex: 10 }) }}
                                className="w-450px"
                                placeholder="Selecione"
                                value={
                                    !!formValues.active
                                    ? optionsStatusBank.find((option:IBeneficiary) => option.value === formValues.active)
                                    : {value:'', label:''}
                                }
                                
                                onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "active")}
                                options={optionsStatusBank}
                            />
                        </div>
                        
                    </div>                            
                </div>

                <div className='col-md-8'>
                    <div className="form-floating mb-7">
                        <textarea className="form-control" 
                            placeholder="Mensagem" 
                            name="message" 
                            id="message"
                            value={formValues.message || ''}
                            onChange={(e:any) => {
                                handleInputChange(e.target.value || '', "message");
                                }
                            }
                            ></textarea>
                        <label htmlFor="message">Mensagem</label>
                    </div>                            
                </div>
    </>);
}
import {FC, useState} from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { typeItemTaskSticky, ListPropsItemTasks} from '../../../types/tasks'

  
const StickyTask: React.FC<ListPropsItemTasks> = ({ onItemClick }) => {

    
    const [activeItem, setActiveItem] = useState<typeItemTaskSticky | null>(null);

    const itemTaskSticky: Array<typeItemTaskSticky> = [
        // {id:'64271b447a396', title:'Antecipação de parcelas', total:0, icon:'la-hand-holding-usd', color:'success'},
        // {id:'9b85aa5ddda74', title:'Quitação', total:0, icon:'la-tenge', color:'primary'},
        // {id:'f4414e6910046', title:'Distrato', total:0, icon:'', color:'danger'},
        // {id:'8709f27c3e803', title:'Enviar email', total:0, icon:'la-envelope', color:'info'},
        // {id:'489948855bef9', title:'Enviar Whatsapp', total:0, icon:'la-whatsapp', color:'info'},
        // {id:'b28d32c9a3110', title:'Acordos', total:0, icon:'la-comments-dollar', color:'warning'} 
        {id:'64271b447a396', api:'tableInstallmentAnticipation', title:'Antecipação de parcelas', total:0, icon:'/media/icons/duotune/general/gen014.svg', color:'success', type:'antecipaParc'},
        {id:'9b85aa5ddda74', api:'inadimplentes', title:'Quitação', total:0, icon:'/media/icons/duotune/general/gen020.svg', color:'primary', type:'quitacao'},
        {id:'f4414e6910046', api:'tableInstallmentAnticipation', title:'Distrato', total:0, icon:'/media/icons/duotune/general/gen034.svg', color:'danger', type:'distrato'},
        {id:'8709f27c3e803', api:'inadimplentes', title:'Enviar email', total:0, icon:'/media/icons/duotune/general/gen016.svg', color:'info', type:'envEmail'},
        {id:'489948855bef9', api:'tableInstallmentAnticipation', title:'Enviar Whatsapp', total:0, icon:'/media/icons/duotune/general/gen002.svg', color:'info', type:'envWhatsapp'},
        {id:'b28d32c9a3110', api:'inadimplentes', title:'Acordos', total:0, icon:'/media/icons/duotune/general/gen049.svg', color:'warning', type:'acordo'} 
    ]

       
  const handleItemClick = (item: typeItemTaskSticky) => {
    setActiveItem(item);
    onItemClick(item);
  };

    return (
        <div className="d-none d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-275px">
            <div
                className="card card-flush mb-0"
                data-kt-sticky="true"
                data-kt-sticky-name="inbox-aside-sticky"
                data-kt-sticky-offset="{default: false, xl: '100px'}"
                data-kt-sticky-width="{lg: '275px'}"
                data-kt-sticky-left="auto"
                data-kt-sticky-top="100px"
                data-kt-sticky-animation="false"
                data-kt-sticky-zindex="95"
            >
                <div className="card-body">
                    <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-3">
                        
                        {
                            itemTaskSticky.map((itemTask) => {
                                let _active = activeItem?.id === itemTask.id ? "show active" : "";
                                
                                return (
                                        <div key={itemTask.id} onClick={() => handleItemClick(itemTask)} className={`${_active} menu-item mb-3 ms-n7`}>
                                            <span className="menu-link">
                                                {/* <span className="menu-icon">
                                                    <i className={`las ${itemTask.icon}`}><span className="path1"></span><span className="path2"></span></i>
                                                </span> */}
                                                <span className="menu-icon">
                                                    <KTSVG path={itemTask.icon} className="svg-icon-muted svg-icon-1hx" />
                                                </span>
                                                <span className="menu-title fw-bold">{itemTask.title}</span>
                                                <span className={`badge badge-light-${itemTask.color}`}>{itemTask.total}</span>
                                            </span>
                                        </div>
                                    );
                            })
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )

}

export default StickyTask;
import { createElement } from "react";
import { ChildComponentProps, ChildComponentPropsLevelPermission } from "../../../../app/interface/I_Permission";
import PermissionLevel from "./PermissionLevel";

const Approval: React.FC<ChildComponentProps> & {
    PermissionLevel: React.FC<ChildComponentPropsLevelPermission>;
} = ({ children }) => {
    return createElement(
        'div',
        { permissiontype:'approval' }, 
        children
    );
};

Approval.PermissionLevel = PermissionLevel;

export default Approval;

import { KTSVG } from "../../helpers"
import { ChartDonutOneLine } from "../../helpers/components/chart/ChartDonutOneLine"
import { ProgressOneLine } from "../../helpers/components/chart/ProgressOneline"

import { useSelector } from 'react-redux';
import { useFetchBillingPositionQuery, useLazyFetchBillingPositionQuery } from "../../../app/redux/api/payment/billingPosition"; 
import { RootStateFilter } from "../../../app/interface/I_Filter";
import { useCallback, useEffect, useState } from "react";

export const IncomeStatement = () => {
  
    const formFilter = useSelector((state:RootStateFilter) => state.filter);
	const filterGlobalProduct = useSelector((state:any) => state.productSlice);
    const [combinedFilters, setCombinedFilters] = useState({});

    const [lazyFetchBillingPosition, { data: billingPosition, error: lazyError, isLoading: lazyIsLoading }] =
    useLazyFetchBillingPositionQuery();

    const lazyReportCallback = useCallback(() => {
        lazyFetchBillingPosition({
            ...formFilter,
            ...filterGlobalProduct,
        });
    }, [formFilter, filterGlobalProduct, lazyFetchBillingPosition]);

    useEffect(() => {
        // Chama a função de callback memorizada
        lazyReportCallback();
    }, [lazyReportCallback]);
   
    const colorProgressOneLine =  'primary'

    // liquidado
    const progressPaid =  {
        className: '', 
        width: billingPosition?.data?.paid?.percentMask || '0%', 
        color: 'success', 
        title: '', 
        description: '', 
        progressBar: billingPosition?.data?.paid?.percentMask || '0%'
    }
    
    // vencido
    const progressOverdue =  {
        className: '', 
        width: billingPosition?.data?.overdue?.percentMask || '0%',
        color: 'danger', 
        title: '', 
        description: '', 
        progressBar: billingPosition?.data?.overdue?.percentMask || '0%',
    }
    
    // a receber
    const progressOutstanding =  {
        className: '', 
        width: billingPosition?.data?.receive?.percentMask || '0%',
        color: 'warning', 
        title: '', 
        description: '', 
        progressBar: billingPosition?.receive?.paid?.percentMask || '0%',
    }


    return (
            <div className="card mb-6 mb-xl-9">
                <div className="card-body pt-9 pb-0">
                    {/* <div className="d-flex flex-wrap flex-sm-nowrap mb-6"> */}
                        <div className="row">
                            <div className="d-flex align-items-center mb-1">
                                <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bold me-3">Posição de cobrança</a>
                                <span className="badge badge-light-success me-auto">Em Progresso</span>
                            </div>
                            
                            <div className="fw-semibold mb-4 fs-5 text-gray-400">
                                <div className="col-12"># Acompanhe o progresso de todas as cobranças</div>
                                
                                <div className="col-12 mt-3">
                                    
                                    <h4>Total </h4>
                                    <span>{billingPosition?.data?.totalMask || 'R$ 0,00'}</span>
                                    {/*  Basta mandar a porcentagem */}
                                    <ProgressOneLine 
                                        height={"h-15px"} 
                                        color={'primary'}
                                        progressPaid={progressPaid}
                                        progressOverdue={progressOverdue}
                                        progressOutstanding={progressOutstanding}
                                    />
                                </div>
                            </div>

                            <div className="justify-content-center  p-3">
                                <div className="row g-2 g-xl-2">
                                    <div className="col-xl-4">
                                        <div className="card card-xxl-stretch mb-xl-8 bg-light-success">
                                            <div className="card-body d-flex pe-3">
                                                <div className="flex-grow-1 me-2">
                                                    {/* <KTSVG path={"/media/icons/duotune/general/gen048.svg"} className="svg-icon-2hx" /> */}
                                                    <a href="#" className="fw-bold text-gray-800 text-hover-primary fs-6"> Liquidados</a>
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bold">{billingPosition?.data?.paid?.valueMask || 'R$ 0,00'}</a>
                                                        
                                                    </div>
                                                </div>
                                                
                                                {/* Liquidados */}
                                                <ChartDonutOneLine chartSize={35} chartLine={9} chartRotate={145} colorHex={'#E4E6EF'} classChart={'success'} idElement={"chart_liquidados"} quantity={parseInt(billingPosition?.data?.paid?.count) || 0} valuePercent={parseInt(billingPosition?.data?.paid?.percentMask) || 0}/>   
                                                
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4">
                                        <div className="card card-xxl-stretch mb-xl-8 bg-light-danger">
                                            <div className="card-body d-flex pe-3">
                                                <div className="flex-grow-1 me-2">
                                                    <a href="#" className="fw-bold text-gray-800 text-hover-danger fs-6">Vencidos</a>
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a href="#" className="fs-5 text-gray-800 text-hover-danger fw-bold">{billingPosition?.data?.overdue?.valueMask || 'R$ 0,00'}</a>
                                                        
                                                    </div>
                                                </div>
                                                
                                                <ChartDonutOneLine chartSize={35} chartLine={9} chartRotate={145} colorHex={'#E4E6EF'} classChart={'danger'} idElement={"chart_vencidos"} quantity={parseInt(billingPosition?.data?.overdue?.count) || 0} valuePercent={parseInt(billingPosition?.data?.overdue?.percentMask) || 0}/>
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4">
                                        <div className="card card-xxl-stretch mb-xl-8 bg-light-warning">
                                            <div className="card-body d-flex pe-3">
                                                <div className="flex-grow-1 me-2">
                                                    <a href="#" className="fw-bold text-gray-800 text-hover-warning fs-6">A Receber</a>
                                                    <div className="mb-1 pe-3 flex-grow-1">
                                                        <a href="#" className="fs-5 text-gray-800 text-hover-warning fw-bold">{billingPosition?.data?.receive?.valueMask || 'R$ 0,00'}</a>
                                                        
                                                    </div>
                                                </div>
                                                
                                                <ChartDonutOneLine chartSize={35} chartLine={9} chartRotate={145} colorHex={'#E4E6EF'} classChart={'warning'} idElement={"chart_aReceber"} quantity={parseInt(billingPosition?.data?.receive?.count) || 0} valuePercent={parseInt(billingPosition?.data?.receive?.percentMask) || 0}/> 
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </div>

    )
}
import { KTSVG } from "../../../_metronic/helpers"
import MyQuillEditor from "../../../_metronic/partials/historico/MyQuillEditor"
import { Replay } from "../../../_metronic/partials/historico/Replay"

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { formProduct } from "../../interface/I_Product";
import { Importacao } from "../../../_metronic/layout/components/configurations/importacao/Importacao";
import { Upload } from "../../interface/I_Files";
import { useCallback, useEffect, useState } from "react";
import { useFetchListHistoricQuery } from "../../redux/api/historico/Historic";
import { useAuth } from "../../modules/auth";
import { getHistoricByIdParcel, insertHistoricAPI } from "../../request/historic";
import Swal from "sweetalert2";
import { isUri } from "../../../_metronic/helpers/functions/url";
import { useSelector } from "react-redux";
import { RootStateSliceHistoric } from "../../interface/I_Historic";
import { DrawePropsHistory } from "../../interface/I_Parcel";
import Select, {OptionsOrGroups, GroupBase} from "react-select";
import { optionsStatusParcelHistory } from "../../globals/OptionsGlobals";
import { Option } from "../../interface/I_General";


const DrawerHistoryParcel = ({
    closeDrawer,
    formValues
}:DrawePropsHistory) => {

        
    const [formData, setFormData] = useState({
        id_parent: '',
        id_contract: formValues.id_contract,
        id_client: formValues.id_client,
        id_parcel: formValues.id_parcel,
        title: '',
        description:'',
        value_option:'',
        date_activity:'',
    });
    const [dataHistoric, setDataHistoric] = useState<any>([]);
    // const {data, error, isLoading} = useFetchListHistoricQuery(dataHistoric);
    interface OptionSelected {
        label: string;
    }
    const fetchHistoricData = async () => {
        try {

            if(auth?.api_token && formValues.id_parcel )
            {
                const response = await getHistoricByIdParcel(formValues.id_parcel, auth);

                setDataHistoric(response.data)
            }
    
        } catch (error) {

          console.error(error);
    
        }
      };

    // sets de estados das variaveis
    const [respondingHistoricId, setRespondingHistoricId] = useState<number | null|string>(null);
    const [isHideEditor, setHideEditor] = useState(true);
    const [isHideNewHistoric, setIsHideNewHistoric] = useState(true);
    const {auth} = useAuth();


    const addNewHistoric = () => {
        setIsHideNewHistoric(false)
    }

    const confirmSendHistoric = () => {

        if(!!formData.description)
        {
            Swal.fire({
                title: 'Atenção',
                icon: 'info',
                html:'Desenja adicionar uma novo comentario ao histórico?',
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText:
                  'Sim!',
                cancelButtonText:
                  'Não',
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    handlerSubmit()
                }
            })
        }else{
            Swal.fire(
                'Atenção',
                'Adicione algum texto antes de enviar!',
                'info'
            )
        }
    }

    const handleUpdateContent = (content: string) => {

        setFormData((prevState) => ({
            ...prevState,
            ['id_parent']: respondingHistoricId?.toString() || '',
            ['description']: content
        }));
    };

    const handlerSubmit = async () => {
        
        setHideEditor(true)

        if(auth?.api_token)
        {
            const response = await insertHistoricAPI(formData, auth);
            if(response.data?.success)
            {                
                Swal.fire(
                    'Tudo certo',
                    'Comentario adicionado com sucesso.',
                    'success'
                )
                fetchHistoricData()
                handlerCancel();
                
            }else{
                Swal.fire(
                    'Ops',
                    'Tente novamente mais Tarde.',
                    'warning'
                )
            }
            
        }        
    }

    const handleSelectChange = (selectedOption: any) => {
        
        const {label, value} = selectedOption;
    
        setFormData(prevState => ({
            ...prevState,
            ['value_option'] : value,
            ['title'] : label
        }));
    };

    const handleInputChange = (
        value: string|number,
        fieldName: any
      ) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldName] : value,
        }));
      }

    const replyHistoric = (historicId: number) => {  
        setRespondingHistoricId(historicId);
        setFormData((prevState) => ({
            ...prevState,
            ['id_parent']: historicId.toString() || '',
        }));     
        setHideEditor(false)
    }

    const handlerCancel = () => {
        setRespondingHistoricId('');
        setFormData((prevState) => ({
            ...prevState,
            ['id_parent']: '',
            ['description']: '',
            ['id_client']: '',
        }));

        setHideEditor(true)
        setIsHideNewHistoric(true)
    }

    useEffect(()=> {
        fetchHistoricData()
    }, [])


    return (
        <div
          id='kt_activities'
          className={`bg-body ${!closeDrawer? "": "drawer drawer-end on drawer-on"}`}
          data-kt-drawer='true'
          data-kt-drawer-name='activities'
          data-kt-drawer-activate='true'
          data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'900px', 'lg': '900px'}"
          data-kt-drawer-direction='end'
        >
        <div className='card shadow-none rounded-0'>
            <div className='card-header' id='kt_activities_header'>
              <h3 className='card-title fw-bolder text-dark'>Comentario e observação da parcela</h3>

              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                  id='kt_activities_close'
                  onClick={closeDrawer}
                >
                    
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </button>
              </div>
            </div>
            {/* <div className="card card-flush pt-3 mb-5 mb-xl-10"> */}
                <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                    <div className="">
                        <div className="card-body">
                            <div className="d-flex flex-wrap gap-2 justify-content-between mb-8">
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                    <h2 className="fw-semibold me-3 my-1">Acompanhe tudo que contece com seu cliente</h2>

                                    {/* <span className="badge badge-light-danger my-1">
                                        
                                        <KTSVG path="/media/icons/duotune/communication/com004.svg"/>
                                        TEM ALGO NOVO
                                    </span> */}
                                </div>

                                <div className="d-flex">
                                    
                                    {!isHideNewHistoric 
                                    ? (
                                        <>
                                            <a type="button" onClick={() => confirmSendHistoric()} className="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 ms-4">
                                                <KTSVG className="svg-icon svg-icon-1x svg-icon-primary" path="/media/icons/duotune/general/gen016.svg"/>
                                                Enviar
                                            </a>
                                            
                                            <a type="button" onClick={() => handlerCancel()} className="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 ms-4">
                                                <KTSVG className="svg-icon svg-icon-1x svg-icon-primary" path="/media/icons/duotune/arrows/arr015.svg"/>
                                                Cancelar Comentario
                                            </a>
                                        </>
                                        )
                                    :(
                                        <a type="button" onClick={()=> addNewHistoric()} className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2" >
                                            <KTSVG path="/media/icons/duotune/general/gen035.svg"/>
                                        </a>
                                    )
                                }
                                    
                                </div>
                            </div>

                            
                            {!isHideNewHistoric 
                                ? (
                                    <>
                                        <div className="row col-md-12">
                                            <div className="col-md-4 mb-3">
                                                <label className='form-label fw-bold'>Situação</label>
                                                <div className='d-flex'>
                                                    <Select
                                                        className="w-450px"
                                                        placeholder="Selecione uma situação"
                                                        value={formData.value_option !== '' ? { value: formData.value_option, label: optionsStatusParcelHistory.find(option => option.value === formData.value_option)?.label } : null}                                    
                                                        onChange={(selectedOption) => handleSelectChange(selectedOption)}
                                                        options={optionsStatusParcelHistory}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 mt-n2 mb-3">
                                                <label className='form-label fw-bold'>Data da atividade</label>
                                                <div className='d-flex'>
                                                    <input 
                                                        className="form-control"
                                                        type="date" 
                                                        name="date_activity"
                                                        value={ formData.date_activity || ''}
                                                        onChange={(e) => {
                                                                handleInputChange(e.target.value, "date_activity")
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <MyQuillEditor
                                            onUpdateContent={handleUpdateContent}
                                        />
                                    </>
                                    )
                                :(<></>)
                            }

                            <div >
                                <div className="collapse fade show" >
                                    <div className="py-5">
                                        <div id="kt_activity_today" className="card-body p-0 tab-pane fade active show" role="tabpanel" aria-labelledby="kt_activity_today_tab">
                                            <div className="timeline">
                                                {
                                                    dataHistoric && Object.keys(dataHistoric).length > 0 ? (
                                                        dataHistoric.map((item:any) => (
                                                            <div className={`timeline-item `}>
                                                                <div className="timeline-line w-40px"></div>
                                                                <div className="timeline-icon symbol symbol-circle symbol-40px">
                                                                    <div className="symbol-label bg-light">
                                                                        <KTSVG path={item.icon|| ''}/>
                                                                    </div>
                                                                </div>
                                                                <div className="timeline-content mb-10 mt-n1">
                                                                    <div className="mb-5 pe-3">
                                                                        <a href="#" className="fs-5 fw-semibold text-gray-800 text-hover-primary mb-2">
                                                                            {item.title || '...'}
                                                                        </a>
                                                                        <span className="text-muted fw-bold ms-2">{item.diff_time || ''}</span>
                                                                        <div className="d-flex align-items-center mt-1 fs-6 mb-3">
                                                                            <div className="d-flex align-items-center mt-1 fs-6 mb-3">
                                                                                {
                                                                                    !!item.date_activity
                                                                                    ?(<><span className="badge badge-light-info fs-base me-3">Data da atividade: {item.date_activity}</span><span className="text-muted me-2"> -</span></>)
                                                                                    : (<></>)
                                                                                }
                                                                                <div className="text-muted me-2 fs-7">Data de gravação {item?.format_date}</div>
                                                                            </div>

                                                                            <div className="symbol symbol-circle symbol-25px">
                                                                                {
                                                                                !!item?.photo && isUri(item?.photo) ? (
                                                                                        <img src={item?.photo} alt={item?.full_name} />
                                                                                    ) : (
                                                                                        
                                                                                        <div className="w-25px h-25px d-flex align-items-center bg-opacity-50 bg-primary symbol symbol-30px symbol-md-25px symbol-30px symbol-md-25px">
                                                                                            <span className="d-flex justify-content-center col-md-12 text-light-secondary fs-7">{item?.short_name}</span>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center mb-5">
                                                                            <a href="#" className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4">
                                                                                <KTSVG path="/media/icons/duotune/communication/com012.svg"/>
                                                                                {item.count_comments || 1}
                                                                            </a>
                                                                            <a href="#" className="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2">
                                                                                <KTSVG path="/media/icons/duotune/general/gen005.svg"/>
                                                                                {item.count_file || 0}
                                                                            </a>

                                                                            {isHideEditor  ?
                                                                                (
                                                                                    <a type="button" onClick={() => replyHistoric(item.id)} className="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 ms-4">
                                                                                        <KTSVG className="svg-icon svg-icon-1x svg-icon-primary" path="/media/icons/duotune/arrows/arr054.svg"/>
                                                                                        Responder
                                                                                    </a>
                                                                                ):(<></>)
                                                                            }

                                                                            {!isHideEditor && (respondingHistoricId === item.id) ?
                                                                                (
                                                                                    <>
                                                                                        <a type="button" onClick={() => confirmSendHistoric()} className="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 ms-4">
                                                                                            <KTSVG className="svg-icon svg-icon-1x svg-icon-primary" path="/media/icons/duotune/general/gen016.svg"/>
                                                                                            Enviar
                                                                                        </a>
                                                                                        
                                                                                        <a type="button" onClick={() => handlerCancel()} className="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 ms-4">
                                                                                            <KTSVG className="svg-icon svg-icon-1x svg-icon-primary" path="/media/icons/duotune/arrows/arr015.svg"/>
                                                                                            Cancelar Comentario
                                                                                        </a>
                                                                                    </>
                                                                                ):(<></>)
                                                                            }
                                                                        </div>

                                                                        {!isHideEditor && respondingHistoricId === item.id 
                                                                            ? (
                                                                                <>
                                                                                    <div className="row col-md-12">
                                                                                        <div className="col-md-4 mb-3">
                                                                                            <label className='form-label fw-bold'>Situação</label>
                                                                                            <div className='d-flex'>
                                                                                                <Select
                                                                                                    className="w-450px"
                                                                                                    placeholder="Selecione uma situação"
                                                                                                    value={formData.value_option !== '' ? { value: formData.value_option, label: optionsStatusParcelHistory.find(option => option.value === formData.value_option)?.label } : null}                                    
                                                                                                    onChange={(selectedOption) => handleSelectChange(selectedOption)}
                                                                                                    options={optionsStatusParcelHistory}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3 mt-n2 mb-3">
                                                                                            <label className='form-label fw-bold'>Data da atividade</label>
                                                                                            <div className='d-flex'>
                                                                                                <input 
                                                                                                    className="form-control"
                                                                                                    type="date" 
                                                                                                    name="date_activity"
                                                                                                    value={ formData.date_activity || ''}
                                                                                                    onChange={(e) => {
                                                                                                            handleInputChange(e.target.value, "date_activity")
                                                                                                        }
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <MyQuillEditor
                                                                                        onUpdateContent={handleUpdateContent}
                                                                                    />
                                                                                </>
                                                                             )
                                                                             :(<></>)
                                                                        }
                                                                        
                                                                        {/* {item.description || ''} */}
                                                                        <div className="bg-light" style={{padding: '5px 10px 5px 10px', borderRadius:'5px'}} dangerouslySetInnerHTML={{ __html: item?.description|| '' }} />

                                                                        <div className="mb-4 mt-1"></div>
                                                                        
                                                                        {
                                                                            item.child 
                                                                            ? (
                                                                                item.child.map((child:any)=> (
                                                                                    <div className="overflow-auto mt-7 ms-10">
                                                                                        <div className="d-flex mb-5">
                                                                                            <KTSVG className="svg-icon svg-icon-2x " path="/media/icons/duotune/arrows/arr049.svg"/>
                                                                                            <div className="symbol symbol-45px me-5">
                                                                                            {
                                                                                                isUri(child?.photo) ? (
                                                                                                    <img src={child?.photo} alt={child?.full_name} />
                                                                                                ) : (
                                                                                                    
                                                                                                    <div className="w-30px h-30px d-flex align-items-center bg-opacity-50 bg-primary symbol symbol-30px symbol-md-25px symbol-30px symbol-md-25px">
                                                                                                        <span className="d-flex justify-content-center col-md-12 text-light-secondary fs-6">{child?.short_name}</span>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            </div>
                                                                                            <div className="d-flex flex-column flex-row-fluid">
                                                                                                <div className="d-flex align-items-center flex-wrap mb-1">
                                                                                                    <a href="#" className="text-gray-800 text-hover-primary fw-bold me-2">{child?.full_name || '...'}</a>
                                                                                                        <span className="text-gray-400 fw-semibold fs-7">{child?.diff_time}</span>
                                                                                                    </div>
                                                                                                    <div className="d-flex align-items-center mt-1 fs-6 mb-3">
                                                                                                        {
                                                                                                            !!child.date_activity
                                                                                                            ?(<><span className="badge badge-light-info fs-base me-3">Data da atividade: {child.date_activity}</span><span className="text-muted me-2"> -</span></>)
                                                                                                            : (<></>)
                                                                                                        }
                                                                                                        <div className="text-muted me-2 fs-7">Data de gravação {child?.format_date}</div>
                                                                                                    </div>
                                                                                                    {
                                                                                                        !!child?.path_file && isUri(child?.path_file) 
                                                                                                        ? (
                                                                                                            <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">
                                                                                                                <div className="d-flex flex-aligns-center pe-10 pe-lg-20">

                                                                                                                
                                                                                                                        <>
                                                                                                                            <img src={child?.path_file} alt={child?.name_file} />
                                                                                                                            <div className="ms-1 fw-semibold">
                                                                                                                                <a href="#" className="fs-6 text-hover-primary fw-bold">{child?.name_file}</a>
                                                                                                                                {/* <div className="text-gray-400">1.9mb</div> */}
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                
                                                                                                                
                                                                                                                </div>
                                                                                                            </div>
                                                                                                    ) : (
                                                                                                                
                                                                                                        <></>
                                                                                                    )
                                                                                            }
                                                                                             
                                                                                                <span className="bg-light" style={{padding: '5px 10px 0px 10px', borderRadius:'5px'}}><div dangerouslySetInnerHTML={{ __html: child?.description }} /></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            )
                                                                            
                                                                            :(<></>)
                                                                        }

                                                                        
                                                                        {/* <Importacao
                                                                            propsFiles={proposUpload}
                                                                        /> */}
                                                                    </div>
                                                                    
                                                                </div>
                                                                {/* exibe o carregando */}
                                                                {/* <div className="blockui-overlay bg-danger bg-opacity-25" style={{zIndex:'1'}}><span className="spinner-border text-primary"></span></div> */}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="alert alert-dismissible bg-primary d-flex flex-column flex-sm-row w-100 p-5 mb-10">
                                                            <KTSVG className="svg-icon svg-icon-1x svg-icon-primary" path="/media/icons/duotune/communication/com012.svg"/>
                                                            <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                                                                <h4 className="mb-2 text-light">Precisamos de sua atenção</h4>
                                                                <span>Ainda não há histórico de atividade, financeira.</span>
                                                            </div>

                                                            <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                                                                <KTSVG className="svg-icon svg-icon-1x svg-icon-primary" path="/media/icons/duotune/arrows/arr011.svg"/>
                                                            </button>
                                                        </div>

                                                    )
                                                }
                                                

                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </div>
    </div>

    )

}

export {DrawerHistoryParcel}

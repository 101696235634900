import { createElement } from "react";
import { ChildComponentProps } from "../../../../app/interface/I_Permission";

const Spreadsheet: React.FC<ChildComponentProps> = ({ children, ...props }) => {
    return createElement(
        'div',
        { permissiontype:'spreadsheet', className: props.className || '' },
        children
    );
};

export default Spreadsheet;

import { createElement } from "react";
import { ChildComponentProps } from "../../../../app/interface/I_Permission";

const Create: React.FC<ChildComponentProps> = ({ children }) => {
    return createElement(
        'div',
        { permissiontype:'create' },
        children
    );
};
  
export default Create;


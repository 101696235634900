import { toAbsoluteUrl } from "../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import TabRouteHeaderPixQrCode from "../../../_metronic/partials/configurartion/pix/tabs/TabRouteHeaderPixQrCode"


const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Configuração',
      path: '/configuration/access',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

export const PixWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>QrCode - Pix</PageTitle>
            <TabRouteHeaderPixQrCode/>
        </>

    )
}
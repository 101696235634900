
import { ReactChild } from "react"
import { Link } from "react-router-dom"
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers"
import ComponentPermission from "../../../_metronic/layout/components/permissions/ComponentPermission"
import { ICardConfiguration } from "../../interface/I_Configurations"
export const ContentGeneral: React.FC = () => {

    const ComponentConfuguration: Array<ICardConfiguration> = [
        {
            id:'47aad2c3a8a',
            title:'Retorno',
            description:'Importe seu arquivo de retorno',
            icon:'/media/icons/duotune/files/fil010.svg',
            background:'/media/outros/abstract-1.svg',
            // icon:'/media/svg/files/upload.svg',
            routes:'/configuration/import'
        },
        {
            id:'a50c1efbf417',
            title:'Remessa',
            description:'Exporte seu arquivo remessa',
            icon:'/media/icons/duotune/files/fil021.svg',
            background:'/media/outros/abstract-2.svg',
            routes:'/configuration/export'
        },
        {
            id:'0f5ba114f8fd',
            title:'Pix',
            description:'Configure geração de imagem Pix',
            icon:'/media/icons/duotune/general/gen022.svg',
            background:'/media/outros/abstract-4.svg',
            routes:'/configuration/pix'
        },
        {
            id:'63fkdj594jc',
            title:'Planilha',
            description:'Importe planilha bancaria',
            icon:'/media/icons/duotune/files/fil002.svg',
            background:'/media/outros/abstract-1.svg',
            routes:'/configuration/spreadsheet'
        },
        {
            id:'77olut338pp',
            title:'Bancos',
            description:'Banco usado para geração de boletos',
            icon:'/media/icons/duotune/finance/fin001.svg',
            background:'/media/outros/abstract-2.svg',
            routes:'/accountsBilling/bank'
        },
        {
            id:'22jwei079ao',
            title:'Permissão',
            description:'Permissões e funções de usuários',
            icon:'/media/icons/duotune/coding/cod007.svg',
            background:'/media/outros/abstract-4.svg',
            routes:'/configuration/permission'
        },
        {
            id:'48Aey00273l',
            title:'Bolecode',
            description:'Lista de Pix',
            icon:'/media/icons/duotune/coding/cod007.svg',
            background:'/media/outros/abstract-4.svg',
            routes:'/configuration/bolecode'
        }
    ]
    const componentPermissionMap: Record<string, React.FC> = {
        Retorno: ComponentPermission.Retorno,
        Remessa: ComponentPermission.Remessa,
        Planilha: ComponentPermission.Spreadsheet,
        Bolecode: ComponentPermission.Bolecode,
        // Adicione outras propriedades conforme necessário
    };

    return (
        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
            {ComponentConfuguration.map((value) => {
                const ComponentToRender: React.ComponentType<{ children:ReactChild, className?: string }> | null = componentPermissionMap[value.title] || null;


                if (ComponentToRender) {
                    return (
                        <ComponentPermission >
                            <ComponentToRender className="col-md-4 col-lg-4 col-xl-3 col-sm-12">
                                <div className="" style={{ backgroundImage: `url(${toAbsoluteUrl(value?.background || '')})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right top' }} key={value.id}>
                                    <div className="card card-flush p-6 pb-5 mw-100" style={{ backgroundImage: `url(${toAbsoluteUrl(value?.background || '')})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right top' }} key={value.id}>
                                        <Link to={value.routes}>
                                            <div className="card-body text-center">
                                                <KTSVG className="svg-icon svg-icon-5x" path={value.icon} />
                                                <div className="mb-2">
                                                    <div className="text-center">
                                                        <span className="fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 fs-xl-1">{value.title}</span>
                                                        <span className="text-gray-400 fw-semibold d-block fs-6 mt-n1">{value.description}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </ComponentToRender>
                        </ComponentPermission>
                    );
                } else {
                    return (
                        <div className="col-md-4 col-lg-4 col-xl-3 col-sm-12 " style={{ backgroundImage: `url(${toAbsoluteUrl(value?.background || '')})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right top' }} key={value.id}>
                            <div className="card card-flush p-6 pb-5 mw-100" style={{ backgroundImage: `url(${toAbsoluteUrl(value?.background || '')})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right top' }} key={value.id}>
                                <Link to={value.routes}>
                                    <div className="card-body text-center">
                                        <KTSVG className="svg-icon svg-icon-5x" path={value.icon} />
                                        <div className="mb-2">
                                            <div className="text-center">
                                                <span className="fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 fs-xl-1">{value.title}</span>
                                                <span className="text-gray-400 fw-semibold d-block fs-6 mt-n1">{value.description}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    );
                }
            })}
        </div>
    );
}
import { useState } from "react"
import { NumericFormat } from "react-number-format";
import { KTSVG } from "../../../../../_metronic/helpers"
import { DrawePropsCalculo, ISimulation } from "../../../../interface/I_Parcel";
import { useAuth } from "../../../../modules/auth";
import { calcularAntecipacaoParcela } from "../../../../request/parcel";
import { Details } from "../Details";
import { Summary } from "../Summary";

export const DrawerAntecipation = ({
    closeDrawer,
    formValues
}:DrawePropsCalculo) => {

    const {auth} = useAuth();

    const [formData, setFormData] = useState<ISimulation>({
        id_contract:formValues?.id_contract || 0,
        name_contract:formValues?.name_contract || '',
        option_memory_calculo:'',
        value_parcel:'',
        type_simulation:'antecipacao',
        number_parcel:0,
    });

    const [calculoSimulation, setCalculoSimulation] = useState<any>({});

    const [visibilidade, setVisibilidade] = useState({
        stepp_1:'block',
        stepp_2:'none',
        btn_previous:'none',
        btn_calcular:'block',
        btn_boleto:'none',
        submit:false,
    });

    
    const [loadingBoleto, setLoadingBoleto] = useState({
        submit:false,
    });

    const setTypeMemoryCalculo = (typeMemoryCalculo:string) => {

        let elementos = document.getElementsByClassName('marcao_option');
        if (elementos.length > 0)
        { 
            for (var i = 0; i < elementos.length; i++)
            {
                elementos[i].classList.remove('border-danger');
            }
        }

        if (typeMemoryCalculo !== formData.option_memory_calculo)
        {
            formData.option_memory_calculo = typeMemoryCalculo;
            if(typeMemoryCalculo === 'value_parcel')
            {
                setFormData((prevState) => ({
                    ...prevState,
                    number_parcel:0
                }))
            }else{
                setFormData((prevState) => ({
                    ...prevState,
                    value_parcel:'0,00'
                }))
            }
        }
    }


    const parsedValue = formData.number_parcel;
    const sanitizedValue = parsedValue && parsedValue > 0 ? parsedValue : 0;
    
    const submitAntecipacao = () => {

        const elementos = document.getElementsByClassName('marcao_option');
        const elementosArray = Array.from(elementos); // Converte HTMLCollection em um array

        if (formData.option_memory_calculo !== '') {
        elementosArray.forEach((element) => {
            element.classList.remove('border-danger');
        });

        if (
            (formData.option_memory_calculo === 'number_parcel' && formData.number_parcel !== 0) ||
            (formData.option_memory_calculo === 'value_parcel' && formData.value_parcel !== '0,00')
        ) {
            setVisibilidade((prevState) => ({
            ...prevState,
            submit: true,
            }));

            calcularAntecipacao();
        }
        } else {
        elementosArray.forEach((element) => {
            element.classList.add('border-danger');
        });
        }
    }

    const calcularAntecipacao = async () => {

        if(auth?.api_token)
        {
            const response = await calcularAntecipacaoParcela(formData, auth);
            if(response.data && Object.keys(response.data).length > 0)
            {
                setCalculoSimulation({
                    ...calculoSimulation,
                    ...response.data,
                    ['name_contract'] : formData.name_contract
                });
            }

            setVisibilidade((prevState) => ({
                ...prevState,
                submit:false, 
                btn_previous:'block',           
                btn_calcular:'none',
                btn_boleto:'block',           
                stepp_1:'none',           
                stepp_2:'block',           
            }));
        }
    }

    const handleVoltar = () => {

        setCalculoSimulation({});

        setFormData((prevState) => ({
            ...prevState,
            number_parcel:0,
            value_parcel:'0,00'
        }))

        // Atualize o estado visibilidade
        setVisibilidade({
          stepp_1: 'block',
          stepp_2: 'none',
          btn_previous: 'none',
          btn_calcular: 'block',
          btn_boleto: 'none',
          submit: false,
        });

        setLoadingBoleto((prevState) => ({
            ...prevState,
            submit:false,         
        }));
    };
    
    const gerarBoletoAntecipacao = () => {

        setLoadingBoleto((prevState) => ({
            ...prevState,
            submit:true,         
        }));
    }

    return (
        <div
          id='kt_antecipation'
          className={`bg-body ${!closeDrawer? "": "drawer drawer-end on drawer-on"} w-1000px`}
          data-kt-drawer='true'
          data-kt-drawer-name='antecipation'
          data-kt-drawer-activate='true'
          data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'w-auto', 'md': '752px'}"
          data-kt-drawer-direction='end'
        >
        <div className='card w-100 rounded-0'>
            <div className='card-header' id='kt_activities_header'>
              <h3 className='card-title fw-bolder text-dark'>Simulação de antecipação de parcelas</h3>

              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                  id='kt_activities_close'
                  onClick={closeDrawer}
                >
                    
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </button>
              </div>
            </div>
            {/* <div className="card card-flush pt-3 mb-5 mb-xl-10"> */}
                <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                    <div className="card-body">
                        <div  style={{display:visibilidade.stepp_1, marginLeft:'21%'}}  id="stepp_1" >
                            <div className="d-flex flex-wrap gap-2 justify-content-between mb-8" id="stepp_1">
                                
                                <div className="">                                    

                                    <div className="alert alert-primary d-flex align-items-center p-5 mb-15">

                                        <KTSVG className="svg-icon svg-icon-2x svg-icon-primary" path="/media/icons/duotune/general/gen048.svg"/>                                    
                                        <div className="d-flex flex-column">
                                            
                                            <h4 className="mb-1 text-dark">Escolha umas das opções</h4>                                        
                                            <span>Cada opção tem seu proprio critério de calculo.</span>
                                            
                                        </div>
                                        
                                    </div>

                                    <div data-kt-buttons="true">
                                        
                                        <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary marcao_option d-flex flex-stack text-start p-6 mb-5 ${formData.option_memory_calculo === 'value_parcel' ? 'active' : ''} `}>
                                            
                                            <div className="d-flex align-items-center me-2">
                                                
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input 
                                                        className="form-check-input" 
                                                        onChange={() => setTypeMemoryCalculo('value_parcel')} 
                                                        type="radio"  
                                                        checked={formData.option_memory_calculo === 'value_parcel' ? true : false}  
                                                        name="option_memory_calculo" 
                                                        value={formData.option_memory_calculo}
                                                    />
                                                </div>
                                                
                                                <div className="flex-grow-1">
                                                    <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                                        Valor
                                                    </h2>
                                                    <div className="fw-semibold opacity-50">
                                                        Amotizar valor de parcela
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                            <div className="ms-5">
                                                <div className="form-floating">

                                                    <NumericFormat
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        prefix="R$ "
                                                        thousandsGroupStyle="thousand"
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        allowLeadingZeros={true}
                                                        className={`form-control ${formData.option_memory_calculo === 'value_parcel' ? 'obrigatorio' : ''}`} 
                                                        name="value_parcel"
                                                        value={formData.value_parcel || '0,00'}
                                                        onValueChange={(values) => {
                                                            // handleInputChange(values.floatValue || 0, "value");
                                                            setFormData({ ...formData, value_parcel: values.floatValue || 0 })
                                                            }
                                                        }
                                                    />
                                                    <label htmlFor="value_parcel">Valor da prestação</label>
                                                    { formData.option_memory_calculo === 'value_parcel' && formData.value_parcel === '0,00'  && (
                                                        <div className="fv-plugins-message-container invalid-feedback">Valor da prestação é obrigatório</div>
                                                    )}
                                                </div>
                                            </div>
                                            
                                        </label>
                                        
                                        <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary marcao_option d-flex flex-stack text-start p-6 mb-5 ${formData.option_memory_calculo === 'number_parcel' ? 'active' : ''}`}>
                                            
                                            <div className="d-flex align-items-center me-2">
                                                
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input 
                                                    className="form-check-input" 
                                                    type="radio"  
                                                    onChange={() => setTypeMemoryCalculo('number_parcel')} 
                                                    name="option_memory_calculo" 
                                                    value={formData.option_memory_calculo || ''}
                                                    checked={formData.option_memory_calculo === 'number_parcel' ? true : false}
                                                />
                                                </div>
                                                
                                                <div className="flex-grow-1">
                                                    <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                                        Parcelas
                                                        {/* <span className="badge badge-light-success ms-2 fs-7">Abater</span> */}
                                                    </h2>
                                                    <div className="fw-semibold opacity-50">
                                                        Abater últimas parcelas
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                            <div className="ms-5">
                                                <div className="form-floating">
                                                    <input 
                                                        type='number' 
                                                        name="number_parcel" 
                                                        className={`form-control `} 
                                                        id="number_parcel" 
                                                        placeholder="Número de parcelas" 
                                                        value={sanitizedValue}
                                                        onChange={(e) => {
                                                            const inputValue = parseInt(e.target.value, 10);
                                                            const newValue = inputValue > 0 ? inputValue : 0;
                                                            setFormData({ ...formData, number_parcel: e.target.value.toString() || 0 });
                                                        }}
                                                    />
                                                    
                                                    <label htmlFor="number_parcel">Número de parcelas</label>
                                                    {formData.option_memory_calculo === 'number_parcel' && formData.number_parcel === 0  && (
                                                        <div className="fv-plugins-message-container invalid-feedback">Número da parcela é obrigatório</div>
                                                    )}
                                                </div>
                                            </div>
                                            
                                        </label>
                                        
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                        <div  style={{display:visibilidade.stepp_2}}  id="stepp_2" >
                            <div className="row flex-wrap gap-2 justify-content-between mb-8">
                                
                                <div className="">
                                    
                                    {/* Resumo */}                                    

                                    <Summary calculoSimulation={calculoSimulation}/>

                                    <div className="separator border-gray-200 mb-6"></div>

                                    <div className="d-flex flex-column w-100">
                                        <div className="fw-semibold fs-5 mb-3 text-dark00">EXTRATO</div>

                                        <div className="py-1">
                                            <div className="py-3 d-flex flex-stack flex-wrap">
                                                                  
                                                <div className="me-3">
                                                    <div className="d-flex align-items-center fw-bold">
                                                        Valor simulado : {calculoSimulation?.extract?.simulated_value || '...'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="separator separator-dashed"></div>

                                        <div className="py-1">
                                            <div className="py-3 d-flex flex-stack flex-wrap">                                                                
                                                <div className="me-3">
                                                    <div className="d-flex align-items-center fw-bold">
                                                    Prestação simulada: {calculoSimulation?.extract?.simulated_parcel || '...'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="separator separator-dashed"></div>

                                        <div className="py-1">
                                            <div className="py-3 d-flex flex-stack flex-wrap">                                                                     
                                                <div className="me-3">
                                                    <div className="d-flex align-items-center fw-bold">
                                                        Valor amortizado: {calculoSimulation?.extract?.amortized_value || '...'}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        {/* {formData.option_memory_calculo === 'number_parcel' && ( */}
                                            <>
                                                <div className="separator separator-dashed"></div>

                                                <div className="py-1">
                                                    <div className="py-3 d-flex flex-stack flex-wrap">
                                                        <div className="d-flex align-items-center collapsible toggle collapsed" data-bs-toggle="collapse" data-bs-target="#number_parcel_remaining_update" aria-expanded="false">
                                                            <div className="btn btn-sm btn-icon btn-active-color-primary ms-n3 me-2">
                                                                <KTSVG className="toggle-off fs-2" path="/media/icons/duotune/arrows/arr075.svg"/>
                                                                <KTSVG className="toggle-on fs-2" path="/media/icons/duotune/arrows/arr090.svg"/>
                                                            </div>                  
                                                            <div className="me-3">
                                                                <div className="d-flex align-items-center fw-bold">
                                                                    Prestações amortizadas: {calculoSimulation?.extract?.parcel_deducted || '...'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="number_parcel_remaining_update" className="fs-6 ps-10 collapse" >
                                                
                                                        <div className="d-flex flex-wrap py-5">
                                                            {
                                                                
                                                            calculoSimulation?.data?.parcelasAmortizadas ? (
                                                                
                                                                        <Details 
                                                                            parcelas={calculoSimulation?.data?.parcelasAmortizadas}
                                                                            pendentes={calculoSimulation?.data?.pendentes}
                                                                            type_simulation={'antecipation'}
                                                                        />
                                                                    
                                                                ):(<></>)
                                                            
                                                            }                                                           
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        {/* )} */}
                                        
                                        <div className="separator separator-dashed"></div>
                                        
                                        <div className="py-1">
                                            <div className="py-3 d-flex flex-stack flex-wrap">
                                                                 
                                                <div className="me-3">
                                                    <div className="d-flex align-items-center fw-bold">
                                                    Prestações remanescentes: {calculoSimulation?.extract?.number_parcel_remaining_update || '...'}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div> 
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
            {/* botoes voltar e avançar */}
            <div className='card-footer py-5 text-center' id=''>
                <div className="d-flex flex-stack pt-10"> 
                    
                    <div className="mr-2" style={{display:visibilidade.btn_previous}}>
                        <button 
                                type="button" 
                                onClick={() => handleVoltar()}
                                className="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous"> 
                            <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/arrows/arr063.svg"/> 
                            Voltar
                        </button>
                    </div>
                    
                    <div>
                        <button type="button" style={{display:visibilidade.btn_calcular}} onClick={() => submitAntecipacao()} data-kt-indicator={`${visibilidade.submit === true ? 'on' : 'off'}`}  className="btn btn-lg btn-primary me-3" data-kt-stepper-action="submit">
                            <span className="indicator-label">
                                Calcular
                                <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/arrows/arr064.svg"/>
                            </span>
                            <span className="indicator-progress">
                                Aguarde... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>

                        {/* <button type="button" style={{display:visibilidade.btn_boleto}} onClick={() => gerarBoletoAntecipacao()} data-kt-indicator={`${loadingBoleto.submit === true ? 'on' : 'off'}`}  className="btn btn-lg btn-primary me-3" data-kt-stepper-action="submit">
                            <span className="indicator-label">
                                Gerar Boleto
                                <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/arrows/arr064.svg"/>
                            </span>
                            <span className="indicator-progress">
                                Aguarde... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button> */}
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

    )
}
import axios, { AxiosError, AxiosResponse } from 'axios'
import BaseServiceHttp from '../../_metronic/helpers/functions/http/BaseServiceHttp';
import { IResponse, AuthType} from '../interface/I_General';
import { FormInsertProduct, formProduct } from '../interface/I_Product';

const API_URL = process.env.REACT_APP_API_URL;

export const getAllProduct = async (auth: AuthType)  => {

    try{
        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/productAll`,
                            {},
                            { headers }
                        );

        return response;
    }catch(error){

    }
}

// deleta uma ou mais produtos
export async function deleteProductsAPI(ids: string[], auth: AuthType) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/deleteProduct`,
        data: {
            ids
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar deletar produto'
                                }
                            }
                        });
    return response;
}

// recupera um produto
export async function getDataProduct(id: string | number, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getProductById`,
                            { id },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}

// recupera um produto
export async function saveProduct(data: formProduct | FormInsertProduct) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/saveProduct`,
        data: {
            data
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar salvar produto'
                                }
                            }
                        });
    return response;
}


// recupera as imagensdo produto
export async function getImagensProduct(id: string | number , auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getImagensProduct`,
                            { id },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}
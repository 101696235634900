import Swal from "sweetalert2"
import { queryFileRemessa, updateLastDownloadRemessa } from "../../../../app/request/configurations/_request"

export const  downloadRemessa = async (path_cloud:string, auth:any) =>{

    const responseRemessa = await queryFileRemessa(path_cloud, auth);

    return responseRemessa;
    
}

export const base64ToBlob = (base64String:any) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: 'application/rem' });
}

export const lastDownload = async(id:string|number, auth:any) => {
    const responseRemessa = await updateLastDownloadRemessa(id, auth);
}
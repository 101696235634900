import { useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { confirmEncaminharBoleto, downloadBoletoPdf } from "../../../../_metronic/helpers/functions/boleto/boleto";
import { isDateExpired } from "../../../../_metronic/helpers/functions/dates/date";
import { statusContract } from "../../../Constants";
import { optionsStatusParcel } from "../../../globals/OptionsGlobals";
import { IHeaderWithCell } from "../../../interface";
import { useAuth } from "../../../modules/auth";
import { TooltipCustom } from "../../../../_metronic/helpers/functions/TooltipCustom";
import ComponentPermission from "../../../../_metronic/layout/components/permissions/ComponentPermission";
import { downloadBoletos } from "../../../request/payment";

export const HeaderTablePayment = (type: string, handleEdit?: (row: any) => void,  handleHistory?: (row: any) => void): IHeaderWithCell[] => {

    const {auth} = useAuth();
    const [isMouseOver, setIsMouseOver] = useState(false);

    switch (type) {
        case 'listParcelsCanceled':
            return [
                { Header: 'Contrato', accessor: 'contract' },
                { Header: 'Situação', accessor: (row:any) => {
                        const status_contract = statusContract.find(item => item.value === row.status_contract)?.label || '';
                        return status_contract
                    }
                },
                { Header: 'Valor', accessor: 'value' },
                { Header: 'Vencimento', accessor: 'due_date' },
                { Header: 'Tipo de Parcela', accessor: 'type_parcel' },
                { Header: 'Parcela', accessor: (row:any) => {
                        const statusParcel = optionsStatusParcel.find(item => item.value === row.status)?.writeLabel || 'Pendente';
                        return statusParcel
                    }
                },
                { Header: 'Ações', 
                    accessor: (row: any) => {
                        return <div id={`actions_${row.id}`}>
                        {

                            handleHistory && typeof handleHistory === 'function' && (
                                <button
                                    className={`btn btn-sm w-20px ms-2 fw-bold btn-active-color-danger bg-opacity-15 bg-danger text-danger`}
                                    onClick={() => handleHistory(row)}
                                >
                                    <KTSVG className={`svg-icon svg-icon-1x ms-n2`} path="/media/icons/duotune/communication/com012.svg"/>
                                    
                                    {
                                        !!row.id_historic && (
                                            <span style={{marginTop:'-2.2rem', marginLeft:'-1.5rem'}} className="pulse-ring"></span>
                                        )
                                    }
                                </button>
                            )
                        }
                        </div>
                    }
                },
            ]; 
        break;
        case 'listPayment': // lista de pagamentos
            return [
                { Header: 'Contrato', accessor: 'contract' },
                { Header: 'Situação', accessor: (row:any) => {
                        const status_contract = statusContract.find(item => item.value === row.status_contract)?.label || '';
                        return status_contract
                    }
                },
                { Header: 'Cliente', accessor: 'name_client' },
                { Header: 'Valor', accessor: 'value' },
                // { Header: 'Renegociado', accessor: 'renegotiatedvalue' },
                { Header: 'Vencimento', accessor: 'due_date' },
                { Header: 'Tipo de parcela', accessor: 'type_parcel' },
                { Header: 'Parcela', accessor: (row:any) => {
                        if(row.deleted === null)
                        {
                            let payment_date: JSX.Element | null = null;
                                
                            if(row.mark_parcel_paid !== '' && row.mark_parcel_paid !== null)
                            {
                                if (!!row.payment_date)
                                {
                                    payment_date = (
                                        <span className="badge badge-light-success fs-7 ms-2">{row.payment_date}</span>
                                    );
                                }
                                return (<>
                                        <span>{row.mark_parcel_paid}</span>
                                        <div>{payment_date}</div>
                                </>)
                                
                            }else{

                                if(row.status_contract == '3')
                                {
                                    return 'Distratado'; 
                                }else{
                                    
                                    const status: { [key: string]: string } = {'1':'success', '2':'warning' }
                                    if (!!row.payment_date && (row.status == 1 || row.status == 2))
                                    {
                                        payment_date = (
                                            <span className={`badge badge-light-${status[row.status]} fs-7 ms-2`}>{row.payment_date}</span>
                                        );
                                    }
                                    
                                    const statusParcel = optionsStatusParcel.find(item => item.value === row.status)?.writeLabel || 'Pendente';
                                    return <>
                                    {statusParcel}
                                    <div>{payment_date}</div>
                                    </>
                                }
                                
                            }
                        }else{
                            return (<span className="badge badge-light-danger fs-base">Deletado</span>)
                        }
                            
                    }

                },
                { Header: 'Email', accessor: 'email_send' },
                { Header: 'Ações', 
                    accessor: (row: any) => {
                        // console.log('row',row)
                        // const classBgExpirate = !!row?.due_date && isDateExpired(row?.due_date) ? 'bg-opacity-15 bg-danger text-danger' : 'btn-light';
                        let classBgExpirate = 'btn-light';
                        let classIcon = 'svg-icon-1x';

                        if(row?.hasOwnProperty('due_date') && !!row?.due_date){
                            if(row?.hasOwnProperty('status') && !!row?.status)
                            {
                                if(row?.status !== '1' && row?.status !== '2')
                                {
                                    classBgExpirate = isDateExpired(row?.due_date) ? 'bg-opacity-15 bg-danger text-danger' : 'btn-light';
                                    classIcon = 'svg-icon svg-icon-1x'
                                }
                            }
                        }

                        if(row.deleted === null)
                        {
                        
                            // const fileNameBoleto = !!row.token_boleto ? `Boleto ${row.our_number}, Vencimento ${row.due_date}, ${row.payer}` : '';
                            return (row.status === '1' || row.status === '2') && (row.confirmation_send === 'sim') 
                            ? (
                                <div className="row" id={`actions_${row.id}`}>
                                    <KTSVG className={`svg-icon svg-icon-2x me-2 mb-1 svg-icon-success me-3`} path="/media/icons/duotune/general/gen047.svg"/>
                                    {/*  EXIBIÇÂO DO BOTAO EDITAR */}
                                    {

                                        handleEdit && typeof handleEdit === 'function' && (
                                            <ComponentPermission includeVerify={['payment']}>
                                                <ComponentPermission.Edit equalVerify="payment">
                                                    <button
                                                        className={` btn btn-sm w-20px  me-2 mb-1 fw-bold btn-color-gray-700 btn-active-color-primary ${classBgExpirate}`}
                                                        onClick={() => handleEdit(row)}
                                                    >
                                                        <KTSVG className={`${classIcon} ms-n2`} path="/media/icons/duotune/general/gen055.svg"/>
                                                        
                                                    </button>
                                                </ComponentPermission.Edit>
                                            </ComponentPermission>
                                        ) 
                                    } 

                                    {/* historico */}
                                    {

                                        handleHistory && typeof handleHistory === 'function' && (
                                            <button
                                                className={`btn btn-sm w-20px me-2 mb-1 fw-bold btn-active-color-danger ${classBgExpirate}`}
                                                onClick={() => handleHistory(row)}
                                            >
                                                <KTSVG className={`${classIcon} ms-n2`} path="/media/icons/duotune/communication/com012.svg"/>
                                                
                                                {
                                                    !!row.id_historic && (
                                                        <span style={{marginTop:'-2.2rem', marginLeft:'-1.5rem'}} className="pulse-ring"></span>
                                                    )
                                                }
                                            </button>
                                        )
                                    }
                                </div>
                            ):(
                                <div className="row" id={`actions_${row.id}`}>

                                    {/* EXIBIÇÂO DO BOLETO */}
                                    { 
                                        (row.name_integration === 'api_cloud' || row.name_integration === null || row.name_integration === '') 
                                        ?   !!row.token_boleto && !!row.link_boleto && !!row.token_remessa && row.confirmation_send === 'sim'
                                            ? (
                                                componentDownlad( row.link_boleto, classIcon, row.id, row.email_send, auth )
                                                
                                            ): (<></>)

                                        :   !!row.link_boleto ? componentDownlad( row.link_boleto, classIcon, row.id, row.email_send, auth ) : <></>
                                    }
                                    
                                    
                                    {/*  EXIBIÇÂO DO BOTAO EDITAR */}
                                    {

                                        handleEdit && typeof handleEdit === 'function' && (
                                            <button
                                                className={` btn btn-sm w-20px me-2 mb-1 fw-bold btn-color-gray-700 btn-active-color-primary ${classBgExpirate}`}
                                                onClick={() => handleEdit(row)}
                                            >
                                                <KTSVG className={`${classIcon} ms-n2`} path="/media/icons/duotune/general/gen055.svg"/>
                                                
                                            </button>
                                        ) 
                                    } 

                                    {

                                        handleHistory && typeof handleHistory === 'function' && (
                                            <button
                                                className={`btn btn-sm w-20px me-2 mb-1 fw-bold btn-active-color-danger ${classBgExpirate}`}
                                                onClick={() => handleHistory(row)}
                                            >
                                                <KTSVG className={`${classIcon} ms-n2`} path="/media/icons/duotune/communication/com012.svg"/>
                                                
                                                {
                                                    !!row.id_historic && (
                                                        <span style={{marginTop:'-2.2rem', marginLeft:'-1.5rem'}} className="pulse-ring"></span>
                                                    )
                                                }
                                            </button>
                                        )
                                    }   
                                </div>
                            )
                        }else{
                            
                            return (
                                <div>
                                    <KTSVG className="svg-icon svg-icon-2x svg-icon-danger me-3" path="/media/icons/duotune/general/gen047.svg"/>
                                </div>
                            );                            
                                
                        }
                    }
                },
            ];
        default:
            return [
                { Header: 'Contrato', accessor: 'contract' },
                { Header: 'Cliente', accessor: 'name_client' },
                { Header: 'Valor', accessor: 'value' },
                // { Header: 'Renegociado', accessor: 'renegotiatedvalue' },
                { Header: 'Vencimento', accessor: 'due_date' },
                { Header: 'Forma de pagamento', accessor: 'type_parcel' },
                { Header: 'Situação', accessor: (row:any) => {

                        if(row.mark_parcel_paid !== '' && row.mark_parcel_paid !== null)
                        {                       
                            return row.mark_parcel_paid;
                            
                        }else{
                            return row.status || 'Pendente'
                        }
                    }

                },
                { Header: 'Email', accessor: 'email_ready' },
                { Header: 'Ações', 
                    accessor: (row: any) => {
        
                        const fileNameBoleto = !!row.token_boleto ? `Boleto ${row.our_number}, Vencimento ${row.due_date}, ${row.payer}` : '';
                        return (row.status === '1' || row.status === '2') && !!row.mark_parcel_paid && row.mark_parcel_paid === 'PAID_REMESSA' 
                        ? (
                            <>...</>
                        ):(
                            <div id={`actions_${row.id}`}>

                                {/* EXIBIÇÂO DO BOLETO */}
                                { 

                                    !!row.token_boleto ? (
                                        
                                        <button
                                            className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                            onClick={() => downloadBoletoPdf(row.token_boleto, fileNameBoleto, auth)}
                                        >
                                            <KTSVG path={"/media/icons/duotune/files/fil021.svg"}/>
                                        </button>
                                    ): (<></>)

                                }

                                {/*  EXIBIÇÂO DO BOTAO EDITAR */}
                                {

                                    handleEdit && typeof handleEdit === 'function' && (
                                        <button
                                            className="btn btn-sm ms-2 fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                            onClick={() => handleEdit(row)}
                                        >
                                            <KTSVG className={`ms-n2`} path={"/media/icons/duotune/general/gen055.svg"}/>
                                        </button>
                                    )
                                }  
                            </div>
                        )
                    }
                },
            ];
    }
}; 

export const componentDownlad = (link_boleto: string, classIcon: string, id_parcel:any, email_send:string, auth:any) => {

    return (
        <>
            <ComponentPermission>
                <ComponentPermission.Export>
                    <button  id={`boleto_${id_parcel}`}
                        className="btn btn-sm w-20px fw-bold me-2 mb-1 btn-light btn-color-gray-700 btn-active-color-primary"
                        onClick={() => downloadBoletos(link_boleto)}
                    >
                        <KTSVG className={`${classIcon} ms-n2`} path={"/media/icons/duotune/files/fil021.svg"}/>
                    </button>
                </ComponentPermission.Export>

                <ComponentPermission.Export>
                    <button 
                        className={`btn btn-sm w-20px me-2 mb-1 fw-bold btn-light btn-color-gray-700 btn-active-color-primary `}
                        onClick={() => confirmEncaminharBoleto(id_parcel, auth)}
                        onMouseEnter={(e) => {
                            const data:any = {
                                target:e,
                                id:id_parcel,
                                title: 'Encaminhar boleto',
                                body:'O boleto será enviado para o cliente em caso de email válido'
                            }

                            TooltipCustom(data)

                        }}
                        onMouseLeave={(e) => {
                            // Remove o popover pelo ID
                            const popover = document.getElementById(`popover-${id_parcel}`);
                            if (popover) {
                                popover.remove();
                            }
                        }}
                    >
                        <KTSVG className={`${classIcon} ms-n2`} path={"/media/icons/duotune/communication/com010.svg"}/>
                        {/* {!!row?.email_send ? 'Rencaminhar boleto' : 'Encaminhar boleto' } */}
                        {
                            email_send != 'Não'  && (
                                <span style={{marginTop:'-2.2rem', marginLeft:'-1.5rem'}} className="pulse-ring"></span>
                            )
                        }
                    </button>
                </ComponentPermission.Export>
            </ComponentPermission>
        </>
    )

}

export default HeaderTablePayment
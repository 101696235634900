import React from "react";
import { ChildComponentProps } from "../../../../app/interface/I_Permission";

const Remessa: React.FC<ChildComponentProps> = ({ children, ...props }) => {
    // Certifique-se de que children seja um elemento React válido antes de cloná-lo
    const validChildren = React.isValidElement(children) ? children : <></>;

    // Use React.cloneElement para adicionar propriedades
    const enhancedChildren = React.cloneElement(validChildren, { permissiontype: 'remessa', className: props.className || '' } as any);

    return enhancedChildren;
};

export default Remessa;

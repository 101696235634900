import { useState } from "react";
import { useSelector } from "react-redux";
import TableAdvanced from "../../../_metronic/layout/components/tables/TableAdvanced";
import { FormHistory } from "../../interface/I_Parcel";
import { TableOptions } from "../../interface/I_Table";
import { useAuth } from "../../modules/auth";
import { DrawerHistoryParcel } from "../contrato/DrawerHistoryParcel";
import HeaderTablePayment from "./table/HeaderTablePayment";

export const ParcelsCanceledWrapper = () => {

    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isDrawerHistoryOpen, setIsDrawerHistoryOpen] = useState(false);
    // const [tableData, setTableData] = useState<FormValues[]>([]);
    const {auth, currentUser, logout, setCurrentUser} = useAuth();

    
	const filterForm = useSelector((state:any) => state.filter);


    /** estado de dados da nota fiscal */
    const [formHistory, setFormHistory] = useState<FormHistory>({
        id_parent:'',
        id_contract:'',
        id_parcel:'',
        id_client:'',
        title:'',
        description:'',
        value_option:''   
    });

    

    /** ao clicar inserir comentario */
    const handleHistory = (row: any) => {          
        setFormHistory({
            id_parent:'',
            id_contract:row.id_contract,
            id_parcel:row.id,
            id_client:row.id_client,
            title:'',
            description:'',
            value_option:''         
        });

        setIsDrawerHistoryOpen(true);
    };



     /** drawer do historico na parcela */
    const closeDrawerHistory = () => {
        setIsDrawerHistoryOpen(false);
    };
    

    // Propopriedade para montar header e  lass e endopoint dinamico
    const optionsInstallment: TableOptions = {
        header: HeaderTablePayment('listParcelsCanceled', Function, handleHistory),
        iInterface: 'listPayment',
        endPoint: 'listParcelsCanceled',
        methodEndPoint: 'post',
        title:'Lista de Parcelas Canceladas',
        classTable: 'table align-middle table-row-dashed fs-6 gy-4 mb-0',
        classHeadTr: 'border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0',
        classHeadTd: 'w-auto text-center',
        classTbody:'fw-semibold text-gray-800',
        classtbodyTr:'',
        classTbodyTd:'w-auto text-center',
        classTbodyTdDiv:'fw-normal text-gray-600',
        funChangeInMass: 'changeParcelInMassa',
        isHideChekbox:true
    };
    
    return (
        <div className="card card-flush pt-3 mb-5 mb-xl-10">

            
            {/* <TableParcelsCanceled/> */}
            <TableAdvanced 
                options={optionsInstallment}
                
            />

            {isDrawerHistoryOpen &&
                <DrawerHistoryParcel
                    closeDrawer={closeDrawerHistory} 
                    formValues={formHistory}
                />
            }

        </div>

    );
}
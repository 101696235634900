import { KTSVG } from "../../../../_metronic/helpers";
import { IHeaderWithCell } from "../../../interface";
import { useAuth } from "../../../modules/auth";
  
/**
 * 
 * @param type
 *  
    beneficiary:string;
    bankAccount:string;
    nameCliente:string;
    numberBoletos:string;
    createDate: string;
    numberRemessa: string;
    nameFile: string;
    status:string;
 * @returns 
 */
export const HeaderTableRemessa = (type: string, handlerDownloadRemessa: (row: any, auth:any) => void, handlerConfirmationSendRemessaToBank: (row: any, auth:any) => void): IHeaderWithCell[] => {
    
    const {auth} = useAuth();
    
    switch (type) {
        case 'remessa': // lista de pagamentos
            return [
                { Header: 'Beneficiário', accessor: 'beneficiary' },
                { Header: 'Conta', accessor: 'bank_account' },
                { Header: 'Criado Em', accessor: 'created_date' },
                { Header: 'Último download', accessor: 'last_download' },
                { Header: 'Arquivo', accessor: 'name_file' },
                { Header: 'Ações', 
                    accessor: (row: any) => {
                        console.log('row', row)
                        const btnDownlaod = !!row.path_cloud 
                                    ? (
                                            <button
                                                className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                                onClick={() => handlerDownloadRemessa(row, auth)}
                                            >
                                                <KTSVG path={"/media/icons/duotune/files/fil021.svg"}/>
                                            </button>
                                    ): (<KTSVG path={"/media/icons/duotune/files/fil021.svg"}/>)

                            const btnSendBank = row.confirmation_send === 'sim' 
                                    ? (
                                        <><span className="badge badge-light-success ms-3">Enviado ao banco</span></>
                                    ): (<button
                                        className="btn btn-sm ms-2 fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                        
                                        onClick={() => handlerConfirmationSendRemessaToBank(row, auth)}
                                    >
                                        <KTSVG path={"/media/icons/duotune/general/gen016.svg"}/>
                                        Confirmar envio ao banco
                                    </button>)

                                return (
                                    <>
                                        {btnDownlaod}
                                        {btnSendBank}
                                    </>
                                );
                                               
                    }
                },
            ];

            
        default:
            return [
                { Header: 'Beneficiário', accessor: 'beneficiary' },
                { Header: 'Conta', accessor: 'bank_account' },
                { Header: 'Criado Em', accessor: 'created_date' },
                { Header: 'Últomo download', accessor: 'last_download' },
                { Header: 'Arquivo', accessor: 'name_file' },
                { Header: 'Ações', 
                    accessor: (row: any) => {
                        
                        const btnDownlaod = !!row.path_cloud 
                                    ? (
                                            <button
                                                className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                                onClick={() => handlerDownloadRemessa(row, auth)}
                                            >
                                                <KTSVG path={"/media/icons/duotune/files/fil021.svg"}/>
                                            </button>
                                    ): (<KTSVG path={"/media/icons/duotune/files/fil021.svg"}/>)

                            const btnSendBank = row.confirmation_send === 'sim' 
                                    ? (
                                        <><span className="badge badge-light-success ms-3">Enviado ao banco</span></>
                                    ): (<button
                                        className="btn btn-sm ms-2 fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                        
                                        onClick={() => handlerConfirmationSendRemessaToBank(row, auth)}
                                    >
                                        <KTSVG path={"/media/icons/duotune/general/gen016.svg"}/>
                                        Confirmar envio ao banco
                                    </button>)

                                return (
                                    <>
                                        {btnDownlaod}
                                        {btnSendBank}
                                    </>
                                );
                                               
                    }
                },
            ];
    }
}; 


export default HeaderTableRemessa
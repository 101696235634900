import { useEffect, useState } from "react"
import { NumericFormat } from "react-number-format";
import { KTSVG } from "../../../../../_metronic/helpers";
import { DrawePropsCalculo, ISimulation } from "../../../../interface/I_Parcel";
import { useAuth } from "../../../../modules/auth";
import { simulationQuitacao } from "../../../../request/parcel";
import { Details } from "../Details";
import { Summary } from "../Summary";

export const DrawerQuitacao = ({
    closeDrawer,
    formValues
}:DrawePropsCalculo) => {

    const {auth} = useAuth();

    const [validDate, setValidDate] = useState('');

    const [formData, setFormData] = useState<ISimulation>({
        id_contract:formValues?.id_contract || 0,
        discharge_date: '',
        name_contract:formValues?.name_contract || '',
        type_simulation:'quitacao',
    });

    const [calculoSimulation, setCalculoSimulation] = useState<any>({});

    const [visibilidade, setVisibilidade] = useState({
        stepp_1:'block',
        stepp_2:'none',
        btn_previous:'none',
        btn_calcular:'block',
        btn_boleto:'none',
        submit:false,
    });

    const [loadingBoleto, setLoadingBoleto] = useState({
        submit:false,
    });

    const [loadingQuitacao, setLoadingQuitacao] = useState({
        submit:false,
    });

    const handleInputChange = (
        value: string,
        fieldName: keyof ISimulation
      ) => {

        if(value !== '')
        {
            setValidDate('is-valid')
        }else{
            
            setValidDate('is-invalid')
        }

        setFormData((prevState) => ({
            ...prevState,
            [fieldName] : value
        }))
    };


    const submitAntecipacao = () => {
        if(!!formData.discharge_date)
        {
            setVisibilidade((prevState) => ({
                ...prevState,
                submit: true,
            }));

            calcularQuitacao();
        }else{
            setValidDate('is-invalid');
        }
    }

    const calcularQuitacao = async () => {

        if(auth?.api_token)
        {
            const response = await simulationQuitacao(formData, auth);
            if(response.data && Object.keys(response.data).length > 0)
            {
                setCalculoSimulation({
                    ...calculoSimulation,
                    ...response.data,
                    ['name_contract'] : formData.name_contract
                });
            }

            setVisibilidade((prevState) => ({
                ...prevState,
                submit:false, 
                btn_previous:'block',           
                btn_calcular:'none',
                btn_boleto:'block',           
                stepp_1:'none',           
                stepp_2:'block',           
            }));
        }
    }

    const handleVoltar = () => {

        setCalculoSimulation({});

        setValidDate('');

        setFormData((prevState) => ({
            ...prevState,
            discharge_date:''
        }))

        // Atualize o estado visibilidade
        setVisibilidade({
          stepp_1: 'block',
          stepp_2: 'none',
          btn_previous: 'none',
          btn_calcular: 'block',
          btn_boleto: 'none',
          submit: false,
        });

        setLoadingBoleto((prevState) => ({
            ...prevState,
            submit:false,         
        }));
    };

    const gerarBoletoQuitacao = () => {

        setLoadingBoleto((prevState) => ({
            ...prevState,
            submit:true,         
        }));
    }
    const emitirQuitacao = () => {

        setLoadingQuitacao((prevState) => ({
            ...prevState,
            submit:true,         
        }));
    }

    return (
        <div
          id='kt_activities'
          className={`bg-body ${!closeDrawer? "": "drawer drawer-end on drawer-on"} w-1000px`}
          data-kt-drawer='true'
          data-kt-drawer-name='activities'
          data-kt-drawer-activate='true'
          data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'1500px', 'lg': '1500px'}"
          data-kt-drawer-direction='end'
        >
        <div className='card w-100 rounded-0'>
            <div className='card-header' id='kt_activities_header'>
              <h3 className='card-title fw-bolder text-dark'>Simulação de Quitação</h3>

              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                  id='kt_activities_close'
                  onClick={closeDrawer}
                >
                    
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </button>
              </div>
            </div>
            {/* <div className="card card-flush pt-3 mb-5 mb-xl-10"> */}
                <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                    <div className="card-body">
                        <div  style={{display:visibilidade.stepp_1, marginLeft:'28%'}}  id="stepp_1" >
                            <div className="d-flex flex-wrap gap-2 justify-content-between mb-8" id="stepp_1">
                                
                                <div className="">                                    

                                    <div className="alert alert-primary d-flex align-items-center p-5 mt-15">

                                        <KTSVG className="svg-icon svg-icon-2x svg-icon-primary" path="/media/icons/duotune/general/gen048.svg"/>                                    
                                        <div className="d-flex flex-column">
                                            
                                            <h4 className="mb-1 text-dark">Escolha uma data para quitação</h4>                                        
                                            <span>A data é importante para calcular taxa de juros.</span>
                                            
                                        </div>
                                        
                                    </div>

                                    <div className="form-floating mt-7">
                                        <input 
                                            type="date" 
                                            className={`form-control ${validDate}`} 
                                            id="discharge_date" 
                                            placeholder="Data de quitação" 
                                            name="discharge_date"
                                            value={formData.discharge_date}
                                            onChange={(e) => {
                                                    handleInputChange(e.target.value, "discharge_date")
                                                }
                                            }
                                            
                                            />
                                        <label htmlFor="discharge_date">Data de quitação</label>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div  style={{display:visibilidade.stepp_2}}  id="stepp_2" >
                            <div className="row flex-wrap gap-2 justify-content-between mb-8">
                                
                                <div className="">
                                    
                                    {/* Resumo */}

                                    <Summary calculoSimulation={calculoSimulation}/>

                                    <div className="separator border-gray-200 mb-6"></div>

                                    <div className="d-flex flex-column w-100">
                                        <div className="fw-semibold fs-5 mb-3 text-dark00">EXTRATO</div>

                                        <div className="py-1">
                                            <div className="py-3 d-flex flex-stack flex-wrap">
                                                <div className="d-flex align-items-center collapsible toggle collapsed" data-bs-toggle="collapse" data-bs-target="#balance_payment" aria-expanded="false">
                                                    <div className="btn btn-sm btn-icon btn-active-color-primary ms-n3 me-2">
                                                        <KTSVG className="toggle-off fs-2" path="/media/icons/duotune/arrows/arr075.svg"/>
                                                        <KTSVG className="toggle-on fs-2" path="/media/icons/duotune/arrows/arr090.svg"/>
                                                    </div>                  
                                                    <div className="me-3">
                                                        <div className="d-flex align-items-center fw-bold">
                                                            Saldo pra quitação : {calculoSimulation?.extract?.balance_payment || '...'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="balance_payment" className="fs-6 ps-10 collapse" >
                                                
                                                <div className="d-flex flex-wrap py-5">
                                                {
                                                                
                                                    calculoSimulation?.data?.parcelasAmortizadas ? (
                                                        <div className="scroll h-400px px-5">
                                                            <Details 
                                                                parcelas={calculoSimulation?.data?.parcelasAmortizadas}
                                                                pendentes={calculoSimulation?.data?.pendentes}
                                                                proRata={calculoSimulation?.data?.proRata}
                                                                valorQuitacao={calculoSimulation?.data?.valorQuitacao}
                                                                type_simulation={'quitacao'}
                                                            />
                                                            </div>
                                                        
                                                    ):(<></>)
                                                
                                                }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
            {/* botoes voltar e avançar */}
            <div className='card-footer py-5 text-center' id=''>
                <div className="d-flex flex-stack pt-10"> 
                    <div className="mr-2" style={{display:visibilidade.btn_previous}}>
                        <button 
                                type="button" 
                                onClick={() => handleVoltar()}
                                className="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous"> 
                            <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/arrows/arr063.svg"/> 
                            Voltar
                        </button>
                    </div>  

                    <div>
                        <button type="button" style={{display:visibilidade.btn_calcular}} onClick={() => submitAntecipacao()} data-kt-indicator={`${visibilidade.submit === true ? 'on' : 'off'}`}  className="btn btn-lg btn-primary me-3" data-kt-stepper-action="submit">
                            <span className="indicator-label">
                                Calcular
                                <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/arrows/arr064.svg"/>
                            </span>
                            <span className="indicator-progress">
                                Aguarde... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>

                        {/* <button type="button" style={{display:visibilidade.btn_boleto}} onClick={() => gerarBoletoQuitacao()} data-kt-indicator={`${loadingBoleto.submit === true ? 'on' : 'off'}`}  className="btn btn-lg btn-primary me-3" data-kt-stepper-action="submit">
                            <span className="indicator-label">
                                Gerar boleto
                                <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/arrows/arr064.svg"/>
                            </span>
                            <span className="indicator-progress">
                                Aguarde... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>

                        <button type="button" style={{display:visibilidade.btn_boleto}} onClick={() => emitirQuitacao()} data-kt-indicator={`${loadingQuitacao.submit === true ? 'on' : 'off'}`}  className="btn btn-lg btn-primary me-3" data-kt-stepper-action="submit">
                            <span className="indicator-label">
                                Emitir Quitação
                                <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/arrows/arr064.svg"/>
                            </span>
                            <span className="indicator-progress">
                                Aguarde... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button> */}
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

    )
}
import { Link } from "react-router-dom";

export const handleDownload = (idElem:string) => {
    // Seleciona o elemento HTML que contém o QR Code
    const qrCode = document.getElementById(idElem)! as HTMLCanvasElement;
  
    // Cria um canvas e o preenche com o conteúdo do elemento HTML
    const canvas = document.createElement('canvas');
    canvas.width = qrCode.offsetWidth;
    canvas.height = qrCode.offsetHeight;
    const context = canvas.getContext('2d');
    if(!context)return
    context.drawImage(qrCode, 0, 0);
  
    // Converte o canvas em uma imagem PNG
    const dataURL = canvas.toDataURL('image/png');
  
    // Cria um link que, quando clicado, faz o download da imagem
    const link = document.createElement('a');
    link.download = 'qrcode.png';
    link.href = dataURL;
    document.body.appendChild(link);
    link.click();
}


export const ButtonDownload = ({idElem, labelButton}:{idElem:string, labelButton:string}) => (
    
    <button
        type="button"
        className="btn btn-sm btn-flex bg-light btn-color-primary-700 btn-active-color-primary fw-bold me-2" 
        onClick={() => handleDownload(idElem)}
    >
        {labelButton}
    </button>
    
);
  
import * as Yup from 'yup'

export const SchemaInvoice = Yup.object().shape({
    // contract: Yup.object().shape({
        // id_client: Yup.string().test('is-zero-value', 'É necessário adicionar um cliente', (value) => {
        //     if (value === '0' || value === '') {
        //       return false;
        //     }
        //     return true;
        // }),
        // id_contract: Yup.string().test('is-zero-value', 'É necessário adicionar um produto', (value) => {
        //     if (value === '0' || value === '') {
        //         return false;
        //     }
        //     return true;
        // }),
        // id_parcel: Yup.string().test('is-zero-value', 'É necessário adicionar uma unidade', (value) => {
        //     if (value === '0' || value === '') {
        //         return false;
        //     }
        //     return true;
        // }),
        invoice_number: Yup.string().test('is-zero-value', 'O número da nota é obrigatório', (value) => {
            if (value === '0' || value === '') {
                return false;
            }
            return true;
        }),
        value: Yup.string().test('is-zero-value', 'O valor deve ser maior que zero', (value) => {
            if (value === '' || value === '0' || value === '0.0' || value === '0.00 ' || value === '0,00'  || value === 'R$ 0,0' || value === 'R$ 0,00') {
              return false;
            }
            return true;
        }),
        issuance_date: Yup.date().nullable().required('A data é obrigatória').typeError('Informe uma data válida'),
        // file: Yup.mixed()
        // .test('file', 'Selecione uma nota fiscal', value => {
        //     return value instanceof File;
        // })
});

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom"
import { KTSVG } from "../../../_metronic/helpers";
import { useAuth } from "../../modules/auth";
import { ativarInativarUser, listUser } from "../../request/user";
import { RegisterUser } from "./RegisterUser";

export const TabHeaderUser = () => {
    
    // const location = useLocation();
    
    const {auth} = useAuth();
    
    const [tabList, setTabList] = useState(true);
    const [tabRegister, setRegister] = useState(false);

    const [descriptionAction, setDescriptionAction] = useState('Listando');
    
    const [users, setUsers] = useState<any>([]);
    const [itemDataUsers, setItemDataUsers] = useState<any>([]);

    const clickTabList = () => {
        setDescriptionAction('Listando')
        setItemDataUsers([])
        setTabList(true);
        setRegister(false);
    }

    const clickTabRegister = () => {
        setDescriptionAction('Registrando um novo')
        setTabList(false);
        setRegister(true);
    }

    useEffect(() => {
        loadUsers();
    }, [])

    const loadUsers = async () => {

        if( auth && auth.api_token)
        {
            try {
                const response = await listUser(auth);
                if (response.data && response.data && Array.isArray(response.data)) {
                    // Verifiqca se a resposta contém dados válidos e se é uma matriz
                    setUsers(response.data); // Defina users com os dados recebidos

                }

            } catch (error) {
                console.error('Erro ao carregar bancos:', error);
            }

        }
    }

    const handlerEditAccount = (data:any) => {

        setDescriptionAction('Editando')
        setTabList(false);
        setRegister(true);
        setItemDataUsers((prevState:any) => ({
            ...prevState,
            ['id'] : data.id,
            ['username'] : data.username,
            ['email'] : data.email
        }));
    }

    
    const handleCheckboxChange = (index: number, id:any) => {
        
        const updatedUser = [...users];
        
        updatedUser[index].active = updatedUser[index].active  === '1' ? '2' : '1';
        
        setUsers(updatedUser);

        handlerAtivarInativar(id, updatedUser[index].active)
    };
    
    const handlerAtivarInativar = async (id:any, active:any) => {

        if( auth && auth.api_token)
        {
            let dataActive = {
                id:id,
                active:active
            }
            const responseActive = await ativarInativarUser(dataActive, auth);
        }
    }
    
    return (
            <div className="card mb-6 mb-xl-9">
                <div className="card-body pt-9 pb-0">

                    <div className="d-flex flex-wrap flex-stack pt-10 pb-8">
                        <h3 className="fw-bold my-2">
                            Usuários
                            <span className="fs-6 text-gray-400 fw-semibold ms-1"> - {descriptionAction} ↓</span>
                        </h3>

                        <div className="d-flex flex-wrap my-1">
                            <ul className="nav nav-pills me-5" role="tablist">
                                <li className="nav-item m-0" role="presentation">
                                    <a 
                                        className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3 ${tabList ? 'active' : ''}`} 
                                        data-bs-toggle="tab" 
                                        href="#list_user" 
                                        aria-selected="true" 
                                        role="tab"
                                        onClick={() => clickTabList()}
                                    >
                                        <KTSVG path="/media/icons/duotune/layouts/lay010.svg"/>
                                    </a>
                                </li>

                                <li className="nav-item m-0" role="presentation">
                                    <a 
                                        className={`btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary ${tabRegister ? 'active' : ''}`} 
                                        data-bs-toggle="tab" 
                                        href="#register_user" 
                                        aria-selected="false" 
                                        tabIndex={1} 
                                        role="tab"
                                        onClick={() => clickTabRegister()}
                                    >
                                        <KTSVG path="/media/icons/duotune/general/gen025.svg"/>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-12">
                            <div className="card-flush h-lg-100 p-7">                    
                                {
                                    tabList && (

                                        <div id="list_user" className="tab-pane fade show active" role="tabpanel">

                                            <div className="card-title flex-column">
                                                {/* <h3 className="fw-bold mb-1">Contas cadastradas</h3> */}
                                            </div>
                    
                                            <div className="card-body p-9 pt-5">
                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        <div className="fw-semibold">
                                                            <div className="fs-6 text-gray-700">Lista de usuários, cadastrados</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-2">
                    
                                                    {
                                                        users && users.map((users:any, index:number) => (
                                                                <div key={index} className="d-flex flex-stack mt-7">
                                                                    <div className="d-flex">
                                                                        <img src={users.photo || ''} className="w-30px me-6" alt="" />
                    
                                                                        <div className="d-flex flex-column">
                                                                            <Link className="fs-5 text-dark text-hover-primary fw-bold" onClick={() => handlerEditAccount(users)} to={`#`}>Usuário - {users.username} <KTSVG path="/media/icons/duotune/general/gen055.svg"/> </Link>
                                                                            {/* <a href="#" className="fs-5 text-dark text-hover-primary fw-bold"></a> */}
                                                                            <div className="fs-6 fw-semibold text-gray-400">{users.full_name} - {users.status_message} - {users.role || 'Admin'}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-end">
                                                                        <div className="form-check form-check-solid form-check-custom form-switch">
                                                                            <input 
                                                                                className="form-check-input w-45px h-30px" 
                                                                                type="checkbox" 
                                                                                id={users.id}
                                                                                checked={users.active || false} // Use o valor do estado users
                                                                                onChange={() => handleCheckboxChange(index, users.id)} 
                                                                            />
                                                                            <label className="form-check-label" htmlFor="googleswitch"></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                    }
                                                    
                    
                                                    <div className="separator separator-dashed my-5"></div>
                                                </div>
                    
                                            </div>
                    
                                        </div>
                                    )
                                }

                                {
                                    tabRegister && (

                                        <div id="register_accountBilling" className="tab-pane fade show active" role="tabpanel">
                                            <div className="notice d-flex mb-4 bg-light-primary rounded border-primary border border-dashed p-6">
                                                <div className="d-flex flex-stack flex-grow-1">
                                                    <div className="fw-semibold">
                                                        <div className="fs-6 text-gray-700">Aqui você pode Cadastrar as conta bancarias para geração de boletos</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <RegisterUser user={itemDataUsers}/>
                                        </div>
                                    )
                                }
                                
                            </div>
                        </div>
                    </div>


                </div>
                
            </div>
    )
}
import React, { useContext, useEffect, useState } from 'react';
import DateRangePickerComponent from '../../../layout/components/dates/DateRangePickerComponent';
import Select from "react-select";
// import { FormContext } from '../../../context/dashboard/ContextFormFilter';
import FormData from 'form-data';
import moment, { Moment } from "moment";
import "moment/locale/pt-br";


import { useDispatch } from 'react-redux';
import { setFilter } from '../../../../app/redux/filter/filterSlice';  
import { TagifyCustom } from '../../../layout/components/buttons/TagifyCustom';
import { useFetchListProductQuery } from '../../../../app/redux/api/product/listProduct';
import { statusContract } from '../../../../app/Constants';
import { useSelector } from 'react-redux';
import { KTSVG } from '../../../helpers';

const FormFilterLisContract: React.FC = () => {

    const dispatch = useDispatch();
    
	const { data:products, error, isLoading } = useFetchListProductQuery(null);
    const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);


    const [selectedProduto, setSelectedProduto] = useState('');
    const [nameClient, setNameClient] = useState('');
        //moment().tz('America/Sao_Paulo')
    const [formData, setFormData] = useState({
        contract : {
            id_client: '',
            id_contract: '',
            id_unit: '',
            status: '',
            date_start_payment: '', 
            date_end_payment: '',
            date_start_contract: '',
            date_end_contract: ''
        }
    });
    
    const fetchProducts = () => {
        
        if (products && Array.isArray(products))
        {
            const allOption = {
                value: '',
                label: 'Todos',
            };
    
            const newOptionsProduct = [allOption, ...products.map((product: { id: any; name: any; }) => ({
                value: product.id.toString(),
                label: product.name,
            }))];

            setOptionsProduct(newOptionsProduct);
        }
    };

    useEffect(()=> {
        fetchProducts();
    },[products]);

    interface Option {
        value: string;
        label: string;
    }

    // nao esta sendo usado
    function handleDateRangeNextPayment(event: Event, picker: any) {
        
        setFormData(prevState => ({
            ...prevState,
            ['date_start_payment'] : picker.startDate,
            ['date_end_payment'] : picker.endDate
        }));
    }

    // nao esta sendo usado
    function handleDateRangeDueDatePayment(event: Event, picker: any) {
        setFormData(prevState => ({
            ...prevState,
            ['date_start_contract'] : picker.startDate,
            ['date_end_contract'] : picker.endDate
        }));
    }

    const onSelectTagify = (e:any) => {

        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            contract: {
              ...prevState.contract,
              [name]: value,
            },
        }));

        // setFormData(prevState => ({
        //     ...prevState,
        //     [e.target.name] : e.target.value,
        // }));
    }

    const handleSelectChange = (value: string, field:string) => {

        setFormData((prevState) => ({
            ...prevState,
            contract: {
              ...prevState.contract,
              [field]: value,
            },
        }));

    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        
        event.preventDefault();
       
        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter(formData));
        
    }

    const resetFilterForm = () => {

        const emptyFormData = {
            contract: {
              id_client: '',
              id_contract: '',
              id_unit: '',
              status: '',
              date_start_payment: '',
              date_end_payment: '',
              date_start_contract: '',
              date_end_contract: '',
            },
        };

        setFormData(emptyFormData);
        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter(emptyFormData));
    }

    return (
        
            <form id="form_filter_contract" onSubmit={handleSubmit}>
                <div className="d-flex flex-column align-items align-items-start">
                    <div className="d-flex align-items-center mb-2"></div>
                    <div className='mb-10 row'>
                        {/* <div className="col-md-12">
                            <label className='form-label fw-bold'>Produto</label>
                            <div className='d-flex'>
                                <Select
                                    styles={{ container: (base) => ({ ...base, zIndex: 99 }) }}
                                    className="w-450px"
                                    placeholder="Selecione um produto"
                                    value={formData.contract.id_product !== '0' && formData.contract.id_product !== null ? { value: formData.contract.id_product, label: optionsProduct.find(option => option.value === formData.contract.id_product)?.label } : null}
                                    
                                    onChange={(e) => handleSelectChange(e?.value || '', 'id_product')}
                                    options={optionsProduct}
                                />
                            </div>
                        </div> */}
                        <div className="col-md-12 mt-4">
                            <label className='form-label fw-bold'>Unidade</label>
                            <div className=''>
                            <TagifyCustom
                                onSelectTagify={onSelectTagify}
                                selectedId=""
                                selectedName=""
                                endPoint="unit"
                                className={'w-450px'}
                            />
                            </div>
                        </div>

                        <div className="col-md-12 mt-4">
                            <label className='form-label fw-bold'>Cliente</label>
                            <div className=''>
                                <TagifyCustom
                                    onSelectTagify={onSelectTagify}
                                    selectedId=""
                                    selectedName=""
                                    endPoint="client"
                                    className={'w-450px'}
                                />
                            </div>
                        </div>

                        <div className="col-md-12 mt-4">
                            <label className='form-label fw-bold'>Status do Contrato</label>
                            <div className=''>
                                <Select
                                    styles={{ container: (base) => ({ ...base, zIndex: 99 }) }}
                                    className="w-450px"
                                    placeholder="Status do contrato"
                                    value={{value: formData.contract.status, label: statusContract.find(option => option.value === formData.contract.status)?.label }}
                                    onChange={(e) => handleSelectChange(e?.value || '', "status")}
                                    options={statusContract}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className='mb-10'>
                        <label className='form-label fw-bold'>Próximo pagamento</label>
                        <DateRangePickerComponent 
                            onDateRangeSelection={handleDateRangeNextPayment}
                            className="w-450px"
                        />
                        
                    </div>

                    <div className='mb-10'>
                        <label className='form-label fw-bold'>Fim de contrato</label>
                        <DateRangePickerComponent 
                            onDateRangeSelection={handleDateRangeDueDatePayment}                            
                            className="w-450px"
                        />
                        
                    </div> */}
                    
                    
                </div>
                <div className="card-footer pt-4" id="kt_drawer_filter_footer">
                        <div className='d-flex justify-content-center'>
                            <button
                                type='reset'
                                className='btn btn-sm btn-light btn-active-light-primary me-2 btn_reset_filter_all'
                                data-kt-menu-dismiss='true'
                                onClick={() => resetFilterForm()}
                            >
                                Resetar filtro
                            </button>
                            <button type="submit" data-kt-indicator='off'  className="btn btn-sm btn-primary me-3 btn_filter_all" data-kt-menu-dismiss='true' data-kt-stepper-action="submit">
                                <span className="indicator-label">
                                    Filtrar
                                    <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/general/gen031.svg"/>
                                </span>
                                <span className="indicator-progress">
                                    Carregando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
            </form> 
    );
};

export default FormFilterLisContract;

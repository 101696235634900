
// const divAguarde = document.createElement('div');
// divAguarde.className = 'menu menu-sub text-primary text-center menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-350px py-4 show';
// divAguarde.innerHTML = `
//     <div className="menu-item px-3">
//         <span className="px-3">
//             Aguarde buscando informações...
//         </span>
//     </div>
// `;

// divAguarde.style.position = 'absolute';
// divAguarde.style.zIndex = '107';
// divAguarde.style.inset = '0px 0px auto auto';
// divAguarde.style.margin = '0px';
// divAguarde.style.transform = 'translate(-202px, 485px)';

function disableButton(botaoFiltro:any, botoesReset:any)
{
    if (botaoFiltro)
    {
        botaoFiltro.setAttribute('disabled', 'true');  
        botaoFiltro.setAttribute('data-kt-indicator', 'on');  
        // botaoFiltro.parentNode.insertBefore(divAguarde, botaoFiltro.nextSibling);  
    }
    
    if (botoesReset)
    {
        botoesReset.setAttribute('disabled', 'true'); 
    }
}

function enableButton(botaoFiltro:any, botoesReset:any)
{
    if (botaoFiltro)
    {
        botaoFiltro.removeAttribute('disabled');
        botaoFiltro.setAttribute('data-kt-indicator', 'off');  
        // if (divAguarde && divAguarde.parentNode) {
        //     divAguarde.parentNode.removeChild(divAguarde);
        // }
    }
    
    if (botoesReset)
    {
        botoesReset.removeAttribute('disabled'); 
    }
}

export const HttpRequestModifyLoadButton = async () => {
    var originalXhrOpen = XMLHttpRequest.prototype.open;

    XMLHttpRequest.prototype.open = function (
        method: string,
        url: string | URL,
        async?: boolean,
        username?: string | null,
        password?: string | null
    ) {

        async = async === undefined ? true : async;

        var botoesFiltro = document.querySelector('.btn_filter_all');        
        var botoesReset = document.querySelector('.btn_reset_filter_all');        
        
        disableButton(botoesFiltro, botoesReset);

        this.addEventListener('load', function () {
                            
            enableButton(botoesFiltro, botoesReset);
        });

        this.addEventListener('error', function () {

            enableButton(botoesFiltro, botoesReset);

            if (this.status === 401)
            {
                window.location.href = '/auth';
            }
        });

        originalXhrOpen.call(this, method, url, async, username, password);
    };
};
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { KTSVG } from "../../../../_metronic/helpers"
import { Importacao } from "../../../../_metronic/layout/components/configurations/importacao/Importacao" 
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { Upload } from "../../../interface/I_Files"
import { CampareBaixaBoletos } from "../modal/CampareBaixaBoletos"

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Configuração',
      path: '/configuration/access',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

export const ArquivoRetorno = () => {

    
    const [isModalOpen, setIsModalOpen] = useState(false);

    // var jsonString = '[{"pagador":"Pagador 1","identificador":"ID001","numero":"123456","documento":"CPF123456","valor":100.5,"vencimento":"2023-08-20","valor_pago":0,"juros_mora":0,"data_credito":null},{"pagador":"Pagador 2","identificador":"ID002","numero":"789012","documento":"CNPJ789012","valor":150.75,"vencimento":"2023-08-25","valor_pago":0,"juros_mora":0,"data_credito":null},{"pagador":"Pagador 3","identificador":"ID003","numero":"345678","documento":"CPF345678","valor":200,"vencimento":"2023-08-30","valor_pago":0,"juros_mora":0,"data_credito":null},{"pagador":"Pagador 4","identificador":"ID004","numero":"901234","documento":"CNPJ901234","valor":75.2,"vencimento":"2023-09-05","valor_pago":0,"juros_mora":0,"data_credito":null},{"pagador":"Pagador 5","identificador":"ID005","numero":"567890","documento":"CPF567890","valor":300,"vencimento":"2023-09-10","valor_pago":0,"juros_mora":0,"data_credito":null}]';

    // var jsonArray = JSON.parse(jsonString);
    // console.log('jsonArray ', jsonArray)

    const [data, setData] = useState([]);
    

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const proposUpload: Upload = {
        idUppy:'importacao',
        idImageEditor:'ImageEditor',
        plugins:['ImageEditor', 'ScreenCapture', 'Webcam'],
        isDebug:true,
        isAutoProceed: false,
        isAllowMultipleUploads: true,
        isShowProgressDetails:false,
        isHideUploadButton:false,
        fileTypes: ['.RET', '.ret'],
        endPoint: 'arquivoRetorno',
        width: 'auto',
        height: '350px',        
        isMessageReturn:true
    }
    const handleFile = (response:any) => {
        console.log('handleFile ',response)
        console.log('resposta',response.body.response.message.title,
        response.body.response.message.text,
        response.body.response.message.icon)


        if (
            response.body.response.message &&
            response.body.response.message.api &&
            Array.isArray(response.body.response.message.api) &&
            response.body.response.message.api.length > 0
        ){            
            setData(response.body.response.message.api)
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
            Swal.fire(
                response.body.response.message.title,
                response.body.response.message.text,
                response.body.response.message.icon
            )
        }
    };

    useEffect(()=> {

    }, [data])

    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Importação CNAB</PageTitle>
            <div className="col-xl-12 mb-xl-10">
                <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
                    <KTSVG path={""}/>
                    <div className="d-flex flex-column">
                        <h4 className="mb-1 text-primary">Área de Importação de Arquivo Retorno</h4>
                        <span>Importe seus arquivo retorno do banco.</span>
                    </div>
                </div>
            
			    <div className="card">
				
                    <div className="card-body">
                        <div className="row align-items-center">
                            <Importacao 
                                propsFiles={proposUpload}                                
                                onFile={handleFile}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && 
                <CampareBaixaBoletos 
                    closeModal={closeModal}
                    data={data}
                />
            }
        </>
    )
}
import * as yup from 'yup';

export const SchemaRegisterUser = yup.object().shape({    
    email: yup.string().email('Por favor, insira um e-mail válido').nullable().required('O campo Email é obrigatório'),
    username: yup.string().nullable().required('O campo Beneficiário é obrigatório'),
    
    password: yup.string()
        .when('validatePassword', {
            is: true,
            then: yup.string().min(8, 'A senha deve ter no mínimo 8 caracteres').required('O campo Senha é obrigatório'),
            otherwise: yup.string() // Não há validação quando validatePassword for falso
        }),
    password_confirm: yup.string()
        .when(['validatePassword', 'password'], {
            is: (validatePassword: any, password: any) => validatePassword && password,
            then: yup.string().oneOf([yup.ref('password'), null], 'As senhas devem coincidir').required('O campo Repetir Senha é obrigatório'),
            otherwise: yup.string() // Não há validação quando validatePassword ou password for falso
        }),
    validatePassword: yup.boolean()
});

import { Tokens } from "../../../interface/I_Auth";

export const getToken = () => {
    const lsValue: string | null = localStorage.getItem('kt-auth-react-v');
    if (!lsValue) {
        return
    }

    try {
        const token: Tokens = JSON.parse(lsValue) as Tokens
        if (token) {
            return token
        }
    } catch (error) {
        console.error('TOKEN LOCAL STORAGE PARSE ERROR', error)
    }
}
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Select from "react-select";
import "moment/locale/pt-br";
import { useDispatch } from 'react-redux';
import { setFilter } from '../../../../app/redux/filter/filterSlice';
import { useFetchListProductQuery } from '../../../../app/redux/api/product/listProduct';
import { getUnitsByIdProduct } from '../../../../app/request/unit';
import { useAuth } from '../../../../app/modules/auth';
import { TagifyCustom } from '../../../layout/components/buttons/TagifyCustom';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import DateRangePickerComponent from '../../../layout/components/dates/DateRangePickerComponent';
import { formatarValor } from '../../../helpers/functions/numbers';
import { Option } from '../../../../app/interface/I_General';
import { Button } from 'react-bootstrap';
import { KTSVG } from '../../../helpers';
import { statusContract } from '../../../../app/Constants';
import { optionsPossuiNotaFiscal, optionsTypePayment } from '../../../../app/globals/OptionsGlobals';
import { useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import { FormValues } from '../../../../app/interface/I_Parcel';

const FormFilterPayment: React.FC = () => {

    const dispatch = useDispatch();
    
	const { data:products, error, isLoading } = useFetchListProductQuery(null);
    // const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);

    const [resetDueDate, setResetDueDate] = useState<boolean>(false);
    const [resetDateContractCreated, setResetDateContractCreated] = useState<boolean>(false);
    const [resetPaymentDate, setResetPaymentDate] = useState<boolean>(false);

    interface OptionSelected {
        id_product: string;
        type_payment: string;
        status_contract: string;
        form_payment: string;
        assessment: string; // avaliação
        boleto: string;
        possui_nota: string;
    }
        
    const optionsTypePayments: Option[] = [
        { value: "", label: "Todos" },
        { value: "LQ", label: "Liquidados" },
        { value: "VC", label: "Vencidos" },
        { value: "RC", label: "A Receber" }
    ];
        
    const optionsAction: Option[] = [
        { value: "", label: "Todos" },
        { value: ">=", label: "Maior ou igual ao" },
        { value: "<=", label: "Menor ou igual ao" },
        { value: "==", label: "Igual ao" },
    ];

    const optionsBoleto: Option[] = [
        { value: "", label: "Todos" },
        { value: "sim", label: "Sim" },
        { value: "nao", label: "Não" },
    ];

    const [formData, setFormData] = useState({
        payments:{ 
            type_payment: '',
            id_contract: '',
            id_client: '',        
            start_date: '',
            end_date: '',        
            start_date_contract: '',
            end_date_contract: '',        
            start_payment_date : '',
            end_payment_date: '',
            value: '',
            status_contract: '1',
            form_payment: '',
            date_payment: '',
            assessment: '',
            boleto: '',
            or_number: '',
            possui_nota: ''
        }
    });
    
    // const fetchProducts = () => {
        
    //     if (products && Array.isArray(products))
    //     {            
    //         const allOption = {
    //             value: '',
    //             label: 'Todos',
    //         };

    //         const newOptionsProduct = [allOption, ...products.map((product: { id: any; name: any; }) => ({
    //             value: product.id.toString(),
    //             label: product.name,
    //         }))];

    //         setOptionsProduct(newOptionsProduct);
    //     }
    // };
    
    // useEffect(()=> {
    //     fetchProducts();
    // },[products]);

    // data de vencimento
    function handleDateRangeDueDatePayment(event: Event, picker: any) {
        const start_date = picker.startDate;
        const end_date = picker.endDate;
        setFormData((prevState) => ({
            ...prevState,
            payments: {
              ...prevState.payments,
                start_date: start_date.format('YYYY-MM-DD'),
                end_date: end_date.format('YYYY-MM-DD')
            },
        }));
    }

    // data de criado do contrato
    function handleDateRangeDateContractCreated(event: Event, picker: any) {

        setFormData((prevState) => ({
            ...prevState,
            payments: {
              ...prevState.payments,
              start_date_contract: picker.startDate.format('YYYY-MM-DD'),
              end_date_contract: picker.endDate.format('YYYY-MM-DD')
            },
        }));
    }

    // data de pagamento
    function handleDateRangePaymentDate(event: Event, picker: any) {
        // const start_date = picker.start_date.tz('America/Sao_Paulo').toDate();
        const start_date = picker.startDate;
        const end_date = picker.endDate;
        setFormData((prevState) => ({
            ...prevState,
            payments: {
              ...prevState.payments,
                start_payment_date : start_date.format('YYYY-MM-DD'),
                end_payment_date: end_date.format('YYYY-MM-DD'), 
            },
        }));       
    }  
    
    const handleSelectChange = <K extends keyof OptionSelected>(selectedOption: Option | null,fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "0";

        setFormData((prevState) => ({
            ...prevState,
            payments: {
              ...prevState.payments,
              [fieldName]: value.toString(),
            },
        }));
    };

    const onSelectTagify = (e:any, fieldName:string) => {
        setFormData((prevState) => ({
            ...prevState,
            payments: {
              ...prevState.payments,
              [e.target.name] : e.target.value
            },
        }));
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        
        event.preventDefault();

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter(formData));        
    }

    const handleInputChange = useCallback((e:any) => {

            const { name, value } = e.target;

            let newValue = name === 'value' ? formatarValor(value).toString() : value;
            setFormData((prevState) => ({
                ...prevState,
                payments: {
                ...prevState.payments,
                [name]: newValue
                },
            }));
    }, []);

    const handleValueInputChange = (
        value: string|number,
        fieldName: keyof FormValues
      ) => {
        
        setFormData((prevState) => ({
            ...prevState,
            payments: {
            ...prevState.payments,
            [fieldName]: parseFloat(value.toString())
            },
        }));
    };

    const handleClearfilter = (type:string) => {

        if(type === 'due_date')
        {
            setResetDueDate(true); 
            
            setFormData((prevState) => ({
                ...prevState,
                payments: {
                    ...prevState.payments,           
                    start_date: '',
                    end_date: '',
                },
            }));

            
        }else if(type === 'contract_created')
        {
            setResetDateContractCreated(true); 
            setFormData((prevState) => ({
                ...prevState,
                payments: {
                    ...prevState.payments,        
                    start_date_contract: '',
                    end_date_contract: ''
                },
            }));

        }else if(type === 'payment_date')
        {
            setResetPaymentDate(true); 
            setFormData((prevState) => ({
                ...prevState,
                payments: {
                    ...prevState.payments, 
                    start_payment_date : '',
                    end_payment_date: ''
                },
            }));

        }

        dispatch(setFilter(formData));
    }

    const resetFilterForm = () => {
        
        setResetDueDate(true);
        setResetDateContractCreated(true);
        setResetPaymentDate(true);
        
        setFormData({
            payments: {
                type_payment: '',            
                id_contract: '',
                id_client: '', 
                start_date: '',
                end_date: '',
                start_date_contract: '',
                end_date_contract: '',            
                start_payment_date : '',
                end_payment_date: '' ,
                value: '',
                status_contract: '',
                form_payment: '',
                date_payment: '',
                assessment: '',
                boleto: '',
                or_number: '',
                possui_nota: ''
            }
        });
        

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter(formData));
    }

    const handleResetComplete = () => {
        // Reset concluído, definir shouldResetDates como false
        setResetDueDate(false);
        setResetDateContractCreated(false);
    };


    return (
        
            <form id="form_filter_contract" onSubmit={handleSubmit}>
                <div className="d-flex mt-n5 flex-column align-items align-items-start">
                    
                    <div className='mb-4 row'>
                        {/* <div className="col-md-4 mt-2">
                            <label className='form-label fw-bold'>Produto</label>
                            <div className=''>
                                <Select
                                    styles={{ container: (base) => ({ ...base, zIndex: 12 }) }}
                                    className="w-auto"
                                    placeholder="Selecione um produto"
                                    value={formData.payments.id_product !== '0' && formData.payments.id_product !== null ? { value: formData.payments.id_product, label: optionsProduct.find(option => option.value === formData.payments.id_product)?.label } : null}                                    
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "id_product")}
                                    options={optionsProduct}
                                />
                            </div>
                        </div> */}
                        
                        <div className="col-md-6 mt-2">
                            <label className='form-label fw-bold'>Cliente</label>
                            <div className='col-md-12'>
                                <TagifyCustom
                                    onSelectTagify={onSelectTagify}
                                    selectedId=""
                                    className="w-100 col-md-12"
                                    selectedName=""
                                    endPoint='client'
                                />
                            </div>
                        </div>                        

                        <div className="col-md-6 mt-2">
                            <label className='form-label fw-bold'>Contrato</label>
                            <div className=''>
                                <TagifyCustom
                                    onSelectTagify={onSelectTagify}
                                    selectedId=""
                                    selectedName=""
                                    endPoint='contract'
                                />
                            </div>
                        </div>                 
                        
                        <div className="d-flex align-items-center mb-3"></div>       

                        <div className="col-md-4 mt-2">
                            <label className='form-label fw-bold'>Situação</label>
                            <div className=''>
                                <Select
                                    styles={{ container: (base) => ({ ...base, zIndex: 12 }) }}
                                    name="type_pyment"
                                    className="w-auto"
                                    placeholder="Selecione um tipo"
                                    value={{value: formData.payments.type_payment, label: optionsTypePayments.find(option => option.value === formData.payments.type_payment)?.label}}
                                    options={optionsTypePayments}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "type_payment")}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <label className='form-label fw-bold'>Status do Contrato</label>
                            <div className=''>
                                <Select
                                    styles={{ container: (base) => ({ ...base, zIndex: 11 }) }}
                                    className="w-auto"
                                    name="status_contract"
                                    placeholder="Status do contrato"
                                    value={{value: formData.payments.status_contract, label: statusContract.find(option => option.value === formData.payments.status_contract)?.label }}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "status_contract")}
                                    options={statusContract}
                                />
                            </div>
                        </div>
                        
                        <div className="col-md-4 mt-2">
                            <label className='form-label fw-bold'>Forma de pagamento</label>
                            <div className=''>
                                <Select
                                    styles={{ container: (base) => ({ ...base, zIndex: 99 }) }}
                                    className="w-auto"
                                    name="status_contract"
                                    placeholder="Forma de pagamento"
                                    value={{value: formData.payments.form_payment, label: optionsTypePayment.find(option => option.value === formData.payments.form_payment)?.label }}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "form_payment")}
                                    options={optionsTypePayment}
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-3"></div>

                        <div className='col-md-6 mt-2'>
                            <label className='form-label fw-bold'>Vencimento</label>
                            <div className="input-group">
                            <DateRangePickerComponent 
                                onDateRangeSelection={handleDateRangeDueDatePayment}
                                className=''
                                resetDate={resetDueDate}
                                onResetComplete={handleResetComplete}
                            />
                            <button className="btn btn-sm btn-light btn-active-light-primary" onClick={() => handleClearfilter('due_date')}><KTSVG path="/media/icons/duotune/art/art004.svg"/>Período total</button>
                            </div>
                            {/* Rest of your component code */}
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label className='form-label fw-bold'>Contratos criados em:</label>
                            <div className="input-group">
                            <DateRangePickerComponent 
                                onDateRangeSelection={handleDateRangeDateContractCreated}
                                className=''
                                resetDate={resetDateContractCreated}
                                onResetComplete={handleResetComplete}
                            />
                            <button className="btn btn-sm btn-light btn-active-light-primary" onClick={() => handleClearfilter('contract_created')}><KTSVG path="/media/icons/duotune/art/art004.svg"/>Período total</button>
                            </div>
                            {/* Rest of your component code */}
                        </div>
                        
                        <div className="d-flex align-items-center mb-4"></div>

                        {/* <div className='col-md-6'>
                            <div className='form-label fw-bold mb-8'> </div>
                            
                                <Button onClick={handleClearfilter}>Período Todo</Button>
                            
                        </div> */}

                        {/* <div className="d-flex align-items-center mb-7"></div> */}
                        
                        <div className="col-md-4 mt-2">
                            <label className='form-label fw-bold'>Ação</label>
                            <div className=''>
                                <Select
                                    styles={{ container: (base) => ({ ...base, zIndex: 11 }) }}
                                    name="assessment"
                                    className="w-auto"
                                    placeholder="Selecione"
                                    value={{ value: formData.payments.assessment, label: optionsAction.find(option => option.value === formData.payments.assessment)?.label }}
                                    options={optionsAction}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "assessment")}
                                />
                            </div>
                        </div>                        

                        <div className="col-md-4 mt-2">
                            <label className='form-label fw-bold'>Valor</label>
                            <div className='d-flex'>
                                <NumericFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$ "
                                    thousandsGroupStyle="thousand"
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    className={`form-control`} 
                                    name="value"
                                    value={formData.payments.value || '0,00'}
                                    onValueChange={(values) => {
                                        console.log('change value', values)
                                        handleValueInputChange(values.floatValue || 0, "value");
                                    }}
                                    
                                />
                            </div>
                            {/* <div className=''>
                                <input
                                    name="value"
                                    className="form-control"
                                    placeholder="R$ 0,00"
                                    value={formData.payments.value !== "R$ 0" && formData.payments.value !== "" && formData.payments.value !== "R$ 0,0" ? formData.payments.value : ''}
                                    onChange={handleInputChange}
                                />
                            </div> */}
                        </div>
                        
                        <div className="col-md-4 mt-2">
                            <label className='form-label fw-bold'>Com Boleto?</label>
                            <div className=''>
                                <Select
                                    styles={{ container: (base) => ({ ...base, zIndex: 10 }) }}
                                    name="boleto"
                                    className="w-auto"
                                    placeholder="Selecione"
                                    value={{ value: formData.payments.boleto, label: optionsBoleto.find(option => option.value === formData.payments.boleto)?.label }}
                                    options={optionsBoleto}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "boleto")}
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-4"></div>

                        <div className="col-md-6 mt-2">
                            <label className='form-label fw-bold'>Possui nota?</label>
                            <div className=''>
                                <Select
                                    styles={{ container: (base) => ({ ...base, zIndex: 10 }) }}
                                    name="possui_nota"
                                    className="w-auto"
                                    placeholder="Selecione"
                                    value={{ value: formData.payments.possui_nota, label: optionsPossuiNotaFiscal.find(option => option.value === formData.payments.possui_nota)?.label }}
                                    options={optionsPossuiNotaFiscal}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "possui_nota")}
                                />
                            </div>
                        </div>

                        <div className='col-md-6 mt-2'>
                            <label className='form-label fw-bold'>Data de pagamento</label>
                            <div className="input-group">
                                <DateRangePickerComponent 
                                    onDateRangeSelection={handleDateRangePaymentDate}
                                    className=''
                                    resetDate={resetPaymentDate}
                                    onResetComplete={handleResetComplete}
                                />
                                <button className="btn btn-sm btn-light btn-active-light-primary" onClick={() => handleClearfilter('payment_date')}><KTSVG path="/media/icons/duotune/art/art004.svg"/>Período total</button>
                            </div>
                            {/* Rest of your component code */}
                        </div>


                        {/* <div className="col-md-6">
                            <label className='form-label fw-bold'>Nosso Número</label>
                            <div className=''>
                                <input
                                    name="or_number"
                                    className="form-control"
                                    placeholder=""
                                    value={formData.payments.or_number !== "" ? formData.payments.or_number : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div> */}

                    </div>                
                    
                </div>

                <div className="separator separator-dashed my-3"></div>
                <div className='d-flex justify-content-center'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2 btn_reset_filter_all'
                        data-kt-menu-dismiss='true'
                        onClick={() => resetFilterForm()}
                    >
                        Resetar filtro
                    </button>

                    <button type="submit" data-kt-indicator='off'  className="btn btn-sm btn-primary me-3 btn_filter_all" data-kt-menu-dismiss='true' data-kt-stepper-action="submit">
                        <span className="indicator-label">
                            Filtrar
                            <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/general/gen031.svg"/>
                        </span>
                        <span className="indicator-progress">
                            Carregando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </form> 
    );
};

export default FormFilterPayment;

import { PageLink } from "../../../core"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
interface Props{
    titleBase:string
    titleSecond:string
}

type LangUrlType = {
    [key: string]: string;
  }

  const langUrl: LangUrlType = {
    'apresentations': 'Apresentação',
    'initials': 'Inicio',
    'contract': 'Contrato',
    'lotes': 'Lotes',
    'payments': 'Pagamentos',
  };

export const MenuDocumentation = ({titleBase, titleSecond}:Props) => {
    
    // const [segment, setSegment] = useState('apresentations');
    const [segment, setSegment] = useState(langUrl['apresentations']);
    
    
    const urlPathname = window.location.pathname;
    const segments = urlPathname.split('/');
    const lastTwoSegments = segments.slice(-1)[0];
    const newLang = langUrl[lastTwoSegments];
    useEffect(() => {
        setSegment(newLang)
      }, [newLang])
    useParams()

    return (
        <div id="kt_docs_header" className="docs-header align-items-stretch mb-2 mb-lg-10">
            <div className="container w-700px">
                <div className="d-flex align-items-stretch justify-content-between py-3 h-75px">
                    
                    <div className="d-flex align-items-center justify-content-between flex-lg-grow-1">
                        <div className="d-flex align-items-center" id="kt_docs_header_title">
                            <div
                                data-kt-swapper="true"
                                data-kt-swapper-mode="prepend"
                                data-kt-swapper-parent="{default: '#kt_docs_content_container', 'lg': '#kt_docs_header_title'}"
                                className="docs-page-title d-flex flex-column flex-lg-row align-items-lg-center py-5 mb-lg-0"
                            >
                                <h1 className="d-flex align-items-center text-dark my-1 fs-4">
                                    Documentação<a className="badge badge-success bg-hover-danger text-hover-white fw-bold fs-9 px-2 ms-2" href="#">v0.0.1</a>
                                </h1>
                                <span className="d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4"></span>
                                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-6 my-1">
                                    <li className="breadcrumb-item text-gray-60"><span className="text-gray-600">{segment}</span></li>
                                    <li className="breadcrumb-item text-gray-60"><span className="bullet bg-gray-200 w-5px h-2px"></span></li>
                                    {/* <li className="breadcrumb-item text-dark">{lastTwoSegments}</li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <a className="btn btn-flex btn-color-gray-700 btn-active-color-primary bg-body h-40px border-0 fw-bold px-4 px-lg-6 me-2 me-lg-3" target={'_blank'} href="https://documenter.getpostman.com/view/25131443/2s8Z72UBZ1">
                                Acessar documentação da Api
                            </a>
                        </div>
                    </div>
                </div>
                <div className="border-gray-300 border-bottom border-bottom-dashed"></div>
            </div>
        </div>

    )
}
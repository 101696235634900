import { useEffect, useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers"
import Select from "react-select";
import { Option } from "../../../../interface/I_General";
import Swal from "sweetalert2";

export const PreviewTable = ({data, columns, table, selects, name, newUpload, send} : any) => {
    
    // const headers = Array.isArray(data) &&  data.length > 0 ? Object.keys(data[0]) : [];
    
    const [optionsSelectFields, setOptionsSelectFields] = useState<Option[]>([]);

    /** monta os option dos selects */
    useEffect(() => {

        const newOptionsSelectFields = Object.keys(selects).map((property: string) => ({
            value: property,
            label: selects[property],
        }));

        setOptionsSelectFields(newOptionsSelectFields);
    }, [selects]);

    const [selectedData, setSelectedData] = useState<{ [key: string]: string }[]>([]);

    /** adiciona os dados ao fazer o change no select */
    const handleSelectChange = (property: string, selectedOption: any) => {
        setSelectedData((prevSelectedData) => ({
            ...prevSelectedData,
            [property]: {
                index: property,
                column: columns[property],
                value: selectedOption ? selectedOption.value : "",
            },
        }));
    };
    
    const [currentStep, setCurrentStep] = useState(1);
    
    const handlerHomeUpload = () => { setCurrentStep(0);}

    const handlerPreview = () => { setCurrentStep(1);}

    const handlerEnvio = () => {setCurrentStep(2);}

    const upload = () => {

        if(typeof newUpload === 'function')
        {
            newUpload(true)
        }
    }

    const handleConfirmSend = () => {

        Swal.fire({
            title: 'Ei, atenção!',
            text: "Deseja enviar essas alterações?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, Enviar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
                handleSendSpreadSheet()
            }
        })
    }

    const handleSendSpreadSheet = () => {
        if (send) {
            const structuredData = {
                planilha: name || 'Planilha.xlsx',
                data: Object.values(selectedData),
            };
            send(structuredData);
        }
    };


    const breadcrumb = [
        { label: 'Upload', onClick: handlerHomeUpload, id:'breadcrumb-88ikrjd738m', disabled:'disabled' },
        { label: 'Preview', onClick: handlerPreview, id:'breadcrumb-49ikdjsy7348', disabled:'' },
        { label: 'Envio', onClick: handlerEnvio, id:'breadcrumb-12kiidyens638', disabled:'' }
    ];

    return (
        <div className="">
            <div className="d-flex justify-content-start ms-8">
                <div className="align-items-center me-7">
                    <span className="symbol-label bg-light-primary text-primary fs-6 fw-bolder me-2">
                        <KTSVG className="svg-icon-primary" path="/media/icons/duotune/general/gen026.svg"/>
                    </span>

                    <span className="fw-bold text-gray-600 fs-6">Serão enviados</span>
                </div>
                <div className="align-items-center">
                    <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder me-2">
                        <KTSVG className="svg-icon-danger" path="/media/icons/duotune/general/gen022.svg"/>
                    </span>

                    <span className="fw-bold text-gray-600 fs-6">Não serão enviados</span>
                </div>
            </div>
            <div className="card-header">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <div className="badge badge-lg badge-light-primary fs-6  w-300px">
                            <div className="d-flex align-items-center flex-wrap ms-3">
                                {/* inicio link de navegacao */}
                                <KTSVG className="svg-icon-2x svg-icon-primary me-3" path="/media/icons/duotune/abstract/abs044.svg"/> 
                                {
                                    breadcrumb.map((item, key) => (
                                        <>
                                            <a  
                                                type="button" 
                                                key={item.id} 
                                                onClick={() => !item.disabled && item.onClick()}
                                                className={`
                                                    ${
                                                        currentStep === key
                                                        ? 'text-primary'
                                                        : currentStep > key
                                                        ? 'text-success'
                                                        : 'text-gray-400'
                                                    } ${item.disabled ? 'disabled' : ''} ms-3`
                                                }
                                            >
                                                {item.label}
                                                
                                            </a>
                                            {key !== breadcrumb.length - 1 &&
                                                <KTSVG className="ms-2" path="/media/icons/duotune/arrows/arr071.svg"/>
                                            }
                                        </>
                                    ))
                                }
                                {/* fim link de navegacao */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-toolbar">
                    <div className="d-flex justify-content-end" >
                        <div className="h-30px badge badge-lg badge-light-primary me-3 opacity-75">
                            <span id="">{Object.keys(selectedData).length ? Object.keys(selectedData).length + ' coluna(s) modificadas' : '0 coluna(s) modificada(s)'}</span>
                        </div>

                        {/* inicio  botoes upload e enviar */}
                        <button type="button" onClick={() => upload()} className="h-30px w-auto p-3 btn btn-icon btn-light-primary me-3">
                            <KTSVG className="svg-icon me-2" path="/media/icons/duotune/files/fil022.svg"/> Novo upload
                        </button>
                        
                        {
                            Object.keys(selectedData).length > 0 ? (

                                <button type="button" onClick={() => handleConfirmSend()} className="h-30px btn btn-icon btn-light-primary p-2 w-auto me-3">
                                    <KTSVG className="me-3" path="/media/icons/duotune/general/gen016.svg"/> Enviar
                                </button>
                            ):(
                                <div className="h-30px badge badge-lg badge-primary me-3 opacity-50">
                                    <KTSVG className="me-3 svg-icon-white" path="/media/icons/duotune/general/gen016.svg"/> Enviar
                                </div>
                            )
                        }
                        {/* fim  botoes upload e enviar */}
                    </div>

                </div>
            </div>


            <div className="card-body">
                <div id="" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="table-responsive">
                        {currentStep === 1 && (
                            <>
                                {/* Inicio Tabela de previa */}
                                {/* <table id=""  className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                            {     
                                                headers.length > 0 ? (                       
                                                    headers.map((header: any, key_hader: any) => (
                                                        <th key={key_hader} className="min-w-150px sorting_disabled" rowSpan={1} colSpan={1} >{header}</th>
                                                    )) 
                                                ) : (
                                                    <>
                                                        <th className="min-w-150px sorting_disabled" rowSpan={1} colSpan={1} >#</th>
                                                        <th className="min-w-150px sorting_disabled" rowSpan={1} colSpan={1} >...</th>
                                                    </>
                                                )                           
                                            }
                                        </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600">
                                        {                 
                                            data.length > 0 ? (             
                                                data.map((body: any, key: any) => (
                                                    <tr key={key} className="odd">
                                                        {Object.keys(body).map((property: string, key_item: number) => (
                                                            <td key={key_item} className="odd">
                                                                {body[property] || ''}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))   
                                            )  :
                                            (
                                                <>
                                                    <tr>
                                                        <td className="odd">{''}</td>
                                                        <td className="odd">{'Nada aqui.'}</td>
                                                    </tr>
                                                </>
                                            )                       
                                        }
                                    </tbody>
                                </table> */}
                                {<div dangerouslySetInnerHTML={{ __html: table }}></div>}
                                {/* Fim Tabela de previa */}
                            </>
                        )}

                        {/* Incio Tabela de validacao e envio*/}
                        {currentStep === 2 && (
                            <> 

                                <table id=""  className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                            {/* <td></td> */}
                                        </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600">
                                        {                 
                                            Object.keys(columns).length > 0 ? (                                                
                                                Object.keys(columns).map((property: any, key: any) => {
                                                    const initialValue = selectedData[property]?.value || null;
                                                    return (
                                                    <tr key={key} className="odd">
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol- symbol-40px me-3 mt-8">
                                                                    
                                                                        {
                                                                            !!selectedData[property]?.value ? (
                                                                                
                                                                                <span className="symbol-label bg-light-primary text-primary fs-6 fw-bolder">
                                                                                    <KTSVG className="svg-icon-primary" path="/media/icons/duotune/general/gen026.svg"/>
                                                                                </span>
                                                                            ):(
                                                                                
                                                                                <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">
                                                                                    <KTSVG className="svg-icon-danger" path="/media/icons/duotune/general/gen022.svg"/>
                                                                                </span>
                                                                            )
                                                                        }
                                                                </div>
                                                                {/* /media/icons/duotune/general/gen022.svg nok */}
                                                                {/* /media/icons/duotune/general/gen026.svg checked */} 
                                                                <div className="row flex-column col-4">
                                                                    <span  className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{columns[property]}</span>
                                                                    <span className="text-gray-400 fw-semibold d-block fs-7">
                                                                    <Select
                                                                            name={property}
                                                                            className="w-auto"
                                                                            placeholder="Selecione"
                                                                            value={optionsSelectFields.find((option) => option.value === selectedData[property]?.value)}
                                                                            options={optionsSelectFields}
                                                                            onChange={(selectedOption) => handleSelectChange(property, selectedOption)}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>  
                                                    )                                              
                                                })                                                 
                                            )  :
                                            (
                                                <>
                                                    <tr>
                                                        <td className="odd">{''}</td>
                                                        <td className="odd">{'Nada aqui.'}</td>
                                                    </tr>
                                                </>
                                            )                       
                                        }
                                    </tbody>
                                </table>

                            </>
                        )}
                        {/* Fim Tabela de validacao e envio*/}
                    </div>

                </div>
            </div>
        </div>

    )
}
import Swal from "sweetalert2"
import { AuthType } from "../../../../app/interface/I_General"
import { queryBoleto } from "../../../../app/request/contract"
import { encaminharBoleto } from "../../../../app/request/parcel"
import { downloadBoletos } from "../../../../app/request/payment"

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export const  downloadBoletoPdf = async (token_boleto:string, fieldName:string, auth:any) =>{

    let timerInterval : any
    Swal.fire({
        title: 'Fazendo download!',
        html: 'Aguarde seu download começará em  <b></b> ',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            const b:any = Swal.getHtmlContainer()?.querySelector('b')
            timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
            }, 100)
        },
        willClose: () => {
            clearInterval(timerInterval)
        }
    }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {}
    })

    const responseBoleto = await queryBoleto(token_boleto, auth);

    if(  responseBoleto?.data)
    {
        // Criar uma URL Blob a partir da string base64
        const pdfBlob = base64ToBlob(responseBoleto?.data);

        // Criar uma URL temporária para o Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Criar um link de download e disparar o clique nele
        const downloadLink = document.createElement('a');
        downloadLink.href = pdfUrl;
        downloadLink.download = fieldName; // Nome do arquivo a ser baixado
        downloadLink.click();
    }
    
}

function base64ToBlob(base64String:any) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: 'application/pdf' });
}

export const confirmEncaminharBoleto = async (idParcel:any, auth:any) => {
    
    var elemento = document.querySelector('[row-id="' + idParcel + '"]');
    const classOriginal: any = elemento?.getAttribute('class');

    elemento?.removeAttribute('class');
    elemento?.classList.add('bg-opacity-15', 'bg-primary', 'text-primary');

    Swal.fire({
        title: 'Encaminhar Boleto.',
        text: "Deseja encaminhar boleto ao cliente?",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Encaminhar',
        cancelButtonText: 'Não enviar'
    }).then((result) => {
        if (result.isConfirmed) {
            sendBoleto(idParcel, auth)
          if (classOriginal) {
                // Remover as classes adicionadas anteriormente
                elemento?.removeAttribute('class');
                // Adicionar a classe original de volta
                elemento?.setAttribute('class', classOriginal);
            } else {
                // Se o usuário cancelou, restaurar a classe original
                elemento?.removeAttribute('class');
            }
        } else {
            // Se o usuário cancelou, restaurar a classe original
            elemento?.removeAttribute('class');
            if (classOriginal)
            {
                elemento?.setAttribute('class', classOriginal);
            }
        }
    })
}

export const sendBoleto =  async(idParcel: any, auth: AuthType) => {
    Toast.fire({
        icon: 'info',
        html: 'Aguarde encaminhando boleto...<br> Enquanto isso navegue livremente pelo sistema.'
    })

    const response  = await encaminharBoleto(idParcel, auth)
    if(response.data && response.data.success)
    {
        Toast.fire({
            icon: 'success',
            title: 'Boleto encaminhado!'
        })
    }else{
        Toast.fire({
            icon: 'error',
            title: 'Não foi possível encaminhar boleto!'
        })
    }
}

export const recriarOnclick = ( id:number|string, novo_valor:any) => {
    const botao = document.getElementById(`${id}`);
    if (botao) {

        botao.onclick = function() {
            // Altere dinamicamente o valor de link_boleto

            botao.onclick = function() {
                // Chame a função downloadBoletos com o novo valor diretamente
                downloadBoletos(novo_valor);
            };
          
            // Faça algo com o novo valor de link_boleto
            console.log('novo_valor', novo_valor);
        };
    } else {
        console.error(`Elemento com ID ${id} não encontrado.`);
    }
}

import * as yup from 'yup';
import { TestCPF } from '../../../_metronic/helpers/functions/TestCpf';

export const SchemaCorretor = yup.object().shape({

    name: yup.string().nullable().required('O campo nome é obrigatório'),
    // phone: yup.string().matches(/^\(\d{2}\)\s\d{4,5}-\d{4}$/, 'Insira um telefone válido').nullable().required('O campo Telefone é obrigatório'),
    // zip_code: yup.string().matches(/^\d{5}-\d{3}$/, 'Insira um CEP válido').nullable().required('O CEP é obrigatório'),
    cpf: yup.string().test(
        'test-invalid-cpf',
        'Cpf inválido',
        (cpf) =>  TestCPF(cpf)).required('O campo Cpf é obrigatório'),
    email: yup.string().nullable().required('O campo Email é obrigatório'),    
    // creci: yup.string().nullable().required('O campo Creci é obrigatório'),
    // code_bank: yup.string().nullable().required('O Código do banco é obrigatório'),
    // bank: yup.string().nullable().required('O campo banco é obrigatório'),
    // operation_bank: yup.string().nullable().required('O operação é obrigatório'),
    // agency: yup.string().nullable().required('A agência é obrigatório'),
    // account: yup.string().nullable().required('A conta é obrigatório'),
    // account_digit: yup.string().nullable().required('O digito da conta é obrigatório'),
    // pix: yup.string().nullable().required('O Pix é obrigatório')
});
import {lazy, FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import { ListContractWrapper } from '../pages/contrato/ListContractWrapper'
import { ContractWrapper } from '../pages/contrato/ContractWrapper'
import { LotesWrapper } from '../pages/unit/LotesWrapper'
// import { Acordos } from '../pages/receita/Acordos'
import TasksWrapper from '../pages/tasks/TasksWrapper'
import TabContract from '../../_metronic/partials/contract/TabRouteContract'
import { PaymentsWrapper } from '../pages/pagamentos/PaymentsWrapper'
import { ParcelDetailsWrapper } from '../pages/pagamentos/ParcelDetailsWrapper'
import { PrivateRoutesDocumentation } from './PrivateRoutesDocumentation'
import { ConfigurationsWrapper } from '../pages/configurations/ConfigurationsWrapper'
import { ArquivoRetorno } from '../pages/configurations/importacao/ArquivoRetorno'
import { PixWrapper } from '../pages/configurations/PixWrapper'
import { ArquivoRemessa } from '../pages/configurations/remessa/ListArquivoRemessa'
import { ProductWrapper } from '../pages/product/ProductWrapper'
import ProductEditWrapper from '../pages/product/ProductEditWrapper'
import { ListClientWrapper } from '../pages/client/ListClientWrapper'
import { ListCorretoresWrapper } from '../pages/corretores/ListCorretoresWrapper'
import { PlanilhasGerais } from '../pages/configurations/importacao/PlanilhasGerais'
import { ParcelsCanceledWrapper } from '../pages/pagamentos/ParcelsCanceledWrapper'
import { BanksWrapper } from '../pages/configurations/bank/BanksWrapper'
import { UserWrapper } from '../pages/user/UserWrapper'
import { Beneficiary } from '../pages/configurations/bank/beneficiary/Beneficiary'
import PermissionWrapper from '../pages/configurations/permission/PermissionWrapper'
import { Error403 } from '../modules/errors/components/Error403'

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setPermission } from '../redux/api/permission/permission'
import BolecodePixItauWrapper from '../pages/configurations/BolecodePixItauWrapper'

const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

    // const routes = [
    //     { path: 'dashboard', element: <DashboardWrapper />, permission: 'dashboard.view' },
    //     { path: 'contract/list', element: <ListContractWrapper />, permission: 'contracts.access' },
    //     // ... outras rotas
    //   ];
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard/access' />} />
                
                <Route path='documentation/:docs/*' element={<PrivateRoutesDocumentation />} />
                {/* Pages */}
                <Route 
                    path='dashboard/access/' 
                    element={
                        <SuspensedView>
                            <DashboardWrapper />
                        </SuspensedView>
                    } 
                />
                <Route
                    path='tasks'
                    element={
                        <SuspensedView>
                            <TasksWrapper />
                        </SuspensedView>
                    }
                />

                {/* <Route path='menu-test' element={<MenuTestPage />} /> */}


                {/* Begin  Rotas contratos */}
                <Route
                    path='contract/access'
                    element={
                        <SuspensedView>
                            <ListContractWrapper />
                        </SuspensedView>
                    }
                />
                
                <Route
                    path='contract/details/:id/*'
                    element={
                        <SuspensedView>
                            <ContractWrapper />
                        </SuspensedView>
                    }
                />
                
                {/* End  Rotas contratos */}

                <Route
                    path='unit/access'
                    element={
                        <SuspensedView>
                            <LotesWrapper />
                        </SuspensedView>
                    }
                />

                {/* produtos */}
                <Route
                    path='product/access'
                    element={
                        <SuspensedView>
                            <ProductWrapper />
                        </SuspensedView>
                    }
                />

                {/* Begin  Rotas clientes */}
                <Route
                    path='client/access'
                    element={
                        <SuspensedView>
                            <ListClientWrapper />
                        </SuspensedView>
                    }
                />

                {/* Begin  Rotas coretores */}
                <Route
                    path='corretor/access'
                    element={
                        <SuspensedView>
                            <ListCorretoresWrapper />
                        </SuspensedView>
                    }
                />
                
                <Route
                    path='product/edit/:id/*'
                    element={
                        <SuspensedView>
                            <ProductEditWrapper />
                        </SuspensedView>
                    }
                />

                {/* pagamentos */}
                <Route
                    path='payment/access'
                    element={
                        <SuspensedView>
                            <PaymentsWrapper />
                        </SuspensedView>
                    }
                />
                
                <Route
                    path='parcel/access'
                    element={
                        <SuspensedView>
                            <ParcelsCanceledWrapper />
                        </SuspensedView>
                    }
                />
                
                <Route
                    path='payment/parcel/:id/*'
                    element={
                        <SuspensedView>
                            <ParcelDetailsWrapper />
                        </SuspensedView>
                    }
                />

                {/* End payments */}

                {/* begin configuracao */}
                <Route
                    path='configuration/access'
                    element={
                        <SuspensedView>
                            <ConfigurationsWrapper />
                        </SuspensedView>
                    }
                />

                <Route
                    path='configuration/import'
                    element={
                        <SuspensedView>
                            <ArquivoRetorno />
                        </SuspensedView>
                    }
                />
                <Route
                    path='configuration/export'
                    element={
                        <SuspensedView>
                            <ArquivoRemessa />
                        </SuspensedView>
                    }
                />

                <Route
                    path='configuration/pix/*'
                    element={
                        <SuspensedView>
                            <PixWrapper />
                        </SuspensedView>
                    }
                />

                <Route
                    path='configuration/spreadsheet/*'
                    element={
                        <SuspensedView>
                            <PlanilhasGerais />
                        </SuspensedView>
                    }
                />

                <Route
                    path='configuration/bolecode/*'
                    element={
                        <SuspensedView>
                            <BolecodePixItauWrapper />
                        </SuspensedView>
                    }
                />

                <Route
                    path='accountsBilling/*'
                    element={
                        <SuspensedView>
                            <BanksWrapper />
                        </SuspensedView>
                    }
                />
                <Route
                    path='configuration/permission'
                    element={
                        <SuspensedView>
                            <PermissionWrapper />
                        </SuspensedView>
                    }
                />

                <Route
                    path='user/*'
                    element={
                        <SuspensedView>
                            <UserWrapper />
                        </SuspensedView>
                    }
                />
                
                {/* Perfil */}
 
                <Route
                    path='user/edit/:id/*'
                    element={
                        <SuspensedView>
                            <ProfilePage />
                        </SuspensedView>
                    }
                />

                {/* <Route
                    path='accountsBilling/:id/*'
                    element={
                    <SuspensedView>
                        <Beneficiary />
                    </SuspensedView>
                    }
                /> */}

                {/* <Route
                    path='apps/user-management/*'
                    element={
                        <SuspensedView>
                            <UsersPage />
                        </SuspensedView>
                    }
                /> */}

                <Route
                    path='permission/'
                    element={
                        <SuspensedView>
                            <Error403 />
                        </SuspensedView>
                    }
                />

                {/* Permissão insuficiente */}
                {/* <Route path='*' element={<Navigate to='/error/403' />} /> */}

                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--kt-primary')

    // const permission = useSelector((state:any) => state.permission)

    // const [shouldRenderError, setShouldRenderError] = useState(false);

    // useEffect(() => {
    // const permissionBasic = localStorage.getItem('permissionBasic');
    // const permission = permissionBasic ? JSON.parse(permissionBasic) : {};

    // if (Object.keys(permission).length > 0 && permission.status === 403 && permission.type === 'redirect') {
    //     setShouldRenderError(true);
        
    //     localStorage.removeItem('permissionBasic');
    // }
    // }, []);

    // const dispatch = useDispatch();
    

    // dispatch(setPermission({}))

    // console.log('permission', permission)
    // console.log('statusPermission', `${shouldRenderError}`)

    // if (shouldRenderError) {
    //     return <Error403 />;
    //   }


  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>
    
        {children}
        {/* { statusPermission && statusPermission.status === 403 && statusPermission.type === 'redirect' ? <Error403/> : children} */}
    </Suspense>
}

export {PrivateRoutes}

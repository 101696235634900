import axios, { AxiosError, AxiosResponse } from 'axios'
import BaseServiceHttp from '../../_metronic/helpers/functions/http/BaseServiceHttp';
import { IResponse, AuthType} from '../interface/I_General';
import { IFormUnit } from '../interface/I_Unit';

const API_URL = process.env.REACT_APP_API_URL;

export const getUnitsByIdProduct = async (idProduct:string, auth: AuthType)  => {

    try{
        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/unitByProduct`,
                            {idProduct},
                            { headers }
                        );

        return response;
    }catch(error){

    }
}


// editar um contrato
export async function editUnitAPI(form: IFormUnit) 
{
    const customConfig:any = {
        method: 'post',
        url: `/module/editUnit`,
        data: {
            form
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar editar unidade'
                                }
                            }
                        });
    return response;

}  

// deleta uma ou mais unidades
export async function deleteUnitsAPI(ids: string[], auth: AuthType) 
{
    // try {

    //     let headers = {};
    //     if (auth && auth.api_token)
    //     {
    //         headers = { Authorization: `Bearer ${auth.api_token}` };
    //     }            
            
    //     const response = await axios.post<IResponse>(`${API_URL}/module/deleteUnit`,
    //                         { ids },
    //                         { headers }
    //                     );

    //     return response;
    // } catch (error) {
    //     throw error;
    // }

    const customConfig:any = {
        method: 'post',
        url: `/module/deleteUnit`,
        data: {
            ids
        },
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            const { response } = error;
                            let statusResponse:any;
                            if (response)
                            {
                                const { status, data } = response;
                                statusResponse = status;
                            }

                            return {
                                data:{
                                    success:false,
                                    data:[],
                                    status:statusResponse,
                                    message:'Ocorreu um erro ao tentar deletar unidade'
                                }
                            }
                        });
    return response;
}

// pega unidade pelo nome
export async function getUnitByName(name: string, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getUnitByName`,
                            { name },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}


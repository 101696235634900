import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import TabRouterBillingBank from "../../../../_metronic/partials/configurartion/billinBanks/TabRouterBillingBank";


const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Contas',
      path: '/accountsBilling',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

export const BanksWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Conta 2</PageTitle>
            <TabRouterBillingBank/>
        </>

    )
}


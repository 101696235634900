import { createElement } from "react";
import { ChildComponentProps } from "../../../../app/interface/I_Permission";

const Bolecode: React.FC<ChildComponentProps> = ({ children, ...props }) => {
    return createElement(
        'div',
        { permissiontype:'bolecode', className: props.className || '' },
        children
    );
};

export default Bolecode;

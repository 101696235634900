import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom"
import { AccountsBilling } from "../../../../app/pages/configurations/bank/accountsBilling/AccountsBilling"
import { Beneficiary } from "../../../../app/pages/configurations/bank/beneficiary/Beneficiary"
import { PageLink, PageTitle } from "../../../layout/core"
import { TabHeaderBillingBank } from "./TabHeaderBillingBank"

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Conta',
      path: 'accountsBilling',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
const TabRouterBillingBank = () => {
    const location = useLocation();
    return (
    <Routes>
        <Route
            element={
                <>
                    <TabHeaderBillingBank/>
                    <Outlet />
                </>
            }
        >
            <Route
                path={`bank`}
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs} >Contas</PageTitle>
                        <AccountsBilling/>
                    </>
                }
            />

            <Route
                path={`beneficiary`}
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs} >Beneficiario</PageTitle>
                        <Beneficiary/>
                    </>
                }
            />

            <Route
                path={`boleto`}
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs} >Boleto</PageTitle>
                        {/* <ConfigurationPix/> */}
                    </>
                }
            />
        
            <Route index element={<Navigate to={`accountsBilling`} />} />
            {/* <Route path="*" element={<div>404 Not Found</div>} /> */}
        </Route>
    </Routes>
  )
            }
  
  export default TabRouterBillingBank
import React, { useState, useEffect  } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment, { Moment } from "moment-timezone";
import "moment/locale/pt-br";
import { Button, InputGroup, Form } from "react-bootstrap";

moment.locale('pt-br');
moment.tz.setDefault("America/Sao_Paulo");

interface DateRangePickerProps {
    onDateRangeSelection: (event: any, date: Moment) => void;
    className ?:string | undefined;
    resetDate?: boolean;
    onResetComplete?: () => void;
}
  
const DateRangePickerComponent: React.FC<DateRangePickerProps> = ({ onDateRangeSelection, className, resetDate, onResetComplete }) => {
    
    const [dates, setDates] = useState<{
        startDate: Moment | null;
        endDate: Moment | null;
    }>({
        startDate: moment().startOf("month"),
        endDate: moment().endOf("month"),
    });      

    useEffect(() => {
        moment.locale('pt-br');
    }, []);

    useEffect(()=>{
        handleClearfilter()
    }, [])

    useEffect(() => {
        if (resetDate) {
            if(onResetComplete)
            {
                onResetComplete();
                setDates({
                    startDate: null,
                    endDate: null,
                });
            }
            
        }
    }, [resetDate]);
    
    
    interface InputChangeEvent {
        target: {
            value: string;
        };
    }

    const handleInputChange = (event: InputChangeEvent) => {};

    const ranges = {
        'Hoje': [moment(), moment()],
        'Ontem': [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
        ],
        "Últimos 7 dias": [moment().subtract(6, "days"), moment()],
        "Últimos 30 dias": [moment().subtract(29, "days"), moment()],
        "Este mês": [moment().startOf("month"), moment().endOf("month")],
        "Último mês": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
        ],
        'Este ano': [moment().startOf('year'), moment().endOf('year')],
        'Ano passado': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
    };

  
    const locales = {
        format: 'DD/MM/YYYY',
        separator: ' - ',
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        fromLabel: 'De',
        toLabel: 'Até',
        customRangeLabel: 'Datas customizadas'
    };

    const settings = {
        startDate: dates.startDate,
        endDate: dates.endDate,
        ranges: ranges,
        autoUpdateInput: true,
        opens: 'left',
        startDatePlaceholder: 'Data inicial',
        endDatePlaceholder: 'Data final',
        applyButtonClasses: 'btn-primary',
        cancelButtonClasses: 'btn-light',
        showDropdowns:true, // permite trocar meses e anos
        linkedCalendars:true,
        locale: {...locales}
    };

    const handleInputCallback = (start: any, end: any, label: any) => {
        // const [startDateStr, endDateStr] = [start, end];
        // const [startDateStr, endDateStr] = [start, end];
        const newDates = {
            startDate: start,
            endDate: end,
        };
         

        setDates(newDates);
    }
    
    const handleClearfilter = () =>{
        setDates({
            startDate: null,
            endDate: null,
        });
    }

    return (
            
            <DateRangePicker 
                initialSettings={settings} 
                onCallback={handleInputCallback}
                onApply={(event, picker) => onDateRangeSelection(event, picker)}
                >
                <input
                    type="text"
                    value={
                        dates.startDate && dates.endDate
                        ? `${dates.startDate.format("DD/MM/YYYY")} - ${dates.endDate.format(
                            "DD/MM/YYYY"
                          )}`
                        : "Todo Período"
                    }
                    name="data_filter"
                    onChange={handleInputChange}
                    className={`form-control ${className || '' } `}
                />
            </DateRangePicker>

    );
};

export default DateRangePickerComponent;

import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react'

import "@yaireo/tagify/dist/tagify.css" // Tagify CSS
import Tags from '@yaireo/tagify/dist/react.tagify'
import { useAuth } from '../../../../app/modules/auth'
import { getClientByName } from '../../../../app/request/client'
import { getContractByName } from '../../../../app/request/contract'
import { getCorretorByName } from '../../../../app/request/corretor'
import { getUnitByName } from '../../../../app/request/unit'

const baseTagifySettings: TagifySettings = {
    blacklist: [],
    maxTags: 1,
    //backspace: "edit",
    placeholder: "Digíte algo..",
    enforceWhitelist: true, // força a selecionar apenas da lista
    dropdown: {
      enabled: 1, // Desativa o dropdown quando o limite de tags é atingido
      maxItems: 200,
    },
    editTags: false // Desabilita a edição de tags
}

interface TagifySettings {
    blacklist: never[];
    maxTags: number;
    placeholder: string;
    enforceWhitelist: boolean;
    dropdown: {
      enabled: number;
      maxItems: number
    };
    editTags: boolean;
    // readOnly?: boolean; // Adicionando a propriedade opcional readOnly,
    // userInput?:boolean
}

interface ITagiFy  {
    id:string,
    value:string
}

export const TagifyCustom = ({ onSelectTagify, selectedId, selectedName, endPoint, className }: any) => {

    const tagifyRef1 = useRef<any>(null);

    const [tagifySettings, setTagifySettings] = useState(baseTagifySettings)
    const [readOnly, setReadOnly] = useState(false)
    const [tagifyProps, setTagifyProps] = useState<{ whitelist: ITagiFy[]; }>({
        whitelist: [{
            id: selectedId.toString() || '',
            value: selectedName || ''
        }]
        // loading: false
      });
    
    const {auth, logout, setCurrentUser} = useAuth();
    
    const handleRemoveTag = (e: any) => {
        // const removedTag = e.detail.tag;

        let _target = {};

        if(endPoint === 'contract')
        {
            _target ={
                target:{
                    name:'id_contract',
                    value:'',
                    innerText:''
                }
            }
        }else if(endPoint === 'client'){                    
        
            _target ={
                target:{
                    name:'id_client',
                    value:'',
                    innerText:''
                }
            }
        }else if(endPoint === 'corretor')
        {
            _target ={
                target:{
                    name:'id_corretor',
                    value:'',
                    innerText:''
                }
            }
        }else if(endPoint === 'unit')
        {
            _target ={
                target:{
                    name:'id_unit',
                    value:'',
                    innerText:''
                }
            }  
        }

        onSelectTagify(_target, endPoint);
    };

    

    useEffect(() => {
        setTagifyProps(prevState => ({ ...prevState }))
        if (tagifyRef1.current) {
            tagifyRef1.current.on('remove', handleRemoveTag);
        }
        
        // return () => {
        //     if (tagifyRef1.current) {
        //         tagifyRef1.current.off('remove', handleRemoveTag);
        //     }
        // };
        
        // getWhitelistFromServer(2000).then((response) => {
        //     setTagifyProps((lastProps) => ({
        //         ...lastProps,
        //         whitelist: response as ITagiFy[],
        //         showFilteredDropdown: "",
        //         loading: false
        //     }))
        // })
    
        // getValue(3000).then((response) =>
        //     setTagifyProps((lastProps) => ({...lastProps, defaultValue: response}))
        // )
    
        // setTimeout(
        //     () =>
        //         setTagifyProps((lastProps) => ({
        //         ...lastProps,
        //         defaultValue: [],
        //         showFilteredDropdown: false
        //         })),
        //     5000
        // )
    }, [])

     // merged tagify settings (static & dynamic)
    const settings = {
        ...baseTagifySettings,
        ...tagifySettings
    }

    const onInput = useCallback((e: { detail: { tagify: { value: any; getCleanValue: () => any; }; value: any; }; }) => {

        e.detail.tagify.getCleanValue();

        handleSearchClient(e.detail.value)
    }, []);

    // faz a consulta pelo nome cliente
    const handleSearchClient = async (value: string) => {

        if(!!value)
        {
            if( auth && auth.api_token)
            {
                let response:any;
                 
                if(endPoint === 'contract')
                {                
                    response = await getContractByName(value, auth);
                }else if(endPoint === 'client'){              
                
                    response = await getClientByName(value, auth);
                }else if(endPoint === 'corretor')
                {
                    response = await getCorretorByName(value, auth);
                }else if(endPoint === 'unit')
                {
                    response = await getUnitByName(value, auth); 
                }
                
                let suggestions: ITagiFy[] = [];
    
                if ( Array.isArray(response.data) && response.data !== null )
                {                    
                    suggestions = response.data.map((row: any) => ({
                        id: row.id.toString().trim(),
                        value: row.name
                    }));
                    
                }else if(typeof response.data.data === 'object' && response.data.data !== null)
                {    
                    suggestions = response.data.data.map((row: any) => ({
                        id: row.id.toString().trim(),
                        value: row.name
                    }));    
                }

                setTagifyProps((lastProps) => ({
                    ...lastProps,
                    whitelist: suggestions,
                    // showFilteredDropdown: "",
                    // loading: false
                }));

                // getValue(2000).then((response) =>
                //     setTagifyProps((lastProps) => ({...lastProps, defaultValue: suggestions, loading: false}))
                // )
            }
        }else{
            setTagifyProps((lastProps) => ({
                ...lastProps,
                whitelist: [],
                // loading: false
            }));
        }
    };

    // aplica selecao do cliente na caixa do dropdown
    const onDropdownSelect = (e:any) => {

        let _target = {};

        if(endPoint === 'contract')
        {
            _target ={
                target:{
                    name:'id_contract',
                    value:e.detail.data.id,
                    innerText:e.detail.data.value
                }
            }
        }else if(endPoint === 'client'){                    
        
            _target ={
                target:{
                    name:'id_client',
                    value:e.detail.data.id,
                    innerText:e.detail.data.value
                }
            }
        }else if(endPoint === 'corretor')
        {
            _target ={
                target:{
                    name:'id_corretor',
                    value:e.detail.data.id,
                    innerText:e.detail.data.value
                }
            }
        }else if(endPoint === 'unit')
        {
            _target ={
                target:{
                    name:'id_unit',
                    value:e.detail.data.id,
                    innerText:e.detail.data.value
                }
            }
        }

        // devolve o cliente ao formulario
        onSelectTagify(_target, endPoint);
        
        if (e.detail.data && Object.keys(e.detail.data).length >= baseTagifySettings.maxTags) {
            // Limite de tags atingido, desabilitar o campo de entrada
            // setTagifySettings({
            //   ...baseTagifySettings,
            //   placeholder: '',
            //   readOnly: true,
            //   userInput:false
            // });
            // setReadOnly(true)

        }
        // setReadOnly(false)
    };


  return (
    <>
      <Tags
        tagifyRef={tagifyRef1}
        settings={settings}
        className={className || 'w-100'}
        // autoFocus={true}
        {...tagifyProps}
        onInput={onInput}
        inputMode={'select'}
        onDropdownSelect={onDropdownSelect}
        defaultValue={[selectedName]}
        // onEditInput={() => console.log("onEditInput")}
        // onEditBeforeUpdate={() => console.log`onEditBeforeUpdate`}
        // onEditUpdated={() => console.log("onEditUpdated")}
        // onEditStart={() => console.log("onEditStart")}
        // onEditKeydown={() => console.log("onEditKeydown")}
        // onDropdownShow={() => console.log("onDropdownShow")}
        // onDropdownHide={() => console.log("onDropdownHide")}
        // onDropdownScroll={() => console.log("onDropdownScroll")}
        // onDropdownNoMatch={() => console.log("onDropdownScroll")}
        // onDropdownUpdated={() => console.log("onDropdownUpdated")}
      />

    </>
  )
}

export const serverDelay = (func: () => any) => (duration: number) =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      resolve(func())
    }, duration || 1000)
)

export const getWhitelistFromServer = serverDelay(() => []);

export const getValue = serverDelay(() => []);

import { AxiosError, AxiosResponse } from "axios";
import BaseServiceHttp from "../../_metronic/helpers/functions/http/BaseServiceHttp";

// recupera os carner do contrato
export async function getReportFrame(token: string | null  = null) 
{
    const customConfig:any = {
        method: 'get',
        url: `/module/reportFrame`,
        
    };
    const http = BaseServiceHttp(customConfig);

    return await http(customConfig)
                        .then((response: AxiosResponse<any>) => {
                            return response;
                        })
                        .catch((error: AxiosError) => {
                            // throw error; // Rejeita a Promise para propagar o erro
                            
                            return {
                                data:[]
                            }
                        });
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilterGLobals } from '../../interface/I_Filter'; 

// const initialState: IFilterGLobals = {
//     full_date:'',
//     start_date:'',
//     end_date:'',
//     due_date:'',
//     product:'',
//     id_product: '',
//     id_unit: '',
//     status:'',
//     name:'',
//     client:'',
//     end_point:'',
//     type_plan:'',
//     period_1: {
//         start_date: '',
//         end_date: '',
//     },
//     period_2: {
//         start_date: '',
//         end_date: '',
//     },
//     value: '',
//     date_payment: '',
//     assessment: '',
//     type_payment:'',
// };

interface IFilterState {
    [key: string]: any;
}


const initialState: IFilterState = {};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilter: (
            state,
            action: PayloadAction<IFilterState>
        ) => {

            // const filterPayload = action.payload;

            Object.keys(action.payload).forEach((key) => {

                state[key] = action.payload[key];
                // if(!!filterPayload[key])
                // {
                //     state[key] = filterPayload[key];
                // }
            });
            // return {
            //     ...state,
            //     ...action
            // }
        },
    },
});

export const { setFilter } = filterSlice.actions;
export default filterSlice.reducer;
